import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";

import IconAdd from "@material-ui/icons/Add";

import { withRouter } from "react-router-dom";
import { Row } from "react-data-grid";

import Grid from "../../../lib/components/grid";
import { getSaleFundsSummary, SaleFunds, SaleFundsFullType, SaleFundsSummaryType, SaleFundsType } from "../../../lib/api/salefunds";
import { clients } from "../../../lib/api/clients";
import { getFinancialYearSelected } from "../../../lib/api/week";

import { GridColumnsSummary } from "./summarygridsetup";
import { FundsForm } from "../allocation/fundsform";

import { isNullOrUndef } from "../../../lib/helpers/isNullOrUndef";

import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

import numeral from "numeral";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "calc(100% - 200px)",
      position: "relative",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      width: "350px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellDetail: {
      width: "100%",
      borderBottom: "none",
      height: "50px",
    },
    tableContainer: {
      position: "relative",
      height: "100%",
      overflowY: "scroll",
      overflowX: "auto",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "500px",
      borderBottom: "none",
      height: "50px",
    },
    gridActions: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
      marginBottom: "10px",
      gap: theme.spacing(1),
    },
    loadingWrapper: {
      width: "550px",
    },
    formFieldContainer: {
      display: "grid",
      gridTemplateColumns: "150px 180px",
      gridGap: "10px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
  });

type DebtorFundsSummaryProps = {
  match: any;
  location: any;
  history: any;
} & WithStyles<typeof styles>;

type DebtorFundsSummaryState = {
  data: SaleFundsSummaryType[];
  loading: boolean;
  gridHeight: number;
  selectedRecord: SaleFundsFullType;
  excludeZeroBalancesOnStatements: boolean;
};
class DebtorFundsSummary extends React.Component<DebtorFundsSummaryProps, any> {
  state: DebtorFundsSummaryState = {
    data: [],
    loading: true,
    gridHeight: 500,
    selectedRecord: undefined,
    excludeZeroBalancesOnStatements: false,
  };

  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    this.setState({ loading: true });
    const result = await getSaleFundsSummary(getFinancialYearSelected());
    this.setState({ loading: false, data: result.data });
  };

  componentDidMount() {
    this.loadData().then(() => {
      const mainClientheight = document.getElementById("divDetails");
      this.setState({ gridHeight: mainClientheight && mainClientheight.clientHeight });
    });
  }

  handleSelectClient = (row: SaleFundsSummaryType) => {
    this.props.history.push(`/debtors/funds/${row.clients_code}`);
  };

  handleCreateNewFund = () => {
    this.setState({ selectedRecord: { id: 0 } });
  };

  handleCreateConfirm = async (data: SaleFundsFullType) => {
    if (!isNullOrUndef(data)) {
      try {
        const dataToUpdate: { data: SaleFundsType } = {
          data: {
            clients_id: data.clients_id,
            amount: parseFloat(numeral(data.amount).format("0.000")),
            currency_id: data.currency_id,
            exchangerate: data.exchangerate,
            makeanote: data.makeanote,
            bankcharges: parseFloat(numeral(data.bankcharges).format("0.000")),
            companydetailsbanking_id: data.companydetailsbanking_id,
            posteddate: data.posteddate ? data.posteddate : new Date(),
            status: 0,
            allocated_year: data.allocated_year,
          },
        };
        await SaleFunds.create(dataToUpdate);
        const singleclient = await clients.single(data.clients_id);

        this.setState({ selectedRecord: undefined });
        if (singleclient && singleclient.code) {
          this.props.history.push(`/debtors/funds/${singleclient.code}`);
        }
      } catch (error) {
        this.setState({ selectedRecord: undefined });
        const err = GenerateErrorMessage(error, "Error creating fund entry");
        this.context.updateSnack({ show: true, color: "red", message: err });
      }
    } else {
      this.setState({ selectedRecord: undefined });
    }
  };

  rowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClick={() => this.handleSelectClient(row)} />;
  };

  render() {
    const { classes } = this.props;

    return (
      <div id="divDetails" className={classes.root}>
        {this.state.loading && <LinearProgress />}
        {this.state.selectedRecord && (
          <FundsForm
            classes={classes}
            selectedRecord={{
              id: 0,
              clients_id: 0,
              clients_code: "",
              clients_name: "",
              posteddate: null,
              amount: 0,
              bankcharges: 0,
              exchangerate: 0,
              makeanote: "",
              available: 0,
              status: 0,
              currency_id: 0,
              currency: "",
              companydetailsbanking_id: 0,
              adhoc_payment: false,
              totalamount: 0,
              allocated_year: null,
            }}
            handleProcess={this.handleCreateConfirm}
            allocationList={[]}
            setFormDirtyFields={() => {}}
            selectedAllocationList={[]}
            loadingDetail={false}
            setAvailableFunds={() => {}}
            isOverpaidFund={false}
          />
        )}
        {!this.state.selectedRecord && (
          <div className={classes.gridWrapper}>
            <Toolbar className={classes.toolbar}>
              <Tooltip title="Create new Fund">
                <Button variant="contained" color="primary" onClick={this.handleCreateNewFund}>
                  <IconAdd />
                  <span>Create Fund</span>
                </Button>
              </Tooltip>
            </Toolbar>
            <Grid
              loading={this.state.loading}
              data={this.state.data}
              GridColumns={GridColumnsSummary}
              clearFilters={"debtorfundssummary"}
              forceHeight={this.state.gridHeight}
              handleRefresh={this.loadData}
              rowRenderer={this.rowRenderer}
              totalRowColumns={[
                "salesTotal",
                "amountPaidTotal",
                "totalOutstanding",
                "bankchargesTotal",
                "totalAmount",
                "availableTotal",
                "current_period",
                "thirty_days",
                "sixty_days",
                "ninety_days",
                "ninetyplus_days",
                "amountOverpaid",
                "ageAnalysisOutstanding",
                "previousYearBalance",
              ]}
              countRowColumns={["clients_code"]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(DebtorFundsSummary));
