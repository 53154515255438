import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import classNames from "classnames";
import { Row } from "react-data-grid";
import format from "date-fns/format";

import Toolbar from "@material-ui/core/Toolbar";

import Grid from "../lib/components/grid";
import { GreenButton } from "../lib/components/ColorButtons";
import { GridColumnsDispatches } from "./dispatchtablesetupdispatches";
import { DocumentsButton, DocumentsType } from "../lib/components/DocumentsButton";
import { Reports } from "../lib/types";
import { handlePrintPackingListPDF } from "../reports/printing";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { SnackContext } from "../lib/context/SnackContext";
import { GridColumnsDispatchesSpecial } from "./dispatchtablesetupdispatchspecial";

const styles = (theme: Theme) =>
  createStyles({
    toolbarContainer: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "75%",
      gap: `${theme.spacing(1)}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    boldRow: {
      fontWeight: "bold",
    },
    greenRow: {
      color: "green",
    },
  });

type DispatchTableGridProps = {
  data: any[];
  loading: boolean;
  gridHeight: number;
  isSpecial?: boolean;
  selectedDispatch: any;
  handleRefresh(): void;
  toggleMergeDispatch(): void;
  handleAvailableDispatchDoubleClick(row: any): void;
} & WithStyles<typeof styles>;

class DispatchTableGridUnstyled extends React.Component<DispatchTableGridProps, {}> {
  constructor(props) {
    super(props);
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  state = {};

  rowRenderer = (props, classes) => {
    const { row } = props;
    const color = row.dispatch_id && classes.greenRow;
    const fontWeight = this.props.selectedDispatch && this.props.selectedDispatch["Dispatch ID"] == row["Dispatch ID"] && classes.boldRow;
    return <Row {...props} onDoubleClickCapture={() => this.props.handleAvailableDispatchDoubleClick(row)} className={classNames(color, fontWeight)} />;
  };

  handleGenerateEdiFile = () => {
    window.location.href = `/api/edistockpalletsdispatched/ext/generateDispatchFileById/${this.props.selectedDispatch["Dispatch ID"]}`;
  };

  printPackingListExcel = (reportType: Reports) => {
    window.location.href = `/api/sale/ext/exportPackingList/${this.props.selectedDispatch.dispatch_id}/true?reportType=${reportType}`;
  };

  printAddendumExcel = () => {
    window.location.href = `/api/edistockpalletsdispatched/ext/printAddendumExcel/${this.props.selectedDispatch.dispatch_id}`;
  };

  printPackingList = async (reportType: Reports) => {
    this.setState({ printPdfLoading: true, anchorEl: undefined });
    try {
      await handlePrintPackingListPDF(`${this.props.selectedDispatch.dispatch_id}`, reportType, true);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing Invoice");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ printPdfLoading: false });
  };

  menuItems = [
    {
      title: "PACKING LIST",
      children: [
        {
          icon: DocumentsType.PDF,
          title: "CLIENT",
          options: [
            {
              title: "CLIENT PACKING LIST PDF",
              icon: DocumentsType.PDF,
              action: () => this.printPackingList(Reports.PackingListPDF),
            },
            {
              title: "CLIENT PACKING LIST EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.printPackingListExcel(Reports.PackingListExcel),
            },
          ],
        },
        {
          icon: DocumentsType.PDF,
          title: "CONSIGNEE",
          options: [
            {
              title: "CONSIGNEE PACKING LIST PDF",
              icon: DocumentsType.PDF,
              action: () => this.printPackingList(Reports.PackingListConsigneePDF),
            },
            {
              title: "CONSIGNEE PACKING LIST EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.printPackingListExcel(Reports.PackingListConsigneeExcel),
            },
          ],
        },
        {
          icon: DocumentsType.PDF,
          title: "CLIENT & CONSIGNEE",
          options: [
            {
              title: "CLIENT & CONSIGNEE PACKING LIST INVOICE PDF",
              icon: DocumentsType.PDF,
              action: () => this.printPackingList(Reports.PackingListClientAndConsigneePDF),
            },
            {
              title: "CLIENT & CONSIGNEE PACKING LIST INVOICE EXCEL",
              icon: DocumentsType.EXCEL,
              action: () => this.printPackingListExcel(Reports.PackingListClientAndConsigneeConsigneeExcel),
            },
          ],
        },
      ],
    },
    {
      title: "GENERATE EDI",
      action: this.handleGenerateEdiFile,
      icon: DocumentsType.DOCUMENT,
    },
    {
      title: "ADDENDUM",
      action: this.printAddendumExcel,
      icon: DocumentsType.EXCEL,
    },
  ];

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.toolbar}>
          <GreenButton variant="contained" onClick={() => this.props.toggleMergeDispatch()}>
            MERGE DISPATCHES
          </GreenButton>
          <DocumentsButton menuItems={this.menuItems} disabled={!this.props.selectedDispatch} />
        </Toolbar>
        <Grid
          fill
          showFilterChips
          data={this.props.data}
          loading={this.props.loading}
          forceHeight={this.props.gridHeight}
          handleRefresh={() => this.props.handleRefresh()}
          exportName={`${format(new Date(), "yyMMdd")} DISPATCHES`}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          clearFilters={this.props.isSpecial ? "dispatchspecial" : "dispatch"}
          totalRowColumns={["Total Pallets", "Total Cartons", "grossweight", "nettweight"]}
          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
            this.props.isSpecial
              ? GridColumnsDispatchesSpecial(data, filters, columnArrangement, columnsWidth)
              : GridColumnsDispatches(data, filters, columnArrangement, columnsWidth)
          }
          countRowColumns={["loadout_reference"]}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DispatchTableGridUnstyled);
