import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal, SummaryNumber } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 100;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "weekintake_week",
      name: "Intake Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekintake_week"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "weekcold_week",
      name: "Cold Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekcold_week"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "producer_code",
      name: "Producer Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_code"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "phc",
      name: "PHC" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"phc"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "puc",
      name: "PUC" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"puc"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_Orchard",
      name: "Orchar" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_Orchard"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_originalIntakewaybill",
      name: "Original Intake Waybill" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_originalIntakewaybill"} data={data} section="intakesandshipmentsgrid" />
        );
      },
    },
    {
      key: "bar_intakewaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_intakewaybill"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_NoCartons"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "bar_PalletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_PalletSize"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "bar_CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_CommodityCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_VarietyCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_GradeCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_CountCode",
      name: "Count Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_CountCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_MarkCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_PackCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_InventoryCode"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_TargetRegion",
      name: "Target Region" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetRegion"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_TargetMarket",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetMarket"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "bar_TargetCountry",
      name: "Target Country" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bar_TargetCountry"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "shipMarket",
      name: "Ship Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipMarket"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "weekLoading",
      name: "Load Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekLoading"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "weekshipping_week",
      name: "Ship Week" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weekshipping_week"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "vessel_description",
      name: "Vessel Description" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_description"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "portLoading_description",
      name: "POL" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portLoading_description"} data={data} section="intakesandshipmentsgrid" />
        );
      },
    },
    {
      key: "portDischarge_description",
      name: "POD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portDischarge_description"} data={data} section="intakesandshipmentsgrid" />
        );
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "shippedCartons",
      name: "Shipped Cartons" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippedCartons"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "shippedPallets",
      name: "Shipped Pallets" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippedPallets"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "consignee_name",
      name: "Consignee" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee_name"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "paid_currency",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"paid_currency"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "prodAdv",
      name: "Prod Adv" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"prodAdv"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "advRem",
      name: "Adv Rem" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advRem"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "prodFinal",
      name: "Prod Fin" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"prodFinal"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "finRem",
      name: "Fin Rem" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finRem"} data={data} section="intakesandshipmentsgrid" />;
      },
    },
    {
      key: "camend_status",
      name: "Processed" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"camend_status"} data={data} section="intakesandshipmentsgrid" />;
      },
      formatter: ({ row, column }) => (row[column.key] >= 3 ? <CheckIcon style={{ color: "green" }} /> : <CloseIcon style={{ color: "red" }} />),
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
