import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import GetApp from "@material-ui/icons/GetApp";
import Refresh from "@material-ui/icons/Refresh";

import CircularProgress from "@material-ui/core/CircularProgress";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";

import { generateSortFn } from "../helpers/generateSortFN";
import { getStickyFilters } from "../helpers/stickyfilters";
import { generateGridFilteredRows } from "../helpers/generateGridFilteredRows";
import { updateUserProfile } from "../helpers/stickyfilters";
import { ExportDataCSV } from "../../lib/helpers/Files";

import GridColumnLayout from "../../lib/components/gridlayout";
import GridFilterChips from "../components/filterchips";
import Confirmation from "../../lib/components/confirmation";
import { history } from "../../lib/history";

import { handleFilterChange, handleGridFill, handleSortChange, summaryAverageRows, summaryCountRows, summaryRows } from "../helpers/grid";
import classNames from "classnames";

import { format } from "date-fns";
import Badge from "@material-ui/core/Badge";
import { OrangeButton } from "./ColorButtons";

import { groupBy as rowGrouper } from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import LayoutIcon from "@material-ui/icons/ViewColumn";
import { ClearFilterIcon } from "../../icons/icons";
import { DialogInformation } from "./dialoginformation";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(1),
      height: "100%",
      width: "100%",
    },
    totalRow: {
      "& .rdg-row:last-child": {
        bottom: "-1px !important",
      },
    },
  });

type GridProps = {
  data?: any[];
  getCellActions?: (column: any, row: any) => any;
  GridColumns?: (data, filters, columnArrangement?, columnsWidth?, totalRowColumns?: string[]) => any;
  rowRenderer?: any;
  rowHeight?: Number;
  clientHeight?: Number;
  forceHeight?: Number;
  groupBy?: [];
  handleChange?: (grid: any, row: any, column: any) => any;
  handleExport?: (event, exportList) => void;
  handleHideCompleted?: () => void;
  loading?: Boolean;
  hideCompleted?: Boolean;
  selectedRowChange?: (selectedRow: any, columns: { selectedColumn: number; columnsLength: number }) => void;
  minHeight?: number;
  menuItems?: {};
  handleRefresh?: () => void;
  handleGridKeyDown?: (event, gridRef) => void;
  showFilterChips?: boolean;
  title?: string;
  fill?: boolean;
  totalRowColumns?: string[];
  handleFilteredRows?: (filteredRows: any[]) => void;
  handleFiltersChange?: (filters) => void;
  toolbarChildren?: any;
  clearFilters: string;
  exportName?: string;
  // reloadFilters?: boolean;
  // layoutItem?: string;
  // widthItem?: string;
  // sortItem?: any;
  hideDownloadAndLayout?: boolean;
  refreshButtonColor?: "pink" | "orange";
  averageRowColumns?: string[];
  countRowColumns?: string[];
  groupedColumns?: string[];
} & WithStyles<typeof styles>;

class GridUnstyled extends React.Component<GridProps, any> {
  state: any = {
    classes: undefined,
    data: undefined,
    getCellActions: undefined,
    GridColumns: undefined,
    page: 0,
    sorting: {},
    filters: {},
    gridRef: undefined,
    rowRenderer: undefined,
    rowHeight: 30,
    clientHeight: 0,
    forceHeight: undefined,
    groupBy: [],
    handleChange: undefined,
    handleExport: undefined,
    loading: true,
    selectedRowChange: undefined,
    minHeight: undefined,
    clearedPhrase: false,
    tableLayout: false,
    columnsArranged: [],
    menuItems: {},
    anchorEl: undefined,
    handleRefresh: undefined,
    handleGridKeyDown: undefined,
    showFilterChips: true,
    columnsWidth: [],
    title: undefined,
    currentSortColumn: "",
    currentSortDirection: "",
    fill: false,
    handleFilteredRows: undefined,
    toolbarChildren: undefined,
    filtersItem: undefined,
    layoutItem: undefined,
    widthItem: undefined,
    sortItem: undefined,
    handleFiltersChange: undefined,
    hideDownloadAndLayout: false,
    averageRowColumns: [],
    countRowColumns: [],
    groupedColumns: undefined,
    expandedGroupIds: new Set<unknown>(),
    expandedCheck: false,
    bePatient: false,
  };

  constructor(props) {
    super(props);

    this.state.classes = props.classes;

    this.state.data = props.data;
    this.state.getCellActions = props.getCellActions;
    this.state.GridColumns = props.GridColumns;
    this.state.handleExport = props.handleExport;
    this.state.loading = false;

    this.state.filtersItem = props.clearFilters;
    this.state.layoutItem = props.clearFilters + "Layout";
    this.state.sortItem = props.clearFilters + "Sort";
    this.state.widthItem = props.clearFilters + "Width";

    if (props.handleFiltersChange) {
      this.state.handleFiltersChange = props.handleFiltersChange;
    }
    if (props.rowRenderer) {
      this.state.rowRenderer = props.rowRenderer;
    }
    if (props.rowHeight) {
      this.state.rowHeight = props.rowHeight;
    }
    if (props.clientHeight) {
      this.state.clientHeight = props.clientHeight;
    }
    if (props.forceHeight) {
      this.state.forceHeight = props.forceHeight;
    }
    if (props.groupBy) {
      this.state.groupBy = props.groupBy;
    }
    if (props.handleChange) {
      this.state.handleChange = props.handleChange;
    }
    if (props.selectedRowChange) {
      this.state.selectedRowChange = props.selectedRowChange;
    }
    if (props.minHeight) {
      this.state.minHeight = props.minHeight;
    }
    if (props.menuItems && Object.keys(props.menuItems).length > 0) {
      this.state.menuItems = props.menuItems;
    }
    if (props.handleRefresh) {
      this.state.handleRefresh = props.handleRefresh;
    }
    if (props.handleGridKeyDown) {
      this.state.handleKeyDown = props.handleGridKeyDown;
    }
    if (props.title) {
      this.state.title = props.title;
    }
    if (props.fill) {
      this.state.fill = props.fill;
    }
    if (props.totalRowColumns) {
      this.state.totalRowColumns = props.totalRowColumns;
    }
    if (props.handleFilteredRows) {
      this.state.handleFilteredRows = props.handleFilteredRows;
    }
    if (props.hideDownloadAndLayout) {
      this.state.hideDownloadAndLayout = props.hideDownloadAndLayout;
    }
    if (props.averageRowColumns) {
      this.state.averageRowColumns = props.averageRowColumns;
    }
    if (props.countRowColumns) {
      this.state.countRowColumns = props.countRowColumns;
    }
    if (props.groupedColumns) {
      this.state.groupedColumns = props.groupedColumns;
    }
    this.state.toolbarChildren = props.toolbarChildren;
    this.state.showFilterChips = props.showFilterChips;
    this.state.gridRef = React.createRef();
  }

  componentDidMount() {
    this.handleStickySituation();
  }

  componentWillReceiveProps(props) {
    this.setState({ ...this.state, data: props.data, forceHeight: props.forceHeight, toolbarChildren: this.props.toolbarChildren }, () => {
      this.handleStickySituation();
    });
  }

  componentDidUpdate(props) {}

  handleStickySituation() {
    try {
      const gridLayout = localStorage.getItem(this.state.layoutItem);
      if (gridLayout) {
        this.setState({ columnsArranged: JSON.parse(gridLayout) }, () => {});
        updateUserProfile(JSON.parse(gridLayout), this.state.layoutItem, "general");
      } else {
        const columnsToRender = this.state.GridColumns(undefined, undefined, undefined, undefined, this.state.totalRowColumns);
        const columnsArr = columnsToRender.map((column) => column.name);
        this.setState({ columnsArranged: columnsArr });
        updateUserProfile(columnsArr, this.state.layoutItem, "general");
      }
    } catch (e) {}

    try {
      const importinfowidth = localStorage.getItem(this.state.widthItem);
      if (importinfowidth) {
        this.setState({ columnsWidth: JSON.parse(importinfowidth) }, () => {});
        updateUserProfile(JSON.parse(importinfowidth), this.state.widthItem, "general");
      } else {
        const columnsDetailToRender = this.state.GridColumns(undefined, undefined, undefined, undefined, this.state.totalRowColumns);
        const columnsArr = columnsDetailToRender.map((column) => ({ name: column.name, width: column.width }));
        this.setState({ columnsWidth: columnsArr }, () => {});
        updateUserProfile(columnsArr, this.state.widthItem, "general");
      }
    } catch (e) {}

    let tablesorting = localStorage.getItem(this.state.sortItem);
    if (tablesorting) {
      tablesorting = JSON.parse(tablesorting);
      const keys = Object.keys(tablesorting);
      this.setState({ sorting: tablesorting, currentSortColumn: keys[0], currentSortDirection: tablesorting[keys[0]] }, () => {});
    }
    this.getValidFilters();
    this.handleExpandRowsState();
  }

  handleExpandRowsState = () => {
    if (this.state.groupedColumns) {
      const expandAllState = localStorage.getItem(`${this.state.filterItem}_expandall`) || "0";
      // only run the function if the grid is not already expaneded
      if (Number(expandAllState) && !this.state.expandedCheck) {
        this.handleExpandAllRows(true);
      }
    }
  };

  handleExport = async (exportList: []) => {
    this.setState({ bePatient: true });

    if (this.state.handleExport) {
      this.state.handleExport();
    } else {
      try {
        this.setState({ loading: true });
        let fileName = "";

        if (this.props.exportName) {
          fileName = this.props.exportName;
        } else {
          const currentPageTitle = history.location.pathname.split("/")[history.location.pathname.split("/").length - 1];
          fileName = `${format(new Date(), "yyMMdd")} ${currentPageTitle.replace(/\_/g, " ").trimStart()}.xlsx`;
        }
        const columns = this.state.GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth, this.state.totalRowColumns);
        await ExportDataCSV(fileName, exportList, columns);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handleExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleExportMenuClose = () => {
    this.setState({ anchorEl: undefined });
  };

  handleExportMenuAction = (menuidx, data, filters) => {
    this.setState({ anchorEl: undefined }, () => {
      this.state.menuItems[menuidx].action(data, filters);
    });
  };

  handleSort = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sorting, sortColumn, sortDirection);
    localStorage.setItem(this.state.sortItem, JSON.stringify(sortingState));
    this.setState({ sorting: sortingState, currentSortColumn: sortColumn, currentSortDirection: sortDirection });
  };

  selectors = Data.Selectors;

  getRows = (rows, filters) => {
    const result = generateGridFilteredRows(rows, filters).filter((item) => {
      return item;
    });

    const fieldSorterData = Object.keys(this.state.sorting).map((item) => {
      return { name: item, reverse: this.state.sorting[item] !== "ASC" };
    });

    result.sort(generateSortFn(fieldSorterData));

    return result;
  };

  handleRemoveSortEntry = (field) => {
    const sortingState = this.state.sorting;
    delete sortingState[field];
    localStorage.setItem(this.state.sortItem, JSON.stringify(sortingState));
    this.setState({ sorting: sortingState });
  };

  handleFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.filters);
    this.setState({ filters: newFilters });
    if (this.state.handleFiltersChange) {
      this.state.handleFiltersChange(newFilters);
    }
  };

  handleRemoveSticky = (key, value) => {
    const section = this.state.filtersItem;
    const sticky = getStickyFilters(section);

    localStorage.removeItem(`stickyfilters_${section}`);

    const newSticky = sticky.reduce((arr, item) => {
      if (item.field === key) {
        const filtered = item.value.filter((v) => v.value.toString().trim() !== value);

        if (filtered.length > 0) {
          arr.push({ ...item, value: filtered });
        }
      } else {
        arr.push(item);
      }
      return arr;
    }, []);

    localStorage.setItem(`stickyfilters_${section}`, JSON.stringify(newSticky));
  };

  handleRemoveFilterEntry = (key, value) => {
    const filters = { ...this.state.filters };

    const newFilters = Object.keys(filters).reduce((obj, filterKey) => {
      if (filterKey === key) {
        const filterTerms = filters[filterKey].filterTerm.filter((item) => item.value != value);
        const rawValues = filters[filterKey].rawValue.filter((item) => item.value != value);

        if (filterTerms.length !== 0 && rawValues.length !== 0) {
          obj[filterKey] = {
            column: filters[filterKey].column,
            filterTerm: filterTerms,
            filterValues: filters[filterKey].filterValues,
            rawValue: rawValues,
          };
        }
      } else {
        obj[filterKey] = filters[filterKey];
      }
      return obj;
    }, {});

    this.handleRemoveSticky(key, value);
    this.setState({ filters: { ...newFilters } });
    if (this.state.handleFiltersChange) {
      this.state.handleFiltersChange({ ...newFilters });
    }
  };

  onGridRowsUpdated = (rows, column) => {
    this.setState((state) => {
      if (this.state.handleChange) {
        const intersection = rows.filter((x) => !this.state.data.includes(x));
        const dirtyData = rows.map((row) => {
          if (intersection.find((item) => item == row)) {
            return { ...row, rowDirty: 1 };
          }
          return { ...row };
        });
        this.state.handleChange("grid", dirtyData, column);
      }
      return { rows };
    });
  };

  handleLayout = () => {
    this.setState({ tableLayout: true });
  };

  handleLayoutClose = () => {
    if (!this.phrase || this.phrase.length === 0) {
      this.setState({ tableLayout: false });
    } else {
      this.clearPhrase();
    }
  };

  handleLayoutConfirm = (columns) => {
    localStorage.setItem(this.state.layoutItem, JSON.stringify(columns));
    this.setState({ tableLayout: false, columnsArranged: columns });
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };
  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  handleClearFilters = () => {
    const section = this.state.filtersItem;
    const sticky = getStickyFilters(section);

    localStorage.removeItem(`stickyfilters_${section}`);
    localStorage.removeItem(this.state.widthItem);
    localStorage.removeItem(this.state.sortItem);

    updateUserProfile(sticky, undefined, section);
    this.setState({ filters: {}, sorting: {}, currentSortColumn: "", currentSortDirection: "" }, () => {
      if (this.state.handleFiltersChange) {
        this.state.handleFiltersChange({});
      }
    });
  };

  handleColumnResize = (index, width) => {
    const newArr = [...this.state.columnsWidth];
    const columnsArranged = [];
    if (this.state.columnsArranged && this.state.columnsArranged.length > 0) {
      this.state.columnsArranged.map((item) => {
        const indexOfItem = newArr.indexOf(newArr.find((col) => col.name.trim() == item.toString().trim()));
        if (indexOfItem != -1) {
          columnsArranged.push(newArr[indexOfItem]);
        }
      });
    } else {
      newArr.map((colItem) => {
        columnsArranged.push(colItem);
      });
    }
    columnsArranged[index].width = width;
    localStorage.setItem(this.state.widthItem, JSON.stringify(columnsArranged));
    updateUserProfile(columnsArranged, this.state.widthItem, "general");
    this.setState({ columnsWidth: columnsArranged });
  };

  handleKeyDownEvent = (event) => {
    const enterKey = 13;
    if (event.which === enterKey) {
      event.preventDefault();
    }

    if (this.state.handleKeyDown) {
      this.state.handleKeyDown(event, this.state.gridRef);
    }
  };

  /* FIX WHEN NOT FILTERING VISIBLE COLUMN */
  getValidFilters = () => {
    const filters: { read: string; field: string; value: any[] }[] = JSON.parse(localStorage.getItem(`stickyfilters_${this.state.filtersItem}`));
    const columns: any[] = this.state.GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth, this.state.totalRowColumns);
    if (filters) {
      const newFilters = (filters || []).reduce((obj, filter) => {
        if (filter.value.length > 0) {
          const col = columns.find((column) => column.key === filter.field);
          obj[filter.field] = { column: col, filterValues: () => {}, filterTerm: filter.value, rawValue: filter.value };
        }
        return obj;
      }, {});

      this.setState({ filters: { ...newFilters, ...this.state.filters } });
      return { ...newFilters, ...this.state.filters };
    }
  };

  calculateTotalRows = (): any[] => {
    const rowsGetten = this.getRows(this.state.data, this.state.filters);
    let totals = {};

    if (this.state.totalRowColumns) {
      const rows = summaryRows(rowsGetten, this.state.totalRowColumns);
      totals = { ...totals, ...rows[0] };
    }
    if (this.state.averageRowColumns) {
      const rows = summaryAverageRows(rowsGetten, this.state.averageRowColumns);
      totals = { ...totals, ...rows[0] };
    }
    if (this.state.countRowColumns) {
      const rows = summaryCountRows(rowsGetten, this.state.countRowColumns);
      totals = { ...totals, ...rows[0] };
    }
    return [totals];
  };

  handleChangeExpandedGroupIds = (expandedGroupIds: Set<unknown>) => {
    this.setState({ expandedGroupIds });
  };

  handleExpandAllRows = (init?: boolean) => {
    // NOTE: this will only expand the first column in 'groupedColumns', this functionality should be expanded to expand all columns ;)
    // if this is the first run of the function from the 'componentDidMount', do not change the localstorage
    if (!init) {
      localStorage.setItem(`${this.state.filterItem}_expandall`, !this.state.expandedCheck ? "1" : "0");
    }
    const expandColumn = (this.state.groupedColumns || [])[0];
    const groupedData = this.getRows(this.state.data, this.state.filters).map((row) => row[expandColumn]);
    this.setState({ expandedGroupIds: this.state.expandedCheck ? new Set() : new Set(groupedData), expandedCheck: !this.state.expandedCheck });
  };

  handleCalcGroupedGridHeight = () => {
    const rowsGetten = this.getRows(this.state.data, this.state.filters);
    const groupedRows = rowGrouper(rowsGetten, this.state.groupedColumns[0]);
    const size = Math.floor(rowsGetten.length / Object.keys(groupedRows).length);
    const calc = (Object.keys(groupedRows).length + 1) * 30 + 80 + (this.state.expandedGroupIds || new Set()).size * size * 30;
    return calc < 500 ? 500 : calc;
  };

  filterTerms = (filterObj, term = "filterTerm") => {
    return Object.keys(filterObj).reduce((val, key) => (val += filterObj[key][term].length), 0);
  };

  handleBePatientClose = () => {
    this.setState({ bePatient: false });
  };

  render() {
    const { classes } = this.state;

    const columnsToRender = this.state.GridColumns(this.state.data, this.state.filters, this.state.columnsArranged, this.state.columnsWidth, this.state.totalRowColumns);
    // not sure why by the arrangement does not effect correctly sometimes?
    // might be when called from a functional component.
    // events are weird. :)
    const columnsArranged = [];
    if (this.state.columnsArranged && this.state.columnsArranged.length > 0) {
      this.state.columnsArranged.map((item) => {
        const indexOfItem = columnsToRender.indexOf(columnsToRender.find((col) => col.name.trim() == item.toString().trim()));
        if (indexOfItem != -1) {
          columnsArranged.push(columnsToRender[indexOfItem]);
        }
      });
    } else {
      columnsToRender.map((colItem) => {
        columnsArranged.push(colItem);
      });
    }
    const rowsGetten = this.getRows(this.state.data, this.state.filters);

    const sortFilterCount = () => {
      if (Object.keys(this.state.filters).length >= 2) {
        Object.keys(this.state.filters).length = 2;
      }
      if (Object.keys(this.state.sorting).length >= 2) {
        Object.keys(this.state.sorting).length = 2;
      }
      return Object.keys(this.state.filters).length + Object.keys(this.state.sorting).length;
    };

    const mainClientheight =
      document.getElementsByTagName("Main") && document.getElementsByTagName("Main").length > 0 && document.getElementsByTagName("Main")[0].clientHeight
        ? document.getElementsByTagName("Main")[0].clientHeight + 60
        : 0;

    const chipCount = Object.keys(this.state.filters).length;

    if (this.state.handleFilteredRows) {
      this.state.handleFilteredRows(rowsGetten);
    }

    const newHeight =
      (this.state.groupedColumns || []).length > 0
        ? this.handleCalcGroupedGridHeight()
        : this.state.forceHeight
        ? this.state.forceHeight - (this.state.showFilterChips && chipCount > 0 ? 50 : 0)
        : this.state.data.length < 12
        ? 500
        : chipCount > 0
        ? mainClientheight - (300 + this.state.clientHeight)
        : sortFilterCount() >= 1
        ? mainClientheight - (260 + 40 * (sortFilterCount() - 1) + this.state.clientHeight)
        : mainClientheight - (220 + this.state.clientHeight);

    return (
      <>
        <div className={classes.root}>
          <Paper style={{ display: "flex", flexDirection: "column" }}>
            {this.state.bePatient && (
              <DialogInformation
                isOpen
                handleClose={this.handleBePatientClose}
                handleOK={this.handleBePatientClose}
                showinput={false}
                title={"BE PATIENT"}
                body={"This could take a moment. Please be patient."}
              />
            )}
            {this.state.tableLayout && (
              <Confirmation isOpen={this.state.tableLayout} handleClose={this.handleLayoutClose} handleConfirm={() => {}} title="Editing Grid Layout" body={undefined}>
                <GridColumnLayout
                  layoutItem={this.state.layoutItem}
                  onSubmit={this.handleLayoutConfirm}
                  onClose={this.handleLayoutClose}
                  columns={this.state.columnsArranged}
                  setSearchPhrase={this.setSearchPhrase}
                  clearPhrase={this.state.clearedPhrase}
                />
              </Confirmation>
            )}
            <div>
              {this.state.toolbarChildren}
              {this.state.groupedColumns && (
                <FormControlLabel
                  style={{ float: "left", margin: "15px 5px" }}
                  control={<Checkbox onChange={() => this.handleExpandAllRows()} name="expand-al" color="primary" checked={this.state.expandedCheck} />}
                  label="Expand All"
                />
              )}
              {this.state.title && <span style={{ float: "left", position: "absolute", padding: "20px", fontWeight: "bold" }}>{this.state.title}</span>}
              {this.state.layoutItem && !this.state.hideDownloadAndLayout && (
                <Tooltip title="Grid layout">
                  <Button variant="contained" color="primary" onClick={this.handleLayout} style={{ float: "right", margin: "15px 5px" }}>
                    <LayoutIcon />
                  </Button>
                </Tooltip>
              )}
              {this.state.handleRefresh &&
                (this.props.refreshButtonColor ? (
                  this.props.refreshButtonColor == "orange" ? (
                    <Tooltip title="Refresh data">
                      <OrangeButton variant="contained" onClick={this.state.handleRefresh} style={{ float: "right", margin: "15px 5px" }}>
                        <Refresh />
                      </OrangeButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Refresh data">
                      <Button variant="contained" color="secondary" onClick={this.state.handleRefresh} style={{ float: "right", margin: "15px 5px" }}>
                        <Refresh />
                      </Button>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title="Refresh data">
                    <Button variant="contained" color="primary" onClick={this.state.handleRefresh} style={{ float: "right", margin: "15px 5px" }}>
                      <Refresh />
                    </Button>
                  </Tooltip>
                ))}
              {this.state.filtersItem && (
                <Tooltip title="Clear Filters">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleClearFilters}
                    style={{ float: "right", margin: "15px 5px" }}
                    disabled={Object.entries(this.state.filters).length === 0}
                  >
                    <Badge badgeContent={this.filterTerms(this.state.filters)} color="secondary">
                      <ClearFilterIcon />
                    </Badge>
                  </Button>
                </Tooltip>
              )}
              {!this.state.hideDownloadAndLayout && (
                <>
                  <Tooltip title="Export and Download.">
                    {this.props.loading || this.state.loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            if (!this.state.menuItems || Object.keys(this.state.menuItems).length == 0) {
                              if (rowsGetten.length > 0) {
                                this.handleExport(rowsGetten);
                              }
                            } else {
                              this.handleExportMenu(e);
                            }
                          }}
                          style={{ float: "right", margin: "15px 5px" }}
                        >
                          {!this.props.loading && !this.state.loading && <GetApp />}
                        </Button>
                        {Object.keys(this.state.menuItems).length != 0 && (
                          <StyledMenu id="customized-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleExportMenuClose}>
                            {Object.keys(this.state.menuItems).map((menuItemKey) => {
                              return (
                                <StyledMenuItem key={menuItemKey} onClick={(e) => this.handleExportMenuAction(menuItemKey, rowsGetten, this.state.filters)}>
                                  <ListItemText primary={this.state.menuItems[menuItemKey].title} />
                                </StyledMenuItem>
                              );
                            })}
                          </StyledMenu>
                        )}
                      </div>
                    )}
                  </Tooltip>
                  <div style={{ marginTop: "23px", float: "right" }}>
                    <span style={{ fontWeight: "bold", color: "#ed7d31", marginRight: "25px", width: "94px" }}>
                      {rowsGetten && rowsGetten.length > 0 ? `${rowsGetten.length} Row(s)` : `     `}
                    </span>
                  </div>
                </>
              )}
            </div>
            <form onKeyPress={this.handleKeyDownEvent}>
              <ReactDataGrid
                className={classNames(this.state.totalRowColumns && classes.totalRow, "rdg-light")}
                ref={(input) => (this.state.gridRef = input)}
                style={{
                  height: newHeight,
                }}
                columns={columnsToRender}
                rows={rowsGetten}
                onSort={this.handleSort}
                onFiltersChange={this.handleFilterChange}
                rowHeight={this.state.rowHeight}
                headerRowHeight={35}
                enableFilterRow={true}
                onColumnResize={this.handleColumnResize}
                sortColumn={this.state.currentSortColumn}
                sortDirection={this.state.currentSortDirection}
                onRowsChange={this.onGridRowsUpdated}
                onRowClick={(rowIdx, row: any, column) => {
                  if (this.state.selectedRowChange) {
                    this.state.selectedRowChange({ ...row, rowIdx: rowIdx }, { selectedColumn: column.idx, columnsLength: columnsToRender.length });
                  }
                }}
                onFill={this.state.fill ? handleGridFill : () => {}}
                summaryRows={this.calculateTotalRows()}
                onSelectedCellChange={({ idx, rowIdx }) => {
                  if (this.state.selectedRowChange) {
                    this.state.selectedRowChange({ ...rowsGetten[rowIdx], rowIdx: rowIdx }, { selectedColumn: idx, columnsLength: columnsToRender.length });
                  }
                }}
                rowRenderer={this.state.rowRenderer}
                groupBy={this.state.groupedColumns}
                rowGrouper={this.state.groupedColumns ? rowGrouper : undefined}
                expandedGroupIds={this.state.expandedGroupIds}
                onExpandedGroupIdsChange={this.handleChangeExpandedGroupIds}
              />
            </form>
            <div>
              {this.state.showFilterChips && (
                <GridFilterChips
                  columns={columnsToRender}
                  filterData={this.state.filters}
                  handleFilterClear={null}
                  sortingData={this.state.sorting}
                  removesortingentry={this.handleRemoveSortEntry}
                  removeFilterEntry={this.handleRemoveFilterEntry}
                />
              )}
            </div>
          </Paper>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(GridUnstyled);
