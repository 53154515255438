import React from "react";

import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterNumber2Decimals, FormatterNumber3Decimals } from "../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";

import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../lib/components/gridhelper/RightAlignHeaderColumn";
import { SaleFundsFullType } from "../../lib/api/salefunds";

const cellWidthActions = 120;
const cellWidthSmall = 100;
const cellWidthSmallBig = 140;
const cellWidthMedium = 180;
const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement, columnsWidth, handleEdit, handleRemoveFundEntry, handleAllocate) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthActions,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleEdit, handleRemoveFundEntry, handleAllocate);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "clients_code",
      name: "Client" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_code"} data={data} section="debtorsfunds" />;
      },
    },
    {
      key: "clients_name",
      name: "Client Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_name"} data={data} section="debtorsfunds" />;
      },
    },
    {
      key: "posteddate",
      name: "Posted Date" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"posteddate"} data={data} section="debtorsfunds" isDate />;
      },
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="debtorsfunds" />;
      },
    },
    {
      key: "exchangerate",
      name: "ROE" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchangerate"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber3Decimals,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amount",
      name: "Amount Received" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amount"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "bankcharges",
      name: "Foreign Charges" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bankcharges"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "totalamount",
      name: "Total Received" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalamount"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "available",
      name: "Available for Allocation" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"available"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "amountcredit",
      name: "Credit Applied" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"amountcredit"} data={data} section="debtorsfunds" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "makeanote",
      name: "INCOMING FUNDS NOTE" + postValue,
      width: cellWidthLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"makeanote"} data={data} section="debtorsfunds" />;
      },
    },
    {
      key: "bank",
      name: "Bank" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"bank"} data={data} section="debtorsfunds" />;
      },
    },
    {
      key: "status",
      name: "Status" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section="debtorsfunds" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (data: SaleFundsFullType, handleEdit, handleRemove, handleAllocate) => {
  const actions = [
    {
      icon: (
        <span title="Remove">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: () => handleRemove(data),
    },
  ];

  if (data.salefundsadhoc_id) {
    actions.push({
      icon: (
        <span title="Edit">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: () => handleEdit(data),
    });
  } else {
    actions.push({
      icon: (
        <span title="Allocation">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: () => handleAllocate(data),
    });
  }

  return actions;
};
