import React, { useEffect } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import IconEdit from "@material-ui/icons/Edit";
import IconDelete from "@material-ui/icons/Delete";

import { estimatedcostsFull } from "../../../lib/api/estimatedcost";
import { VarietiesByCommodity } from "../../../lib/api/variety";
import { estimatedcostsetups, estimatedcostsetupsFull } from "../../../lib/api/estimatedcostsetup";
import { estimatedcostitemsAllSortedMappedforCombo } from "../../../lib/api/estimatedcostitem";
import { palletspecsByCommodityCodeSortedMappedforCombo } from "../../../lib/api/palletspec";

import Confirmation from "../../../lib/components/confirmation";

import numeral from "numeral";

import { MaterialSelectNativeComponentNonField } from "../../../lib/helpers/materialcomponents";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginTop: "50px",
    },
    varietySelect: {
      width: "100%",
    },
  });

type EstimatedCostsSetupDetailProps = {
  estimatedcost_id: number;
  palletspec_id: number;
  submitSetupDetail(palletSpecId: number | string, detail: any[]): Promise<void>;
} & WithStyles<typeof styles>;

type EstimatedCostsSetupDetailEditingProps = {
  estimatedcostid: number;
  palletspecid: number;
};

const EstimatedCostsSetupDetailUnstyled: React.FunctionComponent<EstimatedCostsSetupDetailProps> = (props) => {
  const { classes, estimatedcost_id, palletspec_id, submitSetupDetail } = props;

  const [currentDetail, setCurrentDetail] = React.useState({});
  const [editingDetail, setEditingDetail] = React.useState<EstimatedCostsSetupDetailEditingProps>();
  const [estimatedCostItems, setEstimatedCostItems] = React.useState([]);

  const style = {
    tableCell: { borderWidth: "1px", paddingLeft: "2px", paddingRight: "2px", borderColor: "black", borderStyle: "solid", width: "200px" },
    tableCellValue: { borderWidth: "1px", paddingLeft: "2px", paddingRight: "2px", borderColor: "black", borderStyle: "solid", width: "100px" },
  };

  useEffect(() => {
    loadData();
  }, [estimatedcost_id, palletspec_id]);

  const loadData = () => {
    estimatedcostsetupsFull(estimatedcost_id, palletspec_id).then((result) => {
      if (result && result.data && result.data.length > 0) {
        const estimatedcostsetupsFull = result.data.reduce((acc, curr) => {
          if (!acc[curr.palletspec_id]) {
            acc[curr.palletspec_id] = [];
          }
          acc[curr.palletspec_id].push(curr);
          return acc;
        }, {});

        setCurrentDetail(estimatedcostsetupsFull);
      }
    });
  };

  useEffect(() => {
    estimatedcostitemsAllSortedMappedforCombo().then((result) => {
      setEstimatedCostItems(result);
    });
  }, []);

  const handleEdit = (psid) => {
    setEditingDetail({ estimatedcostid: estimatedcost_id, palletspecid: psid });
  };

  const handleEditClose = () => {
    setEditingDetail(undefined);
  };

  const handleEditConfirm = async (newPalletSpecId: number | string, detail: any[]) => {
    await submitSetupDetail(newPalletSpecId, detail);
    handleEditClose();
    loadData();
  };

  let totalProducerCost = 0;

  return (
    <div className={classes.root}>
      {/* <div style={{ paddingTop: "5px" }}>
        <Button variant="contained" color="secondary" style={{ margin: "2px", width: "200px" }}>
          add new setup
        </Button>
      </div> */}
      {Object.keys(currentDetail).map((pskey) => {
        totalProducerCost = 0;
        return (
          <div style={{ float: "left", marginRight: "10px" }}>
            <span style={{ fontWeight: "bold" }}>
              Pallet Spec: ({currentDetail[pskey][0].palletspec_code}) {currentDetail[pskey][0].palletspec_carton}
            </span>
            <table>
              <tbody>
                <tr>
                  <td style={{ ...style.tableCell, fontWeight: "bold" }}>Group</td>
                  <td style={{ ...style.tableCell, fontWeight: "bold" }}>Description</td>
                  <td style={{ ...style.tableCellValue, fontWeight: "bold" }}>Value</td>
                  <td style={{ ...style.tableCell, fontWeight: "bold" }}>Varieties</td>
                </tr>
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 0) {
                    totalProducerCost += di.value;
                    return (
                      <tr key={`row0${di.id}`}>
                        <td style={style.tableCell}>{di.group_name}</td>
                        <td style={style.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td style={style.tableCellValue}>{numeral(di.value).format("0.00")}</td>
                        <td style={style.tableCellValue}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
                <tr>
                  <td>Total Producer Cost</td>
                  <td style={{ fontWeight: "bold" }}>{numeral(totalProducerCost).format("0.00")}</td>
                </tr>
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 1) {
                    totalProducerCost += di.value;
                    return (
                      <tr key={`row1${di.id}`}>
                        <td style={style.tableCell}>{di.group_name}</td>
                        <td style={style.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td style={style.tableCellValue}>{numeral(di.value).format("0.00")}</td>
                        <td style={style.tableCellValue}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
                {currentDetail[pskey].map((di) => {
                  if (di.estimatedcostitem_type == 2) {
                    totalProducerCost += di.value;
                    return (
                      <tr key={`row2${di.id}`}>
                        <td style={style.tableCell}>{di.group_name}</td>
                        <td style={style.tableCell}>{di.estimatedcostitem_costdescription}</td>
                        <td style={style.tableCellValue}>{numeral(di.value).format("0.00")} %</td>
                        <td style={style.tableCellValue}>{di.variety ? di.variety : "ALL"}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <div style={{ paddingTop: "5px", float: "right", marginRight: "-2px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "2px", width: "100px" }}
                onClick={() => {
                  handleEdit(pskey);
                }}
              >
                EDIT
              </Button>
            </div>
          </div>
        );
      })}
      <Confirmation isOpen={editingDetail ? true : false} handleClose={handleEditClose} handleConfirm={handleEditClose} title={"Estimated Costs"} body={undefined}>
        <CostEstimationSetup editingDetail={editingDetail} estimatedCostItems={estimatedCostItems} handleSubmit={handleEditConfirm} cancel={handleEditClose} classes={classes} />
      </Confirmation>
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupDetailUnstyled);

const CostEstimationSetup: React.FunctionComponent<any> = (props) => {
  const { classes, editingDetail, estimatedCostItems, handleSubmit, cancel } = props;

  const [detail, setDetail] = React.useState(editingDetail);
  const [costEstimationDetail, setCostEstimationDetail] = React.useState([]);
  const [varieties, setVarieties] = React.useState(undefined);
  const [palletspecs, setPalletspecs] = React.useState([]);
  const [estimatedCostHead, setEstimatedCostHead] = React.useState({
    cartons: undefined,
    commodity: undefined,
    commodity_full: undefined,
    created_at: undefined,
    exchange_usd: undefined,
    exchange_eur: undefined,
    exchange_gbp: undefined,
    exchange_cad: undefined,
    exchange_zar: undefined,
    id: undefined,
    market: undefined,
    pack: undefined,
    season: undefined,
    updated_at: undefined,
  });
  const [comboVarietyOpen, setComboVarietyOpen] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    loadData();
  }, [editingDetail]);

  const loadData = () => {
    if (editingDetail) {
      estimatedcostsetupsFull(editingDetail.estimatedcostid, editingDetail.palletspecid)
        .then((result) => {
          return result.data;
        })
        .then((data) => {
          estimatedcostsFull(detail.estimatedcostid).then(async (result) => {
            setEstimatedCostHead(result.data[0]);
            await loadVarieties(result.data[0].commodity);
            palletspecsByCommodityCodeSortedMappedforCombo(result.data[0].commodity).then((result) => {
              setPalletspecs(result);
            });
            const reMappedEstimations = data.map((item) => {
              item.value = numeral(item.value).format("0.00");
              item.usd = calculateRate(item.value, result.data[0].exchange_usd);
              item.cad = calculateRate(item.value, result.data[0].exchange_cad);
              item.gbp = calculateRate(item.value, result.data[0].exchange_gbp);
              item.eur = calculateRate(item.value, result.data[0].exchange_eur);
              return item;
            });
            setCostEstimationDetail(reMappedEstimations);
          });
        });
    }
  };

  const handleChangedDetail = (id: number, field: string, value: any) => {
    const idx = costEstimationDetail.findIndex((item) => item.id == id);
    const detailChange = [...costEstimationDetail];
    detailChange[idx][field] = !value ? "" : field == "variety" ? (value.includes("ALL") ? "ALL" : value.join(",")) : value;
    setCostEstimationDetail([...detailChange]);
  };

  const calculateRate = (value, exchange) => {
    return (value / exchange).toFixed(5);
  };

  const currencies = {
    eur: estimatedCostHead.exchange_eur,
    usd: estimatedCostHead.exchange_usd,
    gbp: estimatedCostHead.exchange_gbp,
    cad: estimatedCostHead.exchange_cad,
  };

  const handleUpdateCurrency = (id, field) => {
    const idx = costEstimationDetail.findIndex((item) => {
      return item.id == id;
    });

    const detailChange = [...costEstimationDetail];

    if (field != "ZAR") {
      detailChange[idx].value = (detailChange[idx][field] * currencies[field]).toFixed(2);
    }

    JSON.parse(JSON.stringify(Object.keys(currencies)))
      .filter((currency) => currency != field)
      .map((key) => {
        detailChange[idx][key] = calculateRate(detailChange[idx].value, currencies[key]);
      });
    setCostEstimationDetail([...detailChange]);
  };

  const handleSave = () => {
    handleSubmit(detail.palletspecid, costEstimationDetail);
  };

  const handleAddLine = () => {
    const createData = {
      data: {
        estimatedcost_id: detail.estimatedcostid,
        palletspec_id: detail.palletspecid,
        estimatedcostitem_id: 1,
        value: 1,
      },
    };
    estimatedcostsetups.create(createData).then((result) => {
      const id = result[0];
      const detailChange = [...costEstimationDetail];
      detailChange.push({ ...createData.data, id: id });
      setCostEstimationDetail([...detailChange]);
    });
  };

  const handleRemoveLine = async (id) => {
    await estimatedcostsetups.remove(id);
    loadData();
  };
  const loadVarieties = async (commodity) => {
    await VarietiesByCommodity(commodity).then((result) => {
      setVarieties(result.data);
    });
  };
  const handleChanges = (field, value) => {
    setDetail({ ...detail, [field]: value });
  };

  const handleVarietyCombo = (id: string) => {
    setComboVarietyOpen(id);
  };

  return (
    <div>
      {detail && (
        <div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Season </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.season}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Commodity </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>{` ${estimatedCostHead.commodity_full} (${estimatedCostHead.commodity})`}</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Market </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.market}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Pack </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.pack}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Cartons per Pallet </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.cartons}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Pallet Spec </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>
                    <MaterialSelectNativeComponentNonField
                      name={"palletspecid"}
                      onChange={(e) => handleChanges("palletspecid", e.target.value)}
                      value={detail.palletspecid}
                      addNone={false}
                      childdata={palletspecs}
                      nonedisabled={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}></td>
                  <td style={{ width: "300px", fontWeight: "bold" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>USD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>EUR</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>GBP</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>CAD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>ZAR</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Group</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Item</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_usd).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_eur).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_gbp).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_cad).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_zar).format("0.00")}</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Varieties</td>
                  <td></td>
                </tr>
                {costEstimationDetail.map((ced, index) => {
                  return (
                    <tr key={`row${ced.id}${index}`}>
                      <td key={`group${ced.id}${index}`}>{ced.group_name}</td>
                      <td key={`item${ced.id}${index}`}>
                        <MaterialSelectNativeComponentNonField
                          name={`item${ced.id}`}
                          onChange={(e) => handleChangedDetail(ced.id, "estimatedcostitem_id", e.target.value)}
                          value={ced.estimatedcostitem_id}
                          addNone={false}
                          childdata={estimatedCostItems}
                          nonedisabled={true}
                        />
                      </td>

                      {/* USD */}
                      <td key={`USD${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`USD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "usd")}
                            onChange={(e) => handleChangedDetail(ced.id, "usd", e.target.value)}
                            value={ced.usd || 0.0}
                          />
                        )}
                      </td>

                      {/* EUR */}
                      <td key={`EUR${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`EUR${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "eur")}
                            onChange={(e) => handleChangedDetail(ced.id, "eur", e.target.value)}
                            value={ced.eur || 0.0}
                          />
                        )}
                      </td>

                      {/* GBP */}
                      <td key={`GBP${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`GBP${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "gbp")}
                            onChange={(e) => handleChangedDetail(ced.id, "gbp", e.target.value)}
                            value={ced.gbp || 0.0}
                          />
                        )}
                      </td>

                      {/* CAD */}
                      <td key={`CAD${ced.id}${index}`} style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`CAD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "cad")}
                            onChange={(e) => handleChangedDetail(ced.id, "cad", e.target.value)}
                            value={ced.cad || 0.0}
                          />
                        )}
                      </td>

                      {/* ZAR */}
                      <td key={`ZAR${ced.id}${index}`}>
                        <TextField
                          name={`ZAR${ced.id}${index}`}
                          onBlur={(e) => handleUpdateCurrency(ced.id, "ZAR")}
                          onChange={(e) => handleChangedDetail(ced.id, "value", e.target.value)}
                          value={ced.value || 0.0}
                          style={{ width: "100%" }}
                        />
                      </td>

                      <td key={`col2${ced.id}${index}${ced.variety}`}>
                        <Select
                          multiple
                          displayEmpty
                          className={classes.varietySelect}
                          disabled={ced.estimatedcostitem_type == 0}
                          value={(ced.variety && ced.variety.split(",")) || []}
                          open={comboVarietyOpen == ced.id}
                          onOpen={(e) => handleVarietyCombo(ced.id)}
                          onClose={(e) => handleVarietyCombo(undefined)}
                          onChange={(e) => handleChangedDetail(ced.id, "variety", e.target.value)}
                          renderValue={(value) => {
                            if (!value) return undefined;
                            const selected = JSON.parse(JSON.stringify(value));
                            if (!selected[0] || selected[0].length === 0) {
                              // Purposely allowing different selected varieties to
                              // show in case there are any(instead of hiding and
                              // polluting the db)
                              return ced.estimatedcostitem_type != 0 ? <em>Please make a selection</em> : <em>ALL</em>;
                            }
                            if (selected) {
                              return <em>{`${selected.join(", ")}`}</em>;
                            }
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>Please make a selection</em>
                          </MenuItem>
                          <MenuItem value={"ALL"}>ALL</MenuItem>
                          {(varieties || []).map((variety) => (
                            <MenuItem key={variety.code} value={variety.code}>
                              {`${variety.code} (${variety.name})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <IconButton color="secondary" onClick={() => handleRemoveLine(ced.id)}>
                          <IconDelete />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ paddingTop: "5px", float: "left" }}>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleAddLine()}>
                add line
              </Button>
              <Button variant="contained" color="secondary" style={{ margin: "2px", width: "100px" }} onClick={() => cancel()}>
                cancel
              </Button>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleSave()}>
                save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
