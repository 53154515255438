import React, { useState, useEffect } from "react";

import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconDelete from "@material-ui/icons/Delete";
import DropdownEditor from "../../lib/components/DropdownEditor";
import TextEditor from "../../lib/components/TextEditor";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { SelectColumn } from "react-data-grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      appearance: "none",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      padding: "0px 6px 0 6px",
      border: "2px solid #ccc",
      verticalAlign: "top",
      color: "black",
      textAlign: "right",
      fontSize: "15px",
      "&:focus": {
        borderColor: "black",
        outline: "none",
      },
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  });

const CountEditorUnstyled = ({ row, column, counts, onRowChange, classes }) => {
  const [count, setCount] = useState(row[column.key]);
  const [dataItems, setDataItems] = useState([]);
  const [rowData, setRowData] = useState({ ...row });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const dataItemsList = counts.filter((item) => {
      if (rowData.palletspec_id) {
        if (item.palletspec_id.toString() == rowData.palletspec_id.toString()) {
          return item;
        }
      }
    });

    const sortDataItems = dataItemsList.sort((a, b) => {
      if (Number(a.code) > Number(b.code)) return 1;
      if (Number(a.code) < Number(b.code)) return -1;
      return 1;
    });

    setDataItems(sortDataItems);
  }, [counts]);

  const handleChangeComplete = (e) => {
    const newvalue = e.target.value
      .sort((a, b) => {
        if (Number(a) > Number(b)) return 1;
        if (Number(a) < Number(b)) return -1;
        return 1;
      })
      .join("/");
    setCount(newvalue);
    onRowChange({ ...row, [column.key]: newvalue });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Select
      autoFocus
      open={open}
      multiple={true}
      onClose={handleClose}
      value={count.split("/")}
      onChange={handleChangeComplete}
      style={{ width: "100%", height: "100%", backgroundColor: "lightgray" }}
    >
      {dataItems.map((item) => {
        return (
          <MenuItem key={item.id} value={item.code}>
            {item.code}
          </MenuItem>
        );
      })}
    </Select>
  );
};

const CountEditor = withStyles(styles)(CountEditorUnstyled);

const DirtyFormatter = ({ row, column }) => {
  if (row) {
    const colorNew = row.isDirty && row.isDirty == 1 ? "red" : "black";
    return <div style={{ color: colorNew }}>{row[column.key]}</div>;
  }
  return <div>{row[column.key]}</div>;
};

const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 150;

export const TemplatesDetailColumns = (
  maintenancemode,
  targetsRegion,
  targetsMarket,
  targetsCountry,
  counts,
  classesArr,
  palletspecs,
  coldrooms,
  brands,
  inventories,
  mgps,
  handleRemove,
) => {
  const columns: any[] = [
    {
      key: "groupline",
      name: "Line",
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: DirtyFormatter,
      width: cellWidthSmallX,
    },
    {
      key: "volume",
      name: "Volume",
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      formatter: DirtyFormatter,
      width: cellWidthMedium,
    },
    {
      key: "region",
      name: "TR",
      editor: (props) => <DropdownEditor {...props} options={targetsRegion} valueKey="value" />,
      width: cellWidthSmall,
      formatter: DirtyFormatter,
    },
    {
      key: "market",
      name: "TM",
      editor: (props) => <DropdownEditor {...props} options={targetsMarket} valueKey="value" />,
      width: cellWidthSmallX,
      formatter: DirtyFormatter,
    },
    {
      key: "country",
      name: "TC",
      editor: (props) => <DropdownEditor {...props} options={targetsCountry} valueKey="value" />,
      width: cellWidthSmallX,
      formatter: DirtyFormatter,
    },
    {
      key: "class",
      name: "Class",
      editor: (props) => <DropdownEditor {...props} options={classesArr} valueKey="value" />,
      width: cellWidthSmall,
      formatter: DirtyFormatter,
    },
    {
      key: "palletspec_name",
      name: "Pallet Spec",
      editor: (props) => <DropdownEditor {...props} options={palletspecs} valueKey="value" />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "count",
      name: "Count",
      editor: (props) => <CountEditor {...props} counts={counts} />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "countconstraint",
      name: "Constraint",
      editable: true,
      editor: (props) => <TextEditor {...props} />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "coldroom",
      name: "Coldroom",
      editor: (props) => <DropdownEditor {...props} options={coldrooms} valueKey="value" />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "brand",
      name: "Brand",
      editor: (props) => <DropdownEditor {...props} options={brands} valueKey="value" />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "inventory_description",
      name: "Inventory",
      editor: (props) => <DropdownEditor {...props} options={inventories} valueKey="value" />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
    {
      key: "mgp",
      name: "Terms",
      editor: (props) => <DropdownEditor {...props} options={mgps} />,
      width: cellWidthMedium,
      formatter: DirtyFormatter,
    },
  ];

  if (!maintenancemode) {
    columns.splice(1, 0, SelectColumn);
  } else {
    columns.splice(0, 0, {
      key: "actions",
      name: "Actions",
      width: cellWidthSmallX,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    });
  }

  return columns;
};

const cellActions = (templatedetail, handleRemove) => {
  let actions = [
    {
      icon: (
        <span title="Delete Template Line">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(templatedetail.id);
      },
    },
  ];
  return actions;
};
// ^^^^^ ACTIONS ^^^^^

export const TemplatesDetailSelectedColumns = () => {
  const columns = [
    { key: "templatename", name: "Template Name", width: cellWidthMedium },
    { key: "groupnum", name: "Order", width: cellWidthSmallX },
    { key: "groupline", name: "Line", width: cellWidthSmallX },
    { key: "volume", name: "Volume", width: cellWidthMedium },
    { key: "region", name: "TR", width: cellWidthSmall },
    { key: "market", name: "TM", width: cellWidthSmallX },
    { key: "country", name: "TC", width: cellWidthSmallX },
    { key: "class", name: "Class", width: cellWidthSmall },
    { key: "palletspec_name", name: "Pallet Spec", width: cellWidthMedium },
    { key: "count", name: "Count", width: cellWidthMedium },
    { key: "countconstraint", name: "Constraint", editable: true, width: cellWidthMedium, editor: (props) => <TextEditor {...props} /> },
    { key: "coldroom", name: "Coldroom", width: cellWidthMedium },
    { key: "brand", name: "Brand", width: cellWidthMedium },
    { key: "inventory_description", name: "Inventory", width: cellWidthMedium },
    { key: "mgp", name: "Terms", width: cellWidthMedium },
  ];
  return columns;
};
