import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { SnackContext } from "../../lib/context/SnackContext";
import useInterval from "../../lib/helpers/useInterval";

import { getCount } from "../../lib/api/consolidationdataqueue";

import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type ConsolidationQueueTimerProps = {
  refreshChanged: boolean;
  shouldRefresh: boolean;
  refreshTimerCount: boolean;
  toggleRefreshTimer(shouldRefresh: boolean): void;
} & WithStyles<typeof styles>;

const ConsolidationQueueTimerUnstyled: React.FunctionComponent<ConsolidationQueueTimerProps> = (props) => {
  const { classes, refreshChanged, toggleRefreshTimer, shouldRefresh, refreshTimerCount } = props;
  const { updateSnack } = React.useContext(SnackContext);

  const [count, setCount] = useState(0);
  const [started, setStarted] = useState(false);

  const refreshCount = async () => {
    try {
      const result = await getCount();
      if (result && result.length > 0) {
        setCount(Number(result[0]["count"]));
        if (Number(result[0]["count"]) != 0) {
          setStarted(true);
        }
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving queue count");
      updateSnack({ show: true, color: "red", message: err });
    };
  }

  useInterval(() => {
    refreshCount();
  }, 10000);

  useEffect(() => {
    if (refreshChanged) {
      toggleRefreshTimer(false);
    }
  }, [refreshChanged]);

  useEffect(() => {
    // refresh count value
    refreshCount();
  }, [refreshTimerCount]);

  useEffect(() => {
    // set button to refresh
    if (count <= 0 && started) {
      toggleRefreshTimer(true);
    }
  }, [count, started]);

  return <span>{count > 0 ? `Processing ${count} records...` : `${shouldRefresh ? "REFRESH REQUIRED" : ""}`}</span>;
};

export default withStyles(styles)(ConsolidationQueueTimerUnstyled);
