import React, { useEffect, useState, FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Confirmation from "../../lib/components/confirmation";
import { DialogInformation } from "../../lib/components/dialoginformation";

import { estimatedcosts, EstimatedCostsData, estimatedcostsFull, execRefreshConsolidationCache, upsertEstimatedCostPorts } from "../../lib/api/estimatedcost";
import { estimatedcostsetups, estimatedcostsetupsFull } from "../../lib/api/estimatedcostsetup";

import { EstimatedCostGrid } from "./estimatedcosts/estimatedcostgrid";
import EstimatedCostsSetup from "./estimatedcostssetup/estimatedcostssetup";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "3px",
      marginLeft: "7px",
    },
  });

type EstimatedCostProps = {} & WithStyles<typeof styles>;

const EstimatedCostUnstyled: FC<EstimatedCostProps> = (props) => {
  const { classes } = props;

  const [currentData, setCurrentData] = useState<EstimatedCostsData>(undefined);
  const [copyData, setCopyData] = useState<EstimatedCostsData>(undefined);

  const [isDirty, setIsDirty] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [error, setError] = useState();

  const [removeID, setRemoveID] = useState(undefined);

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const handleCreate = () => {
    setCurrentData({
      id: -1,
      original_id: undefined,
      palletspec_id: undefined,
      cartons: "",
      commodity: "",
      commodity_full: "",
      created_at: "",
      exchange_cad: 0,
      exchange_eur: 0,
      exchange_gbp: 0,
      exchange_usd: 0,
      exchange_zar: 0,
      market: "",
      pack: "",
      season: "",
      status: 0,
      totalproducercost_cad: 0,
      totalproducercost_eur: 0,
      totalproducercost_gbp: 0,
      totalproducercost_usd: 0,
      totalproducercost_zar: 0,
      updated_at: "",
      variety: "",
      container: 1,
      conventional: 0,
      port_id: 0,
      port: "",
    });
  };

  const handleEdit = async (row: EstimatedCostsData) => {
    setCurrentData({ ...row, original_id: row.id });
  };

  const handleCopy = async (row: EstimatedCostsData) => {
    setCopyData({ ...row, id: -1, original_id: row.id });
  };

  const handleCopyClose = (ecid) => {
    setCopyData(undefined);
    setCurrentData(undefined);
  };

  const handleCopyConfirm = () => {
    setCopyData(undefined);
    setCurrentData(copyData);
  };

  const submitHeadData = async (values) => {
    if (!values) return;

    let id = values.id;

    const ecdata = {
      data: {
        season: values.season,
        commodity: values.commodity,
        market: values.market,
        exchange_cad: values.exchange_cad,
        exchange_eur: values.exchange_eur,
        exchange_gbp: values.exchange_gbp,
        exchange_usd: values.exchange_usd,
        exchange_zar: values.exchange_zar,
        container: values.container,
        conventional: values.conventional,
      },
    };

    if (id && id != 0 && id != -1) {
      await estimatedcosts.update(id, ecdata).then(() => {
        const markets = typeof values.market === "object" ? values.market.join(",") : values.market;
        setCurrentData({ ...values, market: markets });
      });
    } else {
      const result = await estimatedcosts.create(ecdata);
      id = result[0];

      // check if original id exists, treat as creating copy
      if (currentData.original_id) {
        const ecfull = await estimatedcostsetupsFull(currentData.original_id);
        for (let i = 0; i < ecfull.data.length; i++) {
          const ecfullitem = ecfull.data[i];
          const ecfullitemData = {
            data: {
              estimatedcost_id: id,
              variety: ecfullitem.variety,
              palletspec_id: ecfullitem.palletspec_id,
              estimatedcostitem_id: ecfullitem.estimatedcostitem_id,
              value: ecfullitem.value,
            },
          };
          await estimatedcostsetups.create(ecfullitemData);
        }
        setCopyData(undefined);
      } else {
        const createData = {
          data: {
            estimatedcost_id: id,
            palletspec_id: 1,
            estimatedcostitem_id: 1,
            value: 99,
          },
        };
        await estimatedcostsetups.create(createData);
      }

      // set head data
      const markets = typeof values.market === "object" ? values.market.join(",") : values.market;
      setCurrentData({ ...values, id: id, original_id: id, market: markets });
    }
    await upsertEstimatedCostPorts({ estimatedcost_id: id, ports: values.port_id });
    execRefreshConsolidationCache();
    return id;
  };

  const handleEditClose = () => {
    loadData();
    if (isDirty) {
      setConfirmDirty(true);
    } else {
      setCurrentData(undefined);
    }
  };

  const handleConfirmContinueClose = () => {
    setConfirmDirty(false);
  };

  const handleConfirmContinueConfirm = async () => {
    setConfirmDirty(false);
    setIsDirty(false);
    setCurrentData(undefined);
  };

  const handleErrorClose = () => {
    setError(undefined);
  };

  const handleRemove = (ecid) => {
    setRemoveID(ecid);
  };

  const handleRemoveClose = (ecid) => {
    setRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    // update status field to 1 (deleted)
    await estimatedcosts.remove(removeID);
    setRemoveID(undefined);
    loadData();
  };

  const loadData = async () => {
    setDataLoading(true);
    const result = await estimatedcostsFull();
    setData(result.data);
    setDataLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        <Tooltip title="Create new Average Cost Estimation">
          <div className={classes.inline}>
            <Button variant="contained" color="primary" onClick={handleCreate}>
              <IconAdd />
            </Button>
          </div>
        </Tooltip>
      </div>
      <EstimatedCostGrid handleEdit={handleEdit} handleRemove={handleRemove} handleCopy={handleCopy} data={data} dataLoading={dataLoading} loadData={loadData} />
      {error && <DialogInformation isOpen={true} handleClose={handleErrorClose} handleOK={handleErrorClose} title={"Error!"} body={`${error}`} showinput={false} />}
      {confirmDirty && (
        <Confirmation
          isOpen={currentData ? true : false}
          handleClose={handleConfirmContinueClose}
          handleConfirm={handleConfirmContinueConfirm}
          title={"Unsaved Changes"}
          body={`You have unsaved data. Do you want to continue?`}
        />
      )}
      {removeID && (
        <Confirmation
          isOpen={removeID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title={"Remove Record"}
          body={`Are you sure you want to remove any data processed for the selected Estimated Cost?`}
        />
      )}
      {copyData && (
        <Confirmation
          isOpen={true}
          handleClose={handleCopyClose}
          handleConfirm={handleCopyConfirm}
          title={"Copy Record"}
          body={`Are you sure you want to copy the data of the selected Estimated Cost?`}
        />
      )}
      {currentData && (
        <Confirmation isOpen={true} body={undefined} title={"Estimated Costs"} handleClose={handleEditClose} handleConfirm={handleEditClose} disableBackdropClick={isDuplicate}>
          <EstimatedCostsSetup currentData={currentData} data={data} isDuplicate={isDuplicate} setIsDuplicate={setIsDuplicate} submitHeadData={submitHeadData} />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(EstimatedCostUnstyled);
