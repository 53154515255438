import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";

import { counts, countFull } from "../../lib/api/counts";
import { palletspecs, palletspecFull } from "../../lib/api/palletspec";

import { ComboSelection } from "../../lib/components/ComboSelection";
import Input from "@material-ui/core/Input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableAlign: {
      marginLeft: "10%",
      width: "200px",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    tableRowSpecs: {
      width: "1000px",
      paddingTop: theme.spacing(1),
      textAlign: "left",
    },
    codeInput: {
      paddingLeft: "10px",
      width: "170px",
      float: "left",
    },
    formContainer: {
      width: "300px",
    },
  });

type CountsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class CountsEditFormUnstyled extends React.Component<CountsEditFormProps, any> {
  state = {
    classes: undefined,
    loading: true,
    item: { id: "0", code: "0", palletspec_id: "0" },
    onSubmit: undefined,
    onClose: undefined,
    palletspecs: [],
    id: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    if (props.id != 0) {
      this.state.id = props.id;
    }
  }

  componentDidMount() {
    palletspecFull().then((result) => {
      const resultSorted = result.data.sort((a, b) => {
        if (Number(a.code) < Number(b.code)) return -1;
        if (Number(a.code) > Number(b.code)) return 1;
        return 1;
      });
      this.setState({ palletspecs: resultSorted }, () => {
        if (this.state.id) {
          this.getItem(this.state.id);
        }
        this.setState({ loading: false });
      });
    });
  }

  getItem = (id) => {
    counts.single(id).then((data) => {
      this.setState({ item: data, loading: false }, () => {});
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  onSelectionChange = (value) => {
    this.setState({ item: { ...this.state.item, palletspec_id: value } });
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <div>
            <LinearProgress />
          </div>
        ) : (
          <form
            className={classes.formContainer}
            id={this.state.item.id}
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            <table className={classes.tableAlign}>
              <tbody style={{ minWidth: "100px !important", maxWidth: "250px" }}>
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow}>
                    <span>Code: </span>
                  </td>
                  <td className={classes.codeInput}>
                    <Input
                      onFocus={this.handleFocus}
                      key={"body_value_code"}
                      name={"code"}
                      value={this.state.item.code}
                      onChange={(e) => {
                        this.setState({
                          item: { ...this.state.item, code: e.target.value },
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.tableRow}>
                    <span>PalletSpec:</span>
                  </td>
                  <td className={classes.tableRowSpecs}>
                    <ComboSelection
                      classes={classes}
                      name="countcode"
                      selected={this.state.item.palletspec_id}
                      setSelected={this.onSelectionChange}
                      data={this.state.palletspecs.map((psitem) => {
                        return { id: psitem.id, code: psitem.id, name: `${psitem.code}-${psitem.carton}` };
                      })}
                      compact={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginBottom: "10px", marginRight: "10px" }}>
              <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                <Close />
              </Button>
              <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
                <Check />
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CountsEditFormUnstyled);
