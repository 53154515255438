import React, { useEffect, useMemo, useRef, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Settings from "@material-ui/icons/Settings";
import Grid from "@material-ui/core/Grid";

import { whoami } from "../lib/api/_base";
import { agent } from "../lib/api/agent";
import { getDealTypeReadyForCombo } from "../lib/api/dealtype";
import { clientsfinanceByClientID } from "../lib/api/clientsfinance";
import { getAllClients } from "../lib/api/clients";
import { getFinancialYearSelected, weeksOrdered, getWeekByDate } from "../lib/api/week";
import { temperaturecodes } from "../lib/api/temperaturecodes";
import { getVesselReadyForComboCodeOnly } from "../lib/api/vessel";
import Confirmation from "../lib/components/confirmation";
import { getPortReadyForCombo } from "../lib/api/port";
import { GenerateComboReadyData } from "../lib/helpers/GenerateComboReadyData";
import { getShippingLineReadyForCombo } from "../lib/api/shippingline";
import { getColdroomReadyForCombo } from "../lib/api/coldroom";
import { getAgentsReadyForCombo } from "../lib/api/agent";
import { getLastLoadout } from "../lib/api/loadout";

import Vessel from "../maintenance/vessel/vessel";

import moment from "moment";
import format from "date-fns/format";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import MTextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";

import ReactDatePicker from "react-datepicker";
import parseISO from "date-fns/parseISO";
import toDate from "date-fns/toDate";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    tableRows: {
      margin: theme.spacing(2),
    },
    tableCellsDesc: {
      paddingRight: theme.spacing(2),
      height: "30px",
      width: "110px",
      textAlign: "right",
      paddingBottom: "10px",
    },
    tableCellsValue: {
      textAlign: "left",
      paddingBottom: "10px",
      width: "250px",
    },
    tableCellsValueSelect: {
      marginTop: "-12px",
      textAlign: "left",
      width: "250px",
      marginBottom: "15px",
      height: "35px",
    },
    dropdownOption: {
      paddingTop: "3px",
      paddingBottom: "3px",
      paddingLeft: "10px",
      paddingRight: "10px",
      fontSize: "16px",
    },
  });

type AcceptAllocationDialogProps = {
  onSubmit: any;
  defaultData: any;
} & WithStyles<typeof styles>;

const ZA = "ZA";
const EU = "EU";
const UK = "UK";

export class AcceptAllocationDialogUnstyled extends React.Component<AcceptAllocationDialogProps, any> {
  state = {
    onSubmit: undefined,
    formRecord: {
      client: "",
      dealtype: "",
      destination: "",
      ponumber: "",
      reference: "",
      temperaturecode: "",
      username: "",
      actionpricing: false,
      actionloadout: false,
      vessel_id: undefined,
    },
    clients: undefined,
    weeks: undefined,
    temperatureCodes: [],
    ports: undefined,
    portsZA: undefined,
    vessels: [],
    userDetail: undefined,
    classes: undefined,
    dealtypes: [],
    shippingLines: undefined,
    coldrooms: undefined,
    agents: undefined,
    agentsDefault: undefined,
    loading: true,
    lastLoadout: {},
    currentWeek: 0,
  };

  constructor(props) {
    super(props);
    this.state.onSubmit = props.onSubmit;
    this.state.classes = props.classes;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.loadData();
    this.getUser();
    this.setState({ loading: false });
  }

  loadData = async () => {
    const [clients, weeks, temperatureCodes, ports, portsZA, vessels, dealtypes, shippingLines, coldrooms, agents, agentsAll, lastLoadout, currentWeek] = await Promise.all([
      getAllClients(),
      weeksOrdered(getFinancialYearSelected()),
      temperaturecodes.all(),
      getPortReadyForCombo(),
      getPortReadyForCombo(ZA),
      getVesselReadyForComboCodeOnly(),
      getDealTypeReadyForCombo(),
      getShippingLineReadyForCombo(),
      getColdroomReadyForCombo(),
      getAgentsReadyForCombo(),
      agent.all(),
      getLastLoadout(),
      getWeekByDate(format(new Date(), "yyyy-MM-dd")),
    ]);
    console.log("lastLoadout", lastLoadout);
    const agentsDefault = agentsAll.find((agent) => agent.default === 1);
    this.setState({
      clients,
      weeks: weeks.data,
      temperatureCodes,
      ports,
      portsZA,
      vessels,
      dealtypes,
      shippingLines,
      coldrooms,
      agents,
      agentsDefault: agentsDefault ? agentsDefault : agentsAll[0],
      lastLoadout: lastLoadout.data[0],
      currentWeek: currentWeek[0].id,
    });
  };

  getUser = async () => {
    await whoami().then((data) => {
      this.setState({ userDetail: data, formRecord: { ...this.state.formRecord, username: data.username } });
    });
  };

  handleChangeClient = async (client, form) => {
    const fin = await clientsfinanceByClientID(this.state.clients.find((c) => c.code == client).id);
    if (fin.data[0] && fin.data[0].dealtype) {
      form.change("dealtype", fin.data[0].dealtype);
    } else {
      console.log("No Default Deal Type");
    }
  };

  UpdateVessels = async () => {
    await getVesselReadyForComboCodeOnly().then((result) => {
      this.setState({ vessels: result });
    });
  };

  getClientsReadyComboData = (data = []) => {
    const sorted = (data || []).sort((a, b) => a.name.localeCompare(b.name));
    return sorted.map((item) => ({
      value: item.code,
      display: item.name,
      data: item,
    }));
  };

  render() {
    const clientsMapped = this.getClientsReadyComboData(this.state.clients);
    const weeksMapped = GenerateComboReadyData(this.state.weeks, "period_start", "id", "week", false, true);
    const filteredTemperatureCodes = this.state.temperatureCodes.filter((temp) => temp.description && temp.description.length > 0);
    const temperatureCodesMapped = GenerateComboReadyData(filteredTemperatureCodes, "description", "code", "description", true);
    const temperatureCodesMappedSorted = (temperatureCodesMapped || []).sort((a, b) => a.display.localeCompare(b.display));

    return (
      <div style={{ height: "calc(100vh - 150px)", overflow: "auto", width: "390px" }}>
        {this.state.loading ? (
          <LinearProgress />
        ) : (
          <AcceptAllocation
            onSubmit={this.state.onSubmit}
            defaultRecord={this.state.formRecord}
            clients={clientsMapped}
            weeks={weeksMapped}
            classes={this.state.classes}
            temperaturecodes={temperatureCodesMappedSorted}
            ports={this.state.ports}
            portsZA={this.state.portsZA}
            dealtypes={this.state.dealtypes}
            defaultData={this.props.defaultData}
            handleChangeClient={this.handleChangeClient}
            vessels={this.state.vessels}
            getVessels={this.UpdateVessels}
            shippingLines={this.state.shippingLines}
            coldrooms={this.state.coldrooms}
            agents={this.state.agents}
            agentsDefault={this.state.agentsDefault}
            lastLoadout={this.state.lastLoadout}
            currentWeek={this.state.currentWeek}
          />
        )}
      </div>
    );
  }
}

const AcceptAllocation = ({
  onSubmit,
  defaultRecord,
  clients,
  weeks,
  classes,
  temperaturecodes,
  ports,
  portsZA,
  dealtypes,
  defaultData,
  handleChangeClient,
  vessels,
  getVessels,
  shippingLines,
  coldrooms,
  agents,
  agentsDefault,
  lastLoadout,
  currentWeek,
}) => {
  const destinationPort = ports.find((p) => p.data.code.toString() === (defaultData && defaultData.pod ? defaultData.pod.toString() : ""));

  const [vesselMaintenanceOpen, setVesselMaintenanceOpen] = useState(false);
  const [dateLoading, setDateLoading] = useState(defaultData && defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_dateLoading)) : null);
  const [dateETD, setDateETD] = useState(defaultData && defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_etd)) : null);
  const [dateETA, setDateETA] = useState(defaultData && defaultData.loadout_id ? toDate(parseISO(defaultData.loadout_eta)) : null);

  const changeDate = (value) => {
    setDateLoading(value);
  };

  const changeDateETD = (value) => {
    setDateETD(value);
  };

  const changeDateETA = (value) => {
    setDateETA(value);
  };

  const handleEditDone = () => {
    // The header / toolbar just above the grid with buttons dissappears
    // for %$%^$ knows why when vessel maintenance is closed...this fixes that.
    //HACK START
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }
    //HACK END

    setVesselMaintenanceOpen(false);
    getVessels();
  };

  // TODO: cleanup
  const formRecord = useMemo(() => {
    let record = { ...defaultRecord, shipmentweek_id: currentWeek };

    if (defaultData) {
      record = {
        ...record,
        vessel_id: defaultData.loadout_vessel_id,
      };
    }

    if (defaultData && defaultData.consigneeId && defaultData.consigneeId.length > 0) {
      record = {
        ...record,
        client: defaultData.consigneeId,
        shipmentweek_id: defaultData.shipmentweek_id || currentWeek,
        destination: destinationPort ? destinationPort.data.id : undefined,
        ponumber: defaultData.po,
        reference: defaultData.reference,
        temperaturecode: defaultData.temp,
        dealtype: defaultData.clientsfinance_dealtype,
      };
    }

    if (defaultData && defaultData.loadout_id) {
      record = {
        ...record,
        loadout_exportNotificationReference: defaultData.loadout_exportNotificationReference,
        loadout_portLoading_id: defaultData.loadout_portLoading_id,
        loadout_shippingline_id: defaultData.loadout_shippingline_id,
        loadout_portFinal_id: defaultData.loadout_portFinal_id,
        loadout_voyageNumber: defaultData.loadout_voyageNumber,
        loadout_coldroom_id: defaultData.loadout_coldroom_id,
        loadout_agent_id: defaultData.loadout_agent_id,
        loadout_phytclean: defaultData.loadout_phytclean,
        loadout_specialinstruction: defaultData.loadout_specialinstruction,
        loadout_portDischarge_id: defaultData.loadout_portDischarge_id,
      };
    }
    return record;
  }, [defaultRecord, currentWeek, defaultData, destinationPort]);

  return (
    <Form
      initialValues={{
        ...formRecord,
        loadout_agent_id: formRecord["loadout_agent_id"] ? formRecord["loadout_agent_id"] : agentsDefault.id,
        client: defaultData && (defaultData.allocated || defaultData.loadout_id) ? defaultData.consigneeId : lastLoadout.consignee,
        dealtype: defaultData && (defaultData.clientsfinance_dealtype || defaultData.loadout_id) ? defaultData.clientsfinance_dealtype : lastLoadout.dealtype,
      }}
      onSubmit={(values) => {
        const destCode = ports.find((p) => p.data.id == values.loadout_portDischarge_id);
        const consid = clients.find((c) => c.data.code == values["client"]);
        const submitData = {
          ...values,
          loadout_dateLoading: dateLoading,
          loadout_etd: dateETD,
          loadout_eta: dateETA,
          loadout_consignee_id: consid.data.id,
          destination: destCode ? destCode.data.code : undefined,
        };
        onSubmit(submitData);
      }}
      validate={(values) => {
        let errors = {};

        // if (phytcleancompulsory) {
        //   if (!values["phytclean"] || values["phytclean"].toString().trim().length == 0) {
        //     errors = { ...errors, phytclean: "please add a code" };
        //   }
        // }

        if (!values["client"] || values["client"] === "") {
          errors = { ...errors, client: "please select a client" };
        }
        if (!values["reference"] || values["reference"] === 0) {
          errors = { ...errors, reference: "please enter a reference" };
        }
        // if (!values["loadout_coldroom_id"] || values["loadout_coldroom_id"] === 0) {
        //   errors = { ...errors, loadout_coldroom_id: "please select a coldroom" };
        // }
        // if (!values["destination"] || values["destination"] === "") {
        //   errors = { ...errors, destination: "please select a Port of Discharge" };
        // }
        // if (!values["loadout_portFinal_id"] || values["loadout_portFinal_id"] === 0) {
        //   errors = { ...errors, loadout_portFinal_id: "please select a Final Port" };
        // }
        // if (!values["loadout_portLoading_id"] || values["loadout_portLoading_id"] === 0) {
        //   errors = { ...errors, loadout_portLoading_id: "please select a Port of Loading" };
        // }
        if (dateETD && dateLoading && dateETD < dateLoading) {
          errors = { ...errors, dateETD: "ETD cannot be earlier than the Date of Loading." };
        }
        if (dateETA && dateETD && dateETA < dateETD) {
          errors = { ...errors, dateETA: "ETA cannot be earlier than the ETD." };
        }
        return errors;
      }}
      render={({ handleSubmit, form }) => (
        <form>
          <div>
            <Button disabled={defaultData == undefined} onClick={() => handleSubmit()} name="submit" type="button" variant="contained" color="primary">
              Allocate
            </Button>
          </div>
          <table>
            <tbody>
              <TableFieldCombo classes={classes} field="client" title="Client" data={clients} onChange={handleChangeClient} form={form} />
              <TableFieldTextField classes={classes} field="reference" title="Load Ref" id="full-width-text-field" />
              <TableFieldCombo classes={classes} field="shipmentweek_id" title="Shipment Week" data={weeks} form={form} />
              <TableFieldCombo classes={classes} field="dealtype" title="Deal Type" data={dealtypes} form={form} />
              <TableFieldCombo classes={classes} field="temperaturecode" title="Temperature" data={temperaturecodes} form={form} />
              <TableFieldTextField classes={classes} field="ponumber" title="Client Order No" id="full-width-text-field" />
              <TableFieldTextField classes={classes} field="loadout_exportNotificationReference" title="Export Notification" id="full-width-text-field" />
              <TableFieldCombo classes={classes} field="loadout_portLoading_id" title="POL" data={portsZA} form={form} />
              <TableFieldCombo classes={classes} field="loadout_portDischarge_id" title="POD" data={ports} form={form} />
              <TableFieldCombo classes={classes} field="loadout_portFinal_id" title="Final Dest" data={ports} form={form} />
              <TableFieldCombo classes={classes} field="loadout_shippingline_id" title="Shipping Line" data={shippingLines} form={form} />
              <TableFieldComboWithButton setConfirmEditID={setVesselMaintenanceOpen} classes={classes} title="Vessel" field="vessel_id" data={vessels} form={form} />
              <TableFieldTextField classes={classes} field="loadout_voyageNumber" title="Voyage Number" id="full-width-text-field" />
              <TableFieldDatePicker classes={classes} onChangeHook={changeDate} selectedField={dateLoading} title={"Date of Loading"} />
              <TableFieldDatePicker classes={classes} onChangeHook={changeDateETD} selectedField={dateETD} title={"ETD"} />
              {form.getState().submitFailed && form.getState().errors && form.getState().errors.dateETD && <p className={classes.error}>{form.getState().errors.dateETD}</p>}
              <TableFieldDatePicker classes={classes} onChangeHook={changeDateETA} selectedField={dateETA} title={"ETA"} />
              {form.getState().submitFailed && form.getState().errors && form.getState().errors.dateETA && <p className={classes.error}>{form.getState().errors.dateETA}</p>}
              <TableFieldCombo classes={classes} field="loadout_coldroom_id" title="Coldroom" data={coldrooms} form={form} />
              <TableFieldCombo classes={classes} field="loadout_agent_id" title="Forwarding Agent" data={agents} form={form} />
              <TableFieldTextField classes={classes} field="loadout_phytclean" title="Phytclean" id="full-width-text-field" />
              <TableFieldTextField classes={classes} field="loadout_specialinstruction" title="Special Instruction" id="full-width-text-field" />
              <TableFieldCheckBox classes={classes} title="Add Pricing" field="actionpricing" id="addpricingcheckbox" />
            </tbody>
          </table>
          {vesselMaintenanceOpen && (
            <Confirmation
              fullscreen={true}
              isOpen={vesselMaintenanceOpen}
              handleClose={handleEditDone}
              handleConfirm={() => {}}
              title={"Loadout - Editing Vessels"}
              body={undefined}
            >
              <Vessel handleOnClose={() => handleEditDone()} />
            </Confirmation>
          )}
        </form>
      )}
    />
  );
};

export default withStyles(styles)(AcceptAllocationDialogUnstyled);

export const TableFieldDatePicker: React.FC<{ classes: any; selectedField: any; onChangeHook: any; title: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, onChangeHook, selectedField, title } = props;
  return (
    <tr className={classes.tableRows}>
      <td className={classes.tableCellsDesc}>
        <label>{title}:</label>
      </td>
      <td className={classes.tableCellsValue}>
        <ReactDatePicker
          locale="en-GB"
          showWeekNumbers
          dateFormat="dd-MM-yyyy"
          selected={selectedField || new Date()}
          customInput={<CalenderCustomInput />}
          onChange={(value) => onChangeHook(value)}
          placeholderText="click here to select a date"
        />
      </td>
    </tr>
  );
};

const CalenderCustomInput = React.forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
      {props.value}
    </Button>
  );
});

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: any) => option.display,
});

export const TableFieldCombo: React.FC<{ field: string; title: string; classes: any; data: any; onChange?: any; form?: any } & WithStyles<typeof styles>> = (props) => {
  const { field, title, classes, data, onChange, form } = props;

  const handleOnChange = (selection) => {
    form.change(field, selection);
    if (onChange) {
      onChange(selection, form);
    }
  };

  return (
    <tr className={classes.tableRows}>
      <td className={classes.tableCellsDesc}>
        <label>{title}:</label>
      </td>
      <td className={classes.tableCellsValue}>
        <Field
          name={field}
          component={({ input, meta }) => (
            <Autocomplete
              options={data}
              filterOptions={filterOptions}
              classes={{ option: classes.dropdownOption }}
              getOptionLabel={(option: any) => option.display || ""}
              onChange={(_, selection) => handleOnChange(selection.value)}
              value={(data || []).find((item) => item.value == input.value)}
              renderOption={(option) => <span style={{ whiteSpace: "nowrap", width: "230px", overflowX: "clip", textOverflow: "ellipsis" }}>{option.display}</span>}
              renderInput={(params) => <MTextField {...params} fullWidth label={title} variant="standard" />}
            />
          )}
          formControlProps={{ className: classes.tableCellsValueSelect }}
        />
      </td>
    </tr>
  );
};

export const TableFieldTextField: React.FC<{ classes: any; field: string; title: string; id: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, id } = props;
  return (
    <tr>
      <td className={classes.tableCellsDesc}>
        <label>{title}:</label>
      </td>
      <td>
        <Field fullWidth formControlProps={{ className: classes.tableCellsValue }} name={field} id={id} component={TextField} type="text" />
      </td>
    </tr>
  );
};

export const TableFieldCheckBox: React.FC<{ classes: any; title: string; field: string; id: string } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, id } = props;
  return (
    <tr>
      <td className={classes.tableCellsDesc}>
        <label>{title}:</label>
      </td>
      <td>
        <Field name={field} id={id} style={{ marginLeft: "-13px", marginBottom: "8px" }} component={Checkbox} type="checkbox" />
      </td>
    </tr>
  );
};

const TableFieldComboWithButton: React.FunctionComponent<{ classes: any; field: string; data: any; setConfirmEditID: any; title: string; form: any }> = (props) => {
  const { classes, field, data, setConfirmEditID, title, form } = props;

  const handleOnChange = (selection) => {
    form.change(field, selection);
  };

  return (
    <tr>
      <td className={classes.tableCellsDesc}>
        <label>{title}:</label>
      </td>
      <td>
        <Grid container spacing={1}>
          <Grid container item={true} xs={9}>
            <Field
              name={field}
              component={({ input, meta }) => (
                <Autocomplete
                  fullWidth
                  options={data}
                  filterOptions={filterOptions}
                  getOptionLabel={(option: any) => option.display || ""}
                  onChange={(_, selection) => handleOnChange(selection.value)}
                  value={(data || []).find((item) => item.value == input.value)}
                  PopperComponent={(props) => <Popper {...props} style={{ width: "250px" }} placement="bottom-start" />}
                  renderOption={(option) => <span style={{ whiteSpace: "nowrap", width: "230px", overflowX: "clip", textOverflow: "ellipsis" }}>{option.display}</span>}
                  renderInput={(params) => <MTextField {...params} fullWidth label={title} variant="standard" />}
                />
              )}
              formControlProps={{ className: classes.tableCellsValueSelect }}
            />
          </Grid>
          <Grid container item={true} xs={3}>
            <Button size="small" color="primary" onClick={() => setConfirmEditID(true)}>
              <Settings />
            </Button>
          </Grid>
        </Grid>
      </td>
    </tr>
  );
};

export type AllocationForm = {
  actionloadout: boolean;
  actionpricing: boolean;
  client: string;
  dealtype: number;
  destination: string;
  loadout_agent_id: number;
  loadout_coldroom_id: number;
  loadout_consignee_id: number;
  loadout_dateLoading: Date;
  loadout_eta: Date;
  loadout_etd: Date;
  loadout_exportNotificationReference: string;
  loadout_phytclean: string;
  loadout_portDischarge_id: number;
  loadout_portFinal_id: number;
  loadout_portLoading_id: number;
  loadout_shippingline_id: number;
  loadout_specialinstruction: string;
  loadout_voyageNumber: string;
  ponumber: string;
  reference: string;
  shipmentweek_id: number;
  temperaturecode: string;
  username: string;
  vessel_id: number;
};
