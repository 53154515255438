import { client, apiHeaders, request } from "./_base";
import { config } from "../../config";

// todo api exports
export const target = client("target", item => ({
  ...item,
  // id: item.target_id
}));

export const targetsSorted = () =>
  request(`target/extend/targetssorted`, {
    headers: apiHeaders()
  })
  .then(result => result.json())
  // .then(result => { result.data });

export const targetsAll = () =>
  request(`target/extend/all`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const targetsDistinctRegions = () =>
  request(`target/extend/distinctregions`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const targetsDistinctMarkets = () =>
  request(`target/extend/distinctmarkets`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const targetsDistinctCountries = () =>
  request(`target/extend/distinctcountries`, {
    headers: apiHeaders()
  }).then(result => result.json());

export const targetsDistinctMarketsCountries = () =>
  request(`target/extend/distinctmarketscountries`, {
    headers: apiHeaders()
  }).then(result => result.json());
