import { apiHeaders, client, request } from "./_base";

const endpoint = "pack";

export const pack = client(endpoint, (item) => ({
  ...item,
}));

export const createPack = async (item) => {
  const content = JSON.stringify(item);
  const result = await request(`${endpoint}/ext/createPack`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCode = async (commodityCode: string) => {
  const result = await request(`${endpoint}/ext/packsByCommodityCode/${commodityCode}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCodeReadyForCombo = async (commodityCode: string) => {
  const packs = await getPacksByCommodityCode(commodityCode);
  return (packs || []).map((item) => ({ id: item.id, value: item.id, display: item.code }));
};
