import React from "react";
import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";

import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";
import { StatusTypes } from "../lib/types";
import { CheckboxEdit } from "../lib/components/gridcustomedit";

import { SummaryNumber, SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { CheckboxSelect } from "../lib/components/CheckboxSelect";

import { SaleDispatchJoinedType } from "../lib/api/sale";
import { DealType } from "../lib/api/dealtype";

const cellWidthSmallX = 80;
const cellWidthMedium = 150;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const StatusFormatter = ({ row }) => {
  const datarow: SaleDispatchJoinedType = { ...row };

  if (datarow.camend_status != 0) {
    return (
      <div>
        <Tooltip title={`${datarow.camend_status_text}`}>
          <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else if (datarow["status"] == StatusTypes.INVOICED) {
    return (
      <div>
        <Tooltip title={`${StatusTypes.INVOICED}`}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div>
        <Tooltip title={`${StatusTypes.NEW}`}>
          <IconBrightness style={{ color: "yellow", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
        </Tooltip>
      </div>
    );
  }
};

const TermsFormatter = ({ row, column }) => {
  const data = row[column.key];
  return <div>{isNullOrUndef(data) ? "CUSTOM" : data}</div>;
};

let currentSpace = true;
export const GridColumnsSales = (data, filters, arrangement = undefined, columnsWidth = undefined, billtypes, selectedRows, handleCheckboxSelect, isProFormaLayout = false) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const sectionSwitch = isProFormaLayout ? "invoiceProForma" : "invoice";

  let columns = [];
  if (isProFormaLayout) {
    columns.push({
      key: "check",
      name: "#C" + postValue,
      width: cellWidthSmallX,
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", float: "left" }}>
            <div style={{ marginTop: "-8px" }}>
              <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"sale_invoicenumber"} />
            </div>
          </div>
        );
      },
    });
  }
  columns.push(
    {
      key: "status",
      name: "#S" + postValue,
      width: cellWidthSmallX,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthMedium,
      // formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_forwardagentref",
      name: "Forw Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_forwardagentref"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "producer_codes",
      name: "Producer(s)" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_codes"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_reference",
      name: "Load Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_reference"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_consignee",
      name: "Client" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_consignee"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "consignee_code",
      name: "Consignee" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "terms_description",
      name: "Terms" + postValue,
      width: cellWidthMedium,
      formatter: TermsFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"terms_description"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_updated_eta",
      name: "Updated ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_updated_eta"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "portfinal_code",
      name: "Destination" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "portdistcharge_code",
      name: "Discharge" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_copydox",
      name: "Cpy Dox" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_copydox"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_orgnldox",
      name: "Org Dox" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_orgnldox"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_clientadvpd",
      name: "C Adv Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_clientadvpd"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_billtype",
      name: "Bill T" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_billtype"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_telex",
      name: "Telex" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_telex"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_clientfinpd",
      name: "Fin Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_clientfinpd"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "barcode_nocartons",
      name: "Total Cartons" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Total Pallets" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section={sectionSwitch} />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "shipment_week",
      name: "Sales Week" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "portloading_code",
      name: "Port of Loading" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_arrived",
      name: "Arrived" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_arrived"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_collected",
      name: "Collected" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_collected"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_freightpd",
      name: "Freight Pd" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_freightpd"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (props) => <CheckboxEdit {...props} disabled={true} />,
    },
    {
      key: "sale_accsalereceived",
      name: "A/S Rec" + postValue,
      width: cellWidthSmallX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_accsalereceived"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: (data) => (data.row.clients_sellingterms != 2 ? <CheckboxEdit {...data} disabled={true} /> : <div style={{ fontWeight: "bold", textAlign: "center" }}>N/A</div>),
    },
    {
      key: "dispatch_dispatchdocs_id",
      name: "Dispatch" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_dispatchdocs_id"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_ucr",
      name: "UCR" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ucr"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "users_username",
      name: "Trader" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"users_username"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_dealtype",
      name: "Deal Type" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_dealtype"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
      formatter: ({ row }) => <div>{row.dispatch_dealtype ? DealType[row.dispatch_dealtype] : "N/A"}</div>,
    },
    {
      key: "total",
      name: "Total Sale" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section={sectionSwitch} />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "sale_date",
      name: "Invoice Date" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_date"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "client_po",
      name: "Order No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_po"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_voyageNumber",
      name: "Shipping No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_sealnumber",
      name: "Seal No" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_sealnumber"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "target_market",
      name: "Target Market" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_market"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_ata",
      name: "ATA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ata"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "sale_exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthMedium,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_exchangerate"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
    {
      key: "dispatch_loaddate",
      name: "Load Date" + postValue,
      width: cellWidthMedium,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_loaddate"} data={data} section={sectionSwitch} />;
      },
      filterable: true,
    },
  );

  columns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
