import React, { useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import ClientEdit from "./clientseditform";
import ShippingEdit from "./clientseditshipping";
import FinanceEdit from "./clientseditfin";
import TabPanel from "../../lib/components/TabPanel";
import { DialogInformation } from "../../lib/components/dialoginformation";
import { clients } from "../../lib/api/clients";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "calc(100vw - 650px)",
      height: "calc(100vh - 200px)",
    },
    title: {
      color: "white",
      fontSize: "1.3em",
      fontWeight: "bold",
    },
  });

enum SelectedTab {
  Client = 0,
  Shipping = 1,
  Finance = 2,
  Consignee = 3,
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type ClientsEditFormProps = {
  reloadData: any;
  clientId: number;
  onClose(): any;
  setClientId(clientId: number): void;
} & WithStyles<typeof styles>;

const ClientsEditFormUnstyled: React.FunctionComponent<ClientsEditFormProps> = ({ classes, clientId, setClientId, onClose }) => {
  const [tabIndex, setTabIndex] = useState(SelectedTab.Client);
  const [isDirty, toggleDirty] = useState(false);
  const [blockUserTabChange, toggleBlockUserTabChange] = useState(false);
  const [clientData, setClientData] = useState({ name: "", code: "" });

  const handleChangeTab = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    if (isDirty) {
      toggleBlockUserTabChange(true);
    } else {
      setTabIndex(newTabIndex);
    }
  };

  const loadClientData = async () => {
    if (clientId != 0) {
      const data = await clients.single(clientId);
      setClientData(data);
    }
  };

  useEffect(() => {
    loadClientData();
  }, [clientId]);

  return (
    <div className={classes.root}>
      <div>
        <DialogInformation
          showinput={false}
          isOpen={blockUserTabChange}
          title={"Warning: Unsaved changes"}
          body={"Please save your changes before switching tabs to ensure your data is not lost."}
          handleOK={() => toggleBlockUserTabChange(false)}
          handleClose={() => toggleBlockUserTabChange(false)}
        />
        <AppBar position="static">
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="Invoice Header">
            <Tab label="Client" {...a11yProps(SelectedTab.Client)} />
            <Tab label="Shipping Details" {...a11yProps(SelectedTab.Shipping)} />
            <Tab label="Finance" {...a11yProps(SelectedTab.Finance)} />
            <p className={classes.title}>{clientData.code ? `${clientData.code} - ${clientData.name}` : ""}</p>
          </Tabs>
        </AppBar>
        <div>
          <TabPanel value={tabIndex} index={SelectedTab.Client}>
            <ClientEdit setClientId={setClientId} clientId={clientId} handleClose={onClose} isDirty={isDirty} toggleDirty={toggleDirty} />
          </TabPanel>
          <TabPanel value={tabIndex} index={SelectedTab.Shipping}>
            <ShippingEdit clientId={clientId} handleClose={onClose} toggleDirty={toggleDirty} />
          </TabPanel>
          <TabPanel value={tabIndex} index={SelectedTab.Finance}>
            <FinanceEdit clientId={clientId} handleClose={onClose} toggleDirty={toggleDirty} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ClientsEditFormUnstyled);
