import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../lib/components/EnquiryLink";
import { FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../lib/components/SummaryFormatter";

const cellWidthSmallX = 80;
const cellWidthSmall = 115;
const cellWidthMedium = 140;
const cellWidthMediumX = 190;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpaceSelected = true;

export const GridColumnsEdiDetail = (data, filters, arrangement, columnsWidth, section) => {
  let postValue = "";

  if (data) {
    if (currentSpaceSelected) {
      postValue = " ";
    }
    currentSpaceSelected = !currentSpaceSelected;
  }

  const columns = [
    {
      key: "Barcode",
      name: "Barcode" + postValue,
      width: cellWidthMediumX,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Barcode"} data={data} section={section} />;
      },
      // formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      summaryFormatter: SummaryTotal,
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section={section} />;
      },
      formatter: FormatterNumber,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "PalletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PalletSize"} data={data} section={section} />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "CommodityCode",
      name: "Commodity Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CommodityCode"} data={data} section={section} />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section={section} />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section={section} />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section={section} />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section={section} />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section={section} />;
      },
    },
    {
      key: "InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section={section} />;
      },
    },
    {
      key: "PickingRef",
      name: "Picking Ref" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PickingRef"} data={data} section={section} />;
      },
    },
    {
      key: "Waybill",
      name: "Waybill" + postValue,
      width: cellWidthMedium,
      filterable: true,
      // formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Waybill"} data={data} section={section} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  const columnsArranged = [];
  if (arrangement && arrangement.length > 0) {
    arrangement.map((item) => {
      const indexOfItem = columns.indexOf(columns.find((col) => col.name.trim() == item.toString().trim()));
      if (indexOfItem != -1) {
        if (columns[indexOfItem].name.trim() != "") {
          columnsArranged.push(columns[indexOfItem]);
        }
      }
    });
  } else {
    columns.map((colItem) => {
      columnsArranged.push(colItem);
    });
  }

  if (columnsWidth && columnsWidth.length > 0) {
    for (let index = 0; index < columnsArranged.length; index++) {
      const element = columnsArranged[index];
      const colItem = columnsWidth.find((colwitem) => colwitem.name.trim() == element.name.trim());
      if (colItem && element.name.trim().length != 0) {
        element.width = colItem.width;
      } else if (element.key == "status") {
        element.width = cellWidthSmallX;
      }
    }
  }

  return columnsArranged;
};
