import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import format from "date-fns/format";
import { FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthSmallX = 90;
const cellWidthSmall = 115;
const cellWidthMedium = 150;
const cellWidthLarge = 220;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
  filterable: true,
};

let currentSpace = true;

const StatusTypes = {
  New: { style: {}, text: "New" },
  Imported: { style: { color: "orange" }, text: "Imported" },
  Processed: { style: { color: "green" }, text: "Processed" },
  Rejected: { style: { color: "red" }, text: "Rejected" },
};

const DateFormatter = (date: string, time = false) => {
  let newDate = new Date(date.replace("Z", ""));
  return <div>{time ? format(newDate, "dd MMM yyyy HH:mm") : format(newDate, "dd MMM yyyy")}</div>;
};

const StatusCell = (status: string) => {
  const statusVal = StatusTypes[status];
  return <div style={statusVal.style}>{statusVal.text}</div>;
};

export const FileImportGrid = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "timestampFormatted",
      name: "Timestamp" + postValue,
      width: cellWidthMedium,
      formatter: ({ row }) => DateFormatter(row.timestamp, true),
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"timestampFormatted"} data={data} section={"importfile"} orderDescending />
        );
      },
    },
    {
      key: "fileType",
      name: "File Type" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fileType"} data={data} section={"importfile"} />;
      },
    },
    {
      key: "filename",
      name: "Filename" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"filename"} data={data} section={"importfile"} />;
      },
    },
    {
      key: "locationcode",
      name: "Location Code" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"locationcode"} data={data} section={"importfile"} enableAutoFilter={true} />
        );
      },
    },
    {
      key: "waybillDocument",
      name: "Waybill Document" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"waybillDocument"}
            data={data}
            section={"importfile"}
            enableAutoFilter={true}
          />
        );
      },
    },
    {
      key: "totalCartons",
      name: "Total Cartons" + postValue,
      width: cellWidthSmallX,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalCartons"} data={data} section={"importfile"} enableAutoFilter={true} />
        );
      },
    },
    {
      key: "totalPallets",
      name: "Total Pallets" + postValue,
      width: cellWidthSmallX,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalPallets"} data={data} section={"importfile"} enableAutoFilter={true} />
        );
      },
    },
    {
      key: "notes",
      name: "Notes" + postValue,
      width: cellWidthMedium,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"notes"} data={data} section={"importfile"} />;
      },
    },
    {
      key: "status",
      name: "Status" + postValue,
      width: cellWidthMedium,
      formatter: ({ row }) => StatusCell(row.status),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status"} data={data} section={"importfile"} enableAutoFilter={true} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
