import { client } from "./_base";

// todo api exports
export const commodities = client("commodity", (item) => ({
  ...item,
  // id: item.commodity_id
}));

export const commoditiesAllSortedMappedforCombo = async () => {
  return commodities.all().then((data) => {
    const result = data
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map((c) => {
        return { value: c.code, display: `${c.name} (${c.code})`, id: c.id };
      });

    return result;
  });
};
