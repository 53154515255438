import React, { useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router";

import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import orange from "@material-ui/core/colors/orange";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactDataGrid, { Row } from "react-data-grid";
import numeral from "numeral";

import { StockType } from "../lib/api/stock";
import { DialogInformation } from "../lib/components/dialoginformation";
import { generateSortFn } from "../lib/helpers/generateSortFN";
import { generateGridFilteredRows } from "../lib/helpers/generateGridFilteredRows";
import { DownloadCSV } from "../lib/helpers/Files";

import { GridColumns } from "./stockallocationtablesetup";
import { selectedGridColumns } from "./selectedStockallocationtablesetup";
import StockAllocationFilterChips from "../lib/components/filterchips";
import { clearFiltersForColumns, removeFromSticky } from "../lib/helpers/stickyfilters";
import { saleCreateProForma } from "../lib/api/sale";
import { createDispatchFromLoadout, printLoadoutReport } from "../lib/api/loadout";

import AcceptAllocationDialog from "./stockallocationAcceptDialog";

import { summaryRows, handleSortChange, handleFilterChange } from "../lib/helpers/grid";
import classNames from "classnames";

import { SnackContext } from "../lib/context/SnackContext";

import LinearProgress from "@material-ui/core/LinearProgress";
import { OrangeButton } from "../lib/components/ColorButtons";

import { GenerateErrorMessage } from "../lib/helpers/string_methods";

import format from "date-fns/format";
import Grid from "../lib/components/grid";
import { User } from "../lib/api/users";
import { UserContext } from "../lib/context/UserContext";
import { ClearFilterIcon } from "../icons/icons";
import Tooltip from "@material-ui/core/Tooltip";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: orange[900],
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    control: {
      paddingLeft: "10px",
      float: "right",
      position: "absolute",
      right: "35px",
      top: "76px",
      borderRadius: "10px",
    },
    controlFloatLeft: {
      paddingTop: theme.spacing(1) * 3,
      float: "left",
      height: "10px",
      position: "absolute",
      left: "420px",
      top: "65px",
    },
    controlButton: {
      display: "absolute",
    },
    button: {
      marginRight: "10px",
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    exportButton: {
      marginRight: "10px",
      color: "white",
      backgroundColor: orange[700],
      "&:hover": {
        backgroundColor: orange[900],
      },
    },
    mainTabbed: {
      height: "calc(100% - 50px)",
      width: "100%",
      paddingTop: "10px",
      marginBottom: "100px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    greenRow: {
      color: "green",
    },
    contextMenu: {
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      borderRadius: "0.25rem",
      color: "#373a3c",
      fontSize: "16px",
      margin: "2px 0 0",
      minWidth: "160px",
      outline: "none",
      opacity: 0,
      padding: "5px 0",
      pointerEvents: "none",
      textAlign: "left",
      transition: "opacity 250ms ease !important",
    },
    contextMenuItem: {
      background: "0 0",
      border: 0,
      color: "#373a3c",
      cursor: "pointer",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: "3px 20px",
      textAlign: "inherit",
      whiteSpace: "nowrap",
    },
    orangeRow: {
      color: "orange",
    },
    normalRow: {
      color: "black",
    },
  });

type StockAllocationTableProps = {
  data: StockType[];
  onReload: any;
  onAllocate?: any;
  onAccept: any;
  onUnAllocateSelect: (data) => void;
  onPricingBatches: any;
  referenceBatchesCount: Number;
  showTotals?: boolean;
  NoFollowSelectedTab?: boolean;
  handleUpdateStock(): Promise<any[]>;
  allocating: boolean;
};

const StockAllocationTable: React.FC<StockAllocationTableProps> = ({
  data,
  onReload,
  onAllocate,
  onAccept,
  onUnAllocateSelect,
  onPricingBatches,
  referenceBatchesCount,
  showTotals,
  NoFollowSelectedTab,
  handleUpdateStock,
  allocating,
}) => {
  const { user } = useContext(UserContext);

  return (
    <StockAllocationTableChild
      user={user}
      data={data}
      onReload={onReload}
      onAllocate={onAllocate}
      onAccept={onAccept}
      onUnAllocateSelect={onUnAllocateSelect}
      onPricingBatches={onPricingBatches}
      referenceBatchesCount={referenceBatchesCount}
      showTotals={showTotals}
      NoFollowSelectedTab={NoFollowSelectedTab}
      handleUpdateStock={handleUpdateStock}
      allocating={allocating}
    />
  );
};

export default StockAllocationTable;

type StockAllocationTableChildProps = {
  data: StockType[];
  onReload: any;
  onAllocate?: any;
  onAccept: any;
  onUnAllocateSelect: (data) => void;
  onPricingBatches: any;
  referenceBatchesCount: Number;
  showTotals?: boolean;
  NoFollowSelectedTab?: boolean;
  handleUpdateStock(): Promise<any[]>;
  allocating: boolean;
  user: User;
} & WithStyles<typeof styles>;

const availableItems_tabIndex = 0;
const selectedItems_tabIndex = 1;

let filteredRows = [];

class StockAllocationTableChildUnstyled extends React.Component<StockAllocationTableChildProps, {}> {
  state = {
    data: [],
    selectedRowsFilters: {},
    sorting: { age: "DESC", ageAtLocation: "DESC" },
    sortingSelected: {},
    hideallocated: false,
    hidepreallocated: false,
    loading: false,
    selectedIndexes: [],
    onReload: undefined,
    onAllocate: undefined,
    onAccept: undefined,
    onUnAllocateSelect: undefined,
    selectedRows: [],
    dialoginformation: undefined,
    dialoginformationSelectX: undefined,
    gridRef: undefined,
    selectedRowGridRef: undefined,
    onPricingBatches: undefined,
    referenceBatchesCount: 0,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    clearedPhrase: false,
    columnsArranged: [],
    columnsWidth: [],
    showTotals: true,
    hideAndShowSecondaryBtnTxt: "HIDE",
    hideSorting: false,
    anchorEl: undefined,
    tabIndex: 0,
    NoFollowSelectedTab: undefined,
    requestOpenProFormaInvoice: undefined,
    currentSortColumn: "",
    currentSortDirection: "",
    currentSortColumnSelected: "",
    currentSortDirectionSelected: "",
    createdispatchloading: false,
    selectedEditRow: undefined,
    proformaExists: false,
    selectedLoading: false,
    sortItem: "selectedStockAlloc",
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
    this.state.onAllocate = props.onAllocate;
    this.state.onAccept = props.onAccept;
    this.state.onUnAllocateSelect = props.onUnAllocateSelect;
    this.state.gridRef = React.createRef();
    this.state.selectedRowGridRef = React.createRef();
    this.state.onPricingBatches = props.onPricingBatches;
    this.state.referenceBatchesCount = props.referenceBatchesCount;
    this.state.showTotals = props.showTotals === undefined && true;
    this.state.NoFollowSelectedTab = props.NoFollowSelectedTab;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  onScreenResize() {
    this.setGridHeights();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onScreenResize.bind(this), false);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onScreenResize.bind(this), false);
    this.setGridHeights();

    try {
      const allocated = JSON.parse(localStorage.getItem("hideAllocated") || "0");
      this.setState({ hideallocated: !!allocated, hidepreallocated: !!allocated });
    } catch (error) {
      console.log("error", error);
    }
  }

  handleStickySituation() {
    let tablesorting = localStorage.getItem(this.state.sortItem);
    if (tablesorting) {
      tablesorting = JSON.parse(tablesorting);
      const keys = Object.keys(tablesorting);
      this.setState({ sorting: tablesorting, currentSortColumn: keys[0], currentSortDirection: tablesorting[keys[0]] }, () => {});
    }
  }

  getChipsHeightSelected = () => {
    return (
      (Object.keys(this.state.sortingSelected).length > 0 ? 42 : 0) +
      (Object.keys(this.state.selectedRowsFilters).length > 0 ? 42 * Object.keys(this.state.selectedRowsFilters).length : 0)
    );
  };

  setGridHeights = () => {
    const mainClientheight = document.getElementById("main_tabbed_div");
    if (mainClientheight && mainClientheight.clientHeight) {
      this.setState({ minGridHeight: mainClientheight.clientHeight - 120, minGridHeightSecond: mainClientheight.clientHeight - 120 });
    }
  };

  getDetail(list, largeNumber = false) {
    const text = {
      __html:
        `<span style="${largeNumber ? "font-size: 21px" : ""}">${list.length}</span> Record(s) <span style="font-size: 28px; line-height:0; ">|</span> ` +
        `<span style="${largeNumber ? "font-size: 21px" : ""}">${numeral(this.getCount(list, "palletSize")).format(
          "0",
        )}</span> Pallet(s) <span style="font-size: 28px; line-height:0; ">|</span> ` +
        `<span style="${largeNumber ? "font-size: 21px" : ""}">${numeral(this.getCount(list, "noCartons")).format("0")}</span> Carton(s)`,
    };
    return <span dangerouslySetInnerHTML={text} />;
  }

  getCount = (list, propName) => {
    if (list.length > 0) {
      const value = list
        .map((item) => {
          return item[propName];
        })
        .reduce((acc, curr) => {
          return Number(acc) + Number(curr);
        });
      return Number.isInteger(value) ? value.toFixed(2) : value;
    } else {
      return 0;
    }
  };

  handleSelectedRowsFilterChange = (filter) => {
    const newFilters = handleFilterChange(filter, this.state.selectedRowsFilters);
    this.setState({ selectedRowsFilters: newFilters }, () => {
      this.setGridHeights();
    });
  };

  getRowsSelected = (rows, filters) => {
    const result = [...generateGridFilteredRows(rows, filters).filter((item) => item.barcode != "Totals")];

    const fieldSorterData = Object.keys(this.state.sortingSelected).map((item) => {
      return { name: item, reverse: this.state.sortingSelected[item] == "ASC" ? false : true };
    });

    result.sort(generateSortFn(fieldSorterData));
    return result;
  };

  filteredStocks = [{}];

  sumInt = (collection, propertyName) => {
    return collection.reduce((prev, current) => {
      if (!current) return 0;
      return prev + current[propertyName];
    }, 0);
  };

  sumFloat = (collection, propertyName) => {
    return collection.reduce((prev, current) => {
      if (!current) return 0;
      return Number.parseFloat(prev) + Number.parseFloat(current[propertyName]);
    }, 0.0);
  };

  getAvailableStockTotals = (props) => {
    let totals = JSON.parse(JSON.stringify(props));
    totals.row = this.filteredStocks[this.filteredStocks.length - 1];

    return totals;
  };

  getSelectedStockTotals = (props) => {
    let totals = JSON.parse(JSON.stringify(props));
    totals.row = this.state.selectedRows[this.state.selectedRows.length - 1];

    return totals;
  };

  appendTotals = (rows) => {
    if (rows && rows.length > 0) {
      let totalRow = JSON.parse(JSON.stringify(rows[0]));
      Object.keys(totalRow).map((key) => {
        totalRow["barcode"] = "Totals";
        if (["sellp", "palletsize", "weight", "grossweight", "allocated", "preallocated", "nocartons", "purchaseprice"].indexOf(key.toLowerCase()) == -1) {
          totalRow[key] = undefined;
          return;
        } else if (["sellp", "weight", "grossweight", "palletsize", "purchaseprice"].includes(key.toLowerCase())) {
          totalRow[key] = this.sumFloat(rows, key).toFixed(2);
        } else {
          totalRow[key] = this.sumInt(rows, key);
        }
      });
      rows = rows.filter((f) => f.barcode.toString().toLowerCase() != "totals"); //* rows currently contains a totalled row.
      rows.push(totalRow);
    }
    return rows;
  };

  getTotalledSelectedRows = (rows, addRows: boolean = true) => {
    const result = this.state.selectedRows.filter((f) => f.barcode && f.barcode.toString().toLowerCase() != "totals");
    // const filteredRows = this.getRows(this.state.data, this.state.filters);
    const matched = [];

    /* THIS NEEDS TO BE CLEANED UP */

    if (addRows && Array.isArray(rows)) {
      rows.map((row) => {
        if (row.barcode.toString().toLowerCase() != "totals") {
          matched.push(row);
          filteredRows.map((item) => {
            if (item.barcode && item.barcode == row.barcode) {
              matched.push(item);
            }
          });
        }
      });
      result.push(...matched);
    } else if (addRows) {
      matched.push(rows);
      result.push(rows);
      if (result.length > 0) {
        result.map((r) => {
          this.state.data.map((item) => {
            if (item.barcode && item.barcode == r.barcode) {
              matched.push(item);
            }
          });
        });
      } else {
        this.state.data.map((item) => {
          if (item.barcode && item.barcode == rows.barcode) {
            matched.push(item);
          }
        });
      }
      result.push(...matched);
    } else {
      const index = result.indexOf(rows);
      if (index !== -1) {
        this.state.selectedRows
          .filter((f) => f.barcode && f.barcode.toString().toLowerCase() != "totals")
          .map((item) => {
            if (item.barcode && item.barcode == rows.barcode) {
              result.splice(result.indexOf(item), 1);
            }
          });
      }
    }

    return this.appendTotals(Array.from(new Set(result)));
  };

  handleAvailableStockDoubleClick = (row) => {
    const result = this.state.selectedRows.find((item) => item.barcode == row.barcode) ? this.getTotalledSelectedRows(row, false) : this.getTotalledSelectedRows(row);
    this.setState({ selectedRows: result }, () => {});
  };

  handleSelectedStockDoubleClick = (row: any) => {
    const array = this.getTotalledSelectedRows(row, false);
    this.setState({ selectedRows: array });
  };

  onSelectAllFiltered = () => {
    // const rowsAvailable = this.getRows(this.state.data, this.state.filters);

    const rowsAvailableFiltered = filteredRows.filter((item) => {
      // if (isNullOrUndef(item.loadout_id)) {
      return item;
      // }
    });

    const result = this.getTotalledSelectedRows(rowsAvailableFiltered);
    this.setState({ selectedRows: result });
  };

  onSelectXFiltered = () => {
    this.setState({ dialoginformationSelectX: { title: "Number of X", body: "How many barcodes do you want to select?" } });
  };

  handleClearSelected = () => {
    this.setState({ selectedRows: [] });
  };

  onSelectXFilteredClose = () => {
    this.setState({ dialoginformationSelectX: undefined });
  };

  onSelectXFilteredOK = (value: number) => {
    const selectedRows = filteredRows.reduce((arr, item) => {
      if (arr.length == value) return arr;
      if (!arr.find((row) => row.barcode == item.barcode)) {
        arr.push(item);
      }
      return arr;
    }, []);
    const result = this.getTotalledSelectedRows(selectedRows);
    this.setState({ dialoginformationSelectX: undefined, selectedRows: result }, () => {});
  };

  selectedClear = () => {
    this.setState({ selectedRows: [] });
  };

  allowAccept = () => {
    const filteredAllocated = this.state.selectedRows
      .filter((f) => f.barcode.toString().toLowerCase() != "totals")
      .filter((item) => {
        if (item.allocated || item.preAllocated) {
          return item;
        }
      });
    const returnValue = filteredAllocated.length > 0 ? false : true;
    return returnValue;
  };

  handleSortSelected = (sortColumn, sortDirection) => {
    const sortingState = handleSortChange(this.state.sortingSelected, sortColumn, sortDirection);
    this.setState(
      {
        sortingSelected: sortingState,
        currentSortColumnSelected: sortColumn,
        currentSortDirectionSelected: sortDirection,
      },
      () => {
        this.setGridHeights();
      },
    );
  };

  handleRemoveSortSelectedEntry = (field) => {
    // used to remove the Sorting column indicator.
    const collection = document.getElementsByClassName("pull-right");
    let i = 0;
    for (i = 0; i < collection.length; i++) {
      if (collection[i].innerHTML.length < 1) {
        continue;
      }
      (collection[i] as HTMLElement).innerHTML = "";
      break;
    }
    const sortingState = this.state.sortingSelected;
    delete sortingState[field];
    this.setState({ sortingSelected: sortingState }, () => {
      this.setGridHeights();
    });
  };

  handleRemoveFilterSelectedEntry = (key, value) => {
    removeFromSticky(key, value, "selectedStockAlloc"); // stockAlloc : section

    this.setState({ selectedRowsFilters: {} }, () => {
      this.setGridHeights();
    });
  };

  selectedRowsClearFilters = () => {
    const keys = Object.keys(this.state.selectedRowsFilters);
    clearFiltersForColumns(keys, "selectedStockAlloc");

    this.setState({ selectedRowsFilters: {} });
  };

  handleFullExport = () => {
    const headerColumns = GridColumns(filteredRows, [], this.state.columnsArranged, this.state.columnsWidth);
    const filename = `${format(new Date(), "yyMMdd")}_ALL_STOCK_DATA.xlsx`;
    DownloadCSV(filteredRows, filename, headerColumns);
  };

  handlePrintLoadout = async () => {
    try {
      if (filteredRows.length === 0) {
        this.context.updateSnack({ show: true, color: "red", message: "No rows to print" });
        return;
      }

      const loadouts: number[] = filteredRows.reduce((arr, item) => {
        if (item.loadout_id && !arr.find((aItem: StockType) => aItem == item.loadout_id)) {
          arr.push(item.loadout_id);
        }
        return arr;
      }, []);

      if (loadouts.length === 0) {
        this.context.updateSnack({ show: true, color: "red", message: "No loadout attached" });
        return;
      }

      await printLoadoutReport(loadouts, filteredRows);
    } catch (error) {
      console.log("error", error);
      const err = GenerateErrorMessage(error, "Error printing Loadout Report");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleUnAllocate = () => {
    this.state.onUnAllocateSelect(this.state.selectedRows.filter((f) => f.barcode.toString().toLowerCase() != "totals"));
  };

  handleDialogInfoClose = () => {
    this.setState({ dialoginformation: undefined });
  };

  filterTerms = (obj) => {
    let val = 0;

    Object.keys(obj).map((key) => {
      val += obj[key].filterTerm.length;
    });

    return val;
  };

  handleHideSorting = () => {
    this.setState({ hideSorting: !this.state.hideSorting }, () => {
      this.setGridHeights();
    });
  };

  phrase = "";
  setSearchPhrase = (phrase) => {
    this.phrase = phrase;
  };
  clearPhrase = () => {
    this.setSearchPhrase("");
    this.setState({ clearedPhrase: !this.state.clearedPhrase });
  };

  handleExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleExportMenuClose = () => {
    this.setState({ anchorEl: undefined });
  };

  handleExportMenuAction = (menuidx, data, filters) => {
    this.setState({ anchorEl: undefined }, () => {
      this.menuItems[menuidx].action(data, filters);
    });
  };

  menuItems = {
    1: {
      title: "All Stock Data",
      action: () => this.handleFullExport(),
    },
    2: {
      title: "Loadout",
      action: () => this.handlePrintLoadout(),
    },
  };

  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newTabIndexValue: number) => {
    this.setState({ tabIndex: newTabIndexValue });
    if (newTabIndexValue == selectedItems_tabIndex) {
      this.state.onAccept(this.selectedRows());
    }
  };

  selectedRows = () => {
    return this.getRowsSelected(
      this.state.selectedRows.filter((f) => f != undefined && f.barcode.toString() != "Totals"),
      this.state.selectedRowsFilters,
    ).filter((f) => f != undefined);
  };

  allocationRequested = (data) => {
    this.state.onAllocate(data, this.selectedRows());
  };

  handleGenerateProForma = async (arrLoudouts: { loadout_id: number; reference: string }[]) => {
    this.setState({ selectedLoading: true });

    try {
      const proformaExists = this.state.selectedRows.find((row) => row.sale_id);

      if (proformaExists) {
        // pro-forma exists for a record(s).
        this.setState({ proformaExists: true, selectedLoading: false });
      } else {
        for (let i = 0; i < arrLoudouts.length; i++) {
          const lo = arrLoudouts[i];
          if (lo && lo.loadout_id && lo.loadout_id != 0) {
            await saleCreateProForma(lo.loadout_id);
          }
        }

        const newStock = await this.props.handleUpdateStock();

        const newSelectedRows = this.state.selectedRows.reduce((arr, curr) => {
          const exists = newStock.find((nStock) => nStock.barcode == curr.barcode);
          if (exists) {
            arr.push(exists);
          }
          return arr;
        }, []);

        this.setState({
          selectedRows: newSelectedRows,
          selectedLoading: false,
          dialoginformation: {
            title: "Pro-Forma Creation",
            body: "Pro-Forma has been generated.",
          },
        });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error generating pro-forma");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ selectedLoading: false });
  };

  handleGenerateDispatch = async (arrLoudouts: { loadout_id: number; reference: string }[]) => {
    try {
      if (this.state.selectedRows.find((item) => item.dispatchid)) {
        throw "Dispatch already exists";
      } else {
        this.setState({ createdispatchloading: true });
        const promiseArr = [];
        for (let index = 0; index < arrLoudouts.length; index++) {
          const element = arrLoudouts[index];
          await createDispatchFromLoadout(element.loadout_id).catch((error) => {
            const err = GenerateErrorMessage(error, "failed to retrieve data");
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
        }
        this.setState({ createdispatchloading: false });
        this.context.updateSnack({ show: true, color: "green", message: "Dispatch has been created" });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Dispatch already exists");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  rowRenderer = (props, classes) => {
    const { row } = props;

    const colorStyle = row.allocated || row.loadout_id ? classes.greenRow : row.preAllocated && classes.greenRow;
    const fontweight = this.state.selectedRows.find((item) => item.barcode == row.barcode) && classes.boldRow;

    const handleClick = () => {
      if (this.props.user.stockalloc_permission) {
        this.handleAvailableStockDoubleClick(row);
      }
    };

    return <Row {...props} onDoubleClickCapture={handleClick} className={classNames(colorStyle, fontweight)} />;
  };

  selectedRowRenderer = (props) => {
    const { row } = props;
    const { classes } = this.props;
    const rowExistsStyle = row.sale_id ? classes.orangeRow : classes.normalRow;

    return <Row {...props} className={rowExistsStyle} onDoubleClickCapture={() => this.handleAvailableStockDoubleClick(row)} />;
  };

  handleSelectEditRow = (row) => {
    this.setState({ selectedEditRow: row });
  };

  handleCloseProformaExists = () => {
    this.setState({ proformaExists: false });
  };

  handleRedirectToProForma = () => {
    window.location.href = "/invoiceproforma";
  };

  filteredRows = [];
  handleFilteredRows = (rows: any[]) => {
    filteredRows = rows;
  };

  getRows = (data) => {
    if (!this.state.hideallocated && !this.state.hidepreallocated) {
      return data;
    }

    const result = data.filter((item) => {
      if (this.state.hidepreallocated && !item.preAllocated) {
        // show allocated switch on
        if (item.locationCode != "CONFIRM") {
          return item;
        }
      } else if (this.state.hideallocated && !item.allocated && !this.state.hidepreallocated) {
        return item;
      }
    });
    return result;
  };

  getLoadout = (rows: any[]) => {
    const exists = rows.find((r) => r.loadout_id);
    if (exists) {
      return exists;
    }
    return rows[0];
  };

  render() {
    const { classes } = this.props;
    const selectedRowscolumnsToRender = selectedGridColumns(
      this.state.selectedRows.filter((f) => f.barcode.toString() != "Totals"),
      this.state.selectedRowsFilters,
      this.state.columnsArranged,
      this.state.columnsWidth,
    );
    const filteredRowsSelected = this.getRowsSelected(this.state.selectedRows, this.state.selectedRowsFilters);

    const totalColumns = ["sellp", "noCartons", "palletSize", "grossWeight", "weight"];

    const arrLoadouts: { loadout_id: number; reference: string }[] = [];
    filteredRowsSelected.map((item: StockType) => {
      if (item) {
        if (item && item.barcode.toLowerCase() == "totals") return;
        if (!arrLoadouts || !arrLoadouts.find((ld) => ld.reference === item.reference)) {
          arrLoadouts.push({ loadout_id: item.loadout_id ? item.loadout_id : 0, reference: item.reference });
        }
      }
    });

    if (this.state.requestOpenProFormaInvoice) return <Redirect to={`/invoiceproforma/${this.state.requestOpenProFormaInvoice}`} />;

    return (
      <div className={classes.root}>
        {this.props.allocating && (
          <div style={{ marginBottom: "10px" }}>
            <LinearProgress color="secondary" />
          </div>
        )}
        {this.state.proformaExists && (
          <DialogInformation
            isOpen={true}
            showinput={false}
            handleClose={this.handleCloseProformaExists}
            handleOK={this.handleCloseProformaExists}
            title={"Pro-Forma Generate Failed"}
            body={`One or more records selected is assigned to a Pro-Forma. Unassign the record(s) from the Pro-Forma screen to continue.`}
          />
        )}
        {this.state.dialoginformationSelectX && (
          <DialogInformation
            isOpen={this.state.dialoginformationSelectX}
            handleClose={this.onSelectXFilteredClose}
            handleOK={this.onSelectXFilteredOK}
            title={`${this.state.dialoginformationSelectX.title}`}
            body={`${this.state.dialoginformationSelectX.body}`}
          />
        )}
        {this.state.dialoginformation && (
          <DialogInformation
            isOpen={this.state.dialoginformation}
            handleClose={this.handleDialogInfoClose}
            handleOK={this.handleDialogInfoClose}
            title={`${this.state.dialoginformation.title}`}
            body={`${this.state.dialoginformation.body}`}
            showinput={false}
            buttonCaption="close"
          />
        )}

        <AppBar position="static">
          <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="Stock Allocation">
            <Tab label="Available Stock" {...this.a11yProps(availableItems_tabIndex)} />
            {this.props.user.stockalloc_permission && (
              <Tab
                label={
                  <Badge badgeContent={filteredRowsSelected.length} style={{ position: "absolute" }} color="secondary">
                    {`${!this.state.NoFollowSelectedTab ? "Selected Stock" : this.selectedRows().length > 0 ? "Accept" : "Cancel"}`}
                  </Badge>
                }
                {...this.a11yProps(selectedItems_tabIndex)}
              />
            )}
          </Tabs>
        </AppBar>
        <div id="main_tabbed_div" className={classes.mainTabbed}>
          <div hidden={this.state.tabIndex == 0 ? false : true}>
            {this.state.onReload && (
              <span className={classes.controlFloatLeft}>
                <span style={{ color: "orange", fontWeight: "bold" }}>{this.getDetail(filteredRows.filter((x) => x["barcode"] !== "Totals"))}</span>
              </span>
            )}

            {this.state.onReload && (
              <div className={classes.control}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hideallocated}
                      onClick={(e: any) => {
                        localStorage.setItem("hideAllocated", e.target.checked ? "1" : "0");
                        this.setState({ hideallocated: e.target.checked, hidepreallocated: e.target.checked });
                      }}
                    />
                  }
                  label="Hide Allocated"
                  style={{ color: "white" }}
                />
                <Button variant="contained" color="primary" onClick={this.state.onPricingBatches} className={classes.button}>
                  <Badge badgeContent={this.state.referenceBatchesCount} color="secondary">
                    update pricing
                  </Badge>
                </Button>
                <Button variant="contained" className={classes.exportButton} onClick={this.handleExportMenu} disabled={filteredRows.length <= 0}>
                  Export
                </Button>
                {Object.keys(this.menuItems).length != 0 && (
                  <StyledMenu id="customized-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleExportMenuClose}>
                    {Object.keys(this.menuItems).map((menuItemKey) => (
                      <StyledMenuItem key={menuItemKey} onClick={(e) => this.handleExportMenuAction(menuItemKey, filteredRows, [])}>
                        <ListItemText primary={this.menuItems[menuItemKey].title} />
                      </StyledMenuItem>
                    ))}
                  </StyledMenu>
                )}
                {this.props.user.stockalloc_permission && (
                  <>
                    <Button variant="contained" color="primary" onClick={this.handleClearSelected} className={classes.button}>
                      clear selected
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.onSelectAllFiltered} className={classes.button}>
                      select all
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.onSelectXFiltered} className={classes.button}>
                      select x
                    </Button>
                  </>
                )}
              </div>
            )}
            <Grid
              fill
              showFilterChips
              loading={false}
              clearFilters={"stockalloc"}
              handleRefresh={this.state.onReload}
              data={this.getRows(this.state.data)}
              forceHeight={this.state.minGridHeight}
              handleFilteredRows={this.handleFilteredRows}
              rowRenderer={(props) => this.rowRenderer(props, classes)}
              GridColumns={(data, filters, columnArrangement, columnsWidth) => GridColumns(data, filters, columnArrangement, columnsWidth)}
              totalRowColumns={["noCartons", "palletSize", "grossWeight", "weight", "sellp"]}
            />
          </div>
          <div hidden={this.state.tabIndex == 1 ? false : true} style={{ display: this.state.tabIndex == 0 ? "none" : "grid", gridTemplateColumns: "1fr 390px", gap: "1rem" }}>
            <div>
              {this.state.selectedLoading ? <LinearProgress color="secondary" /> : <div style={{ height: "4px" }}></div>}
              {this.state.onReload && (
                <span className={classes.controlFloatLeft}>
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {this.getDetail(
                      this.getRowsSelected(
                        this.state.selectedRows.filter((item) => item !== undefined),
                        this.state.selectedRowsFilters,
                      )
                        .filter((f) => f !== undefined)
                        .filter((f) => f.barcode.toString() != "Totals"),
                      false,
                    )}
                  </span>
                </span>
              )}
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", left: 0, top: 0, height: "60px", backgroundColor: "#fff", width: "calc(100vw - 550px)", textAlign: "right" }}>
                  {this.props.user.stockalloc_permission && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleGenerateDispatch(arrLoadouts)}
                        style={{ marginLeft: "10px" }}
                        disabled={this.state.createdispatchloading}
                      >
                        Create Dispatch
                        {this.state.createdispatchloading && <CircularProgress color="primary" size={14} style={{ marginLeft: "10px", color: "black" }} />}
                      </Button>
                    </>
                  )}
                  {arrLoadouts &&
                    arrLoadouts.length > 0 &&
                    arrLoadouts.find((lditem) => lditem.loadout_id > 0) &&
                    (filteredRowsSelected.find((row) => row.sale_id) ? (
                      <OrangeButton variant="contained" onClick={this.handleRedirectToProForma} style={{ marginLeft: "10px" }}>
                        OPEN PRO-FORMA
                      </OrangeButton>
                    ) : (
                      this.props.user.stockalloc_permission && (
                        <>
                          <Button variant="contained" color="primary" onClick={() => this.handleGenerateProForma(arrLoadouts)} style={{ marginLeft: "10px" }}>
                            Generate Pro-Forma
                          </Button>
                        </>
                      )
                    ))}
                  {!this.allowAccept() && this.state.onReload && this.props.user.stockalloc_permission && (
                    <Button variant="contained" color="secondary" onClick={this.handleUnAllocate} style={{ marginLeft: "10px" }}>
                      un-allocate
                    </Button>
                  )}
                  <Button variant="contained" color="primary" onClick={this.selectedClear} style={{ marginLeft: "10px" }}>
                    clear selected
                  </Button>
                  <Tooltip title="Clear Filters">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.selectedRowsClearFilters}
                      style={{ marginLeft: "10px" }}
                      disabled={Object.entries(this.state.selectedRowsFilters).length === 0 && this.state.selectedRowsFilters.constructor === Object}
                    >
                      <Badge badgeContent={this.filterTerms(this.state.selectedRowsFilters)} color="secondary">
                        <ClearFilterIcon />
                      </Badge>
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ width: "calc(100vw - 550px)", marginRight: "10px", float: "left", marginTop: "50px" }}>
                  <ReactDataGrid
                    enableFilterRow
                    rowHeight={30}
                    headerRowHeight={35}
                    className="rdg-light"
                    rows={filteredRowsSelected}
                    columns={selectedRowscolumnsToRender}
                    rowRenderer={this.selectedRowRenderer}
                    sortColumn={this.state.currentSortColumnSelected}
                    ref={(input) => (this.state.selectedRowGridRef = input)}
                    summaryRows={summaryRows(filteredRowsSelected, totalColumns)}
                    sortDirection={this.state.currentSortDirectionSelected as any}
                    onFiltersChange={(filter) => this.handleSelectedRowsFilterChange(filter)}
                    onSort={(sortColumn, sortDirection) => this.handleSortSelected(sortColumn, sortDirection)}
                    style={{ position: "relative", height: document.getElementsByTagName("Main")[0].clientHeight - (170 + this.getChipsHeightSelected()) }}
                  />
                  <div>
                    <StockAllocationFilterChips
                      columns={selectedRowscolumnsToRender}
                      filterData={this.state.selectedRowsFilters}
                      handleFilterClear={null}
                      sortingData={this.state.sortingSelected}
                      removesortingentry={this.handleRemoveSortSelectedEntry}
                      removeFilterEntry={this.handleRemoveFilterSelectedEntry}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.props.user.stockalloc_permission && this.state.selectedRows.length == 0 && (
              <AcceptAllocationDialog onSubmit={this.allocationRequested} defaultData={this.getLoadout(filteredRowsSelected)} />
            )}
            {this.props.user.stockalloc_permission && this.state.selectedRows.length > 0 && (
              <AcceptAllocationDialog onSubmit={this.allocationRequested} defaultData={this.getLoadout(filteredRowsSelected)} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const StockAllocationTableChild = withStyles(styles)(StockAllocationTableChildUnstyled);
