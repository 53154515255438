import { GroupConsolidationAmendDataType, GroupConsolidationStatusDataType } from "../../finance/consolidation/consolidation";
import { getFinancialYearSelected } from "./week";
import { apiHeaders, client, request } from "./_base";
const endpoint = "consolidation";

export type ConsolidationType = {
  invoicenumber: string;
  dispatch_id: number;
  ata: Date;
  barcode_id: number;
  barcode: string;
  IntakeWaybill: string;
  "Farm Number": string;
  "Target Market": string;
  "Commodity Code": string;
  "Variety Code": string;
  "Grade Code": string;
  "Pack Code": string;
  "Count Code": string;
  "Mark Code": string;
  "No Cartons": string;
  "Pallet Size": string;
  "Producer ID": string;
  shipment_week: string;
  shipment_season: string;
  currency_id: number;
  currency: string;
  sellingprice: number;
  sellingpricefinal: number;
  foreigncost: number;
  nettsellprice: number;
  roe_eta: number;
  roe_etd: number;
  exchangerate: number;
  adjustment: number;
  sellingpricezar: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  margin: number;
  totalcost: number;
  rtgc: number;
  rtgp: number;
  advance: number;
  final: number;
  camend_id: number;
  camend_status: number;
  camend_final: number;
  camenddetails_id: number;
};
export const consolidation = client(endpoint, (item) => ({
  ...item,
}));

export type ConsolidationGroupedType = {
  invoicenumber: string;
  dispatch_id: number;
  ata: Date;
  IntakeWaybill: string;
  currency: string;
  sellingprice: number;
  sellingpricefinal: number;
  foreigncost: number;
  nettsellprice: number;
  roe_eta: number;
  roe_etd: number;
  exchangerate: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  sellpricezar: number;
  margin: number;
  totalcost: number;
  rtgc: number;
  rtgp: number;
  advance: number;
  final: number;
  camend_id: number;
  camend_status: number;
  camend_final: number;
};

export const consolidationGrouped = async (): Promise<ConsolidationGroupedType[]> => {
  const result = await request(`${endpoint}/ext/grouped`);
  const json = await result.json();
  if (result.status == 500) {
    throw new Error(result.statusText);
  }
  return json.data;
};

export type ConsolidationGroupedProducerType = {
  no_cartons: number;
  IntakeWaybill: string;
  adjustment: number;
  advance: number;
  amounts: any;
  ata: string;
  camend_final: number;
  camend_id: number;
  camend_status: number;
  camenddetails_commission: number;
  cga_est: number;
  cga_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  containerno: string;
  currency: string;
  dispatch_id: number;
  exchangerate: number;
  palletSize: number;
  final: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  foreigncost: number;
  idents: any;
  invoicenumber: string;
  margin: number;
  nettsellprice: number;
  portdist_code: string;
  ppecb_est: number;
  ppecb_final: number;
  roe_eta: number;
  roe_etd: number;
  rtgc: number;
  rtgp: number;
  rtgpzar: number;
  sellingprice: number;
  sellingpricefinal: number;
  sellpricezar: number;
  shipment_week: string;
  target_market: string;
  totalcost: number;
  vessel_name: string;
  final_destination: string;
  makeanote: string;
  payment_date: Date;
};

export const consolidationGroupedByProducer = async (producer: string, financial_year: number): Promise<ConsolidationGroupedProducerType[]> => {
  const result = await request(`${endpoint}/ext/groupedByProducer/${producer}/${financial_year}`);
  const json = await result.json();
  if (result.status == 500) {
    throw new Error(result.statusText);
  }
  return json.data;
};

export type ConsolidationByInvoiceType = {
  invoicenumber: string;
  dispatch_id: number;
  ata: Date;
  barcode_id: number;
  barcode: string;
  IntakeWaybill: string;
  FarmNumber: string;
  TargetMarket: string;
  VarietyCode: string;
  GradeCode: string;
  PackCode: string;
  CountCode: string;
  MarkCode: string;
  palletSize: number;
  NoCartons: number;
  producerid: string;
  currency_id: number;
  currency: string;
  sellingprice: number;
  sale_credit: number;
  sellingprice_final: number;
  foreigncost: number;
  nettsellprice: number;
  roe_eta: number;
  roe_etd: number;
  exchangerate: number;
  adjustment: number;
  sellpricezar: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  ppecb_est: number;
  ppecb_final: number;
  cga_est: number;
  cga_final: number;
  margin: number;
  totalcost: number;
  rtgc: number;
  rtgp: number;
  advance: number;
  final: number;
  camend_id: number;
  camend_status: number;
  camend_final: number;
  camenddetails_id: number;
  camenddetails_commission: number;
  ld_fob: number;
  ld_dip: number;
  pio_id: number;
  rowDirty?: number;
  comment: string;
  username: string;
};

export type ConsolidationDetail = {
  username: string;
  invoicenumber: string;
  consignee: string;
  clients_dealtype: string;
  ident: string;
  camend_final: number;
  barcode: string;
  grossWeight: number;
  weight: number;
  arrivaldetail_qualityscore: number;
  IntakeWaybill: string;
  wk_weekcold: string;
  producerid: string;
  FarmNumber: string;
  prod_payment: string;
  prod_dealtype: string;
  containerno: string;
  vessel_code: string;
  portdist_code: string;
  portfinal_code: string;
  ata: string;
  TargetMarket: string;
  target_shipping: string;
  VarietyCode: string;
  GradeCode: string;
  PackCode: string;
  CountCode: string;
  MarkCode: string;
  InventoryCode: string;
  palletSize: string;
  NoCartons: number;
  currency: string;
  exchangerate: number;
  sellingprice: number;
  sale_credit: number;
  sale_debit: number;
  sellingprice_final: number;
  foreigncost: number;
  nettsellprice: number;
  ld_fob: number;
  adjustment: number;
  roe_etd: number;
  roe_eta: number;
  sellpricezar: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  ppecb_est: number;
  ppecb_final: number;
  cga_est: number;
  cga_final: number;
  margin: number;
  marginperc: number;
  totalcost: number;
  rtgc: number;
  rtgczar: number;
  ld_dip: number;
  advance: number;
  final: number;
  rtgp: number;
  rtgpzar: number;
  diff_rtg_dip: number;
  camend_status: number;
  comment: string;
  barcode_id: number;
  camend_id: number;
  camenddetails_id: number;
  camenddetails_commission: number;
  rowDirty?: number;
  sati_est: number;
  sati_final: number;
  transportCost: number;
  transportCost_est: number;
  transportCost_final: number;
  royaltiesCost: number;
  royaltiesCost_est: number;
  royaltiesCost_final: number;
  sale_credit_total: number;
  sale_debit_total: number;
  totalcalcsellingprice: number;
  totalcalcsellingprice_final: number;
  totalcalcforeigncost: number;
  totalcalcadjustment: number;
  totalcalcnettsellprice: number;
  totalcalcsellpricezar: number;
  totalcalcfobcost: number;
  totalcalcfobcost_est: number;
  totalcalcfobcost_final: number;
  totalcalccoldcost: number;
  totalcalccoldcost_est: number;
  totalcalccoldcost_final: number;
  totalcalcppecb_est: number;
  totalcalcppecb_final: number;
  totalcalccga_est: number;
  totalcalccga_final: number;
  totalcalcmargin: number;
  totalcalctotalcost: number;
  totalcalcsati_est: number;
  totalcalcsati_final: number;
  totalcalctransportCost: number;
  totalcalctransportCost_est: number;
  totalcalctransportCost_final: number;
  totalcalcroyaltiesCost: number;
  totalcalcroyaltiesCost_est: number;
  totalcalcroyaltiesCost_final: number;
};

export const consolidationByInvoiceNumbers = async (invoicenumbers: string[]): Promise<ConsolidationDetail[]> => {
  const content = JSON.stringify({ invoicenumbers });
  const result = await request(`${endpoint}/ext/getConsolDetailsByInvoicenumbers`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });

  const json = await result.json();
  if (result.status == 500) {
    throw result.statusText;
  }
  return json.data;
};

export const consolidationFinByInvoiceNumbers = async (invoicenumbers: string[]): Promise<ConsolidationDetail[]> => {
  const content = JSON.stringify({ invoicenumbers });
  const result = await request(`${endpoint}/ext/getFinConsolDetailsByInvoicenumbers`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });

  const json = await result.json();
  if (result.status == 500) {
    throw result.statusText;
  }
  return json.data;
};

export const consoldationDetailByInvoice = async (invoicenumber: string | string[]): Promise<ConsolidationDetail[]> => {
  const content = JSON.stringify(invoicenumber);
  const result = await request(`${endpoint}/ext/detailByInvoiceNumber`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const json = await result.json();
  if (result.status == 500) {
    throw new Error(result.statusText);
  }
  return json.data;
};

export const getAllConsolidation = async (): Promise<ConsolidationDetail[]> => {
  const result = await request(`${endpoint}/ext/all/${getFinancialYearSelected()}`);
  const json = await result.json();
  if (result.status == 500) {
    throw new Error(result.statusText);
  }
  return json.data;
};

export type ConsolidationFinalDetailType = {
  currency_code: string;
  brand: string;
  CommodityCode: string;
  CountCode: string;
  FarmNumber: string;
  GradeCode: string;
  IntakeWaybill: string;
  MarkCode: string;
  NoCartons: number;
  PackCode: string;
  TargetMarket: string;
  VarietyCode: string;
  adjustment: number;
  advance: number;
  arrivaldetail_qualityscore: string;
  ata: Date;
  barcode: string;
  barcode_id: number;
  camend_final: number;
  camend_id: number;
  camend_status: number;
  camenddetails_commission: number;
  camenddetails_id: number;
  cga_est: number;
  cga_final: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  consignee: string;
  containerno: string;
  currency: string;
  currency_id: number;
  dispatch_id: number;
  exchangerate: number;
  final: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  foreigncost: number;
  invoicenumber: string;
  ld_dip: string;
  ld_fob: string;
  ld_match: string;
  margin: number;
  marginperc: number;
  nettsellprice: number;
  palletSize: number;
  portdist_code: string;
  portfinal_code: string;
  ppecb_est: number;
  ppecb_final: number;
  prod_dealtype: string;
  prod_payment: string;
  producerfinalpaidhead_id: number;
  producerid: string;
  roe_eta: number;
  roe_etd: number;
  rtgc: number;
  rtgp: number;
  rtgpzar: number;
  sale_credit: number;
  sale_credit_total: number;
  sale_debit: number;
  sale_debit_total: number;
  sellingprice: number;
  sellingprice_final: number;
  sellpricezar: number;
  target_shipping: string;
  totalcalcadjustment: number;
  totalcalccga_est: number;
  totalcalccga_final: number;
  totalcalccoldcost: number;
  totalcalccoldcost_est: number;
  totalcalccoldcost_final: number;
  totalcalcfobcost: number;
  totalcalcfobcost_est: number;
  totalcalcfobcost_final: number;
  totalcalcforeigncost: number;
  totalcalcmargin: number;
  totalcalcnettsellprice: number;
  totalcalcppecb_est: number;
  totalcalcppecb_final: number;
  totalcalcsellingprice: number;
  totalcalcsellingprice_final: number;
  totalcalcsellpricezar: number[];
  totalcalctotalcost: number;
  totalcost: number;
  vessel_code: string;
  wk_weekcold: string;
  producer_id: number;
  rtgczar: number;
};

export const getConsolidationFinalByInvoiceNumber = async (invoicenumber: string, producerID: string): Promise<ConsolidationFinalDetailType[]> => {
  return await request(`${endpoint}/ext/finalByProducer?invoicenumber=${invoicenumber}&producerID=${producerID}`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const addConsolidationData = async (invoicenumber: any): Promise<any[]> => {
  return await request(`${endpoint}/ext/addConsolidationData/${invoicenumber}`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const addConsolidationDataBulk = async (invoiceNumbers: string): Promise<any[]> => {
  return await request(`${endpoint}/ext/addConsolidationDataBulk`, {
    method: "POST",
    body: JSON.stringify({ invoiceNumbers }),
  })
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const addConsolidationDataStartJob = async (): Promise<any[]> => {
  return await request(`${endpoint}/ext/addConsolidationDataStartJob`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const updateConsolidationDataStartJob = async (): Promise<any[]> => {
  return await request(`${endpoint}/ext/updateConsolidationDataStartJob`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const removeConsolidationData = async (invoicenumber: any): Promise<any[]> => {
  return await request(`${endpoint}/ext/removeConsolidationData/${invoicenumber}`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getBarcodeForeignCost = async (barcodeid): Promise<any[]> => {
  return await request(`${endpoint}/ext/foreigncostinfo/${barcodeid}`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getGroupedInvoices = async (financial_year: string): Promise<any[]> => {
  return await request(`${endpoint}/ext/getGroupedInvoices/${financial_year}`)
    .then((result) => {
      if (result.status == 500) {
        throw result.statusText;
      }
      return result.json();
    })
    .then(({ data }) => data);
};

export const getFinalByIdent = async (final_ident: number): Promise<any[]> => {
  return await request(`${endpoint}/ext/finalByIdent/${final_ident}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getLocalCostInfo = async (invoicenumber: string): Promise<any[]> => {
  return await request(`${endpoint}/ext/localCostInfoByInvoiceNumber/${invoicenumber}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getImpalaFeeInfo = async (invoicenumber: string): Promise<any[]> => {
  return await request(`${endpoint}/ext/impalaFeeInfoByInvoiceNumber/${invoicenumber}`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const getConsolidationDataQueueJobDetail = async () => {
  return await request(`${endpoint}/ext/consolidationDataQueueJobDetail`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const updateConsolidationQueue = async () => {
  return await request(`${endpoint}/ext/updateConsolidationQueue`)
    .then(async (result) => {
      if (result.status == 500) {
        const resp = await result.json();
        throw resp;
      }
      return result.json();
    })
    .then(({ data }) => {
      return data;
    });
};

export const bulkProcessConsolidationData = async (camend: GroupConsolidationAmendDataType[]) => {
  const result = await request(`${endpoint}/ext/bulkProcessConsolidationData`, {
    method: "POST",
    body: JSON.stringify({ camend }),
  });

  if (result.status == 500) {
    throw result.statusText;
  }
  return result.json();
};

export const bulkUpdateConsolidationStatus = async (camend: GroupConsolidationStatusDataType[]) => {
  const result = await request(`${endpoint}/ext/bulkUpdateConsolidationStatus`, {
    method: "POST",
    body: JSON.stringify({ camend }),
  });

  if (result.status == 500) {
    throw result.statusText;
  }
  return result.json();
};

export const manualInsertConsolidationDataByInvoice = async (invoicenumber: string) => {
  const result = await request(`${endpoint}/ext/manualInsertConsolidationDataByInvoice`, {
    method: "POST",
    body: JSON.stringify({ invoicenumber }),
  });

  if (result.status == 500) {
    throw result.statusText;
  }
  return result.json();
};

export const clearConsolidationData = async (data: { invoicenumber: string; camend_id: number }) => {
  const result = await request(`${endpoint}/ext/clearConsolidationData`, {
    method: "POST",
    body: JSON.stringify({ invoicenumber: data.invoicenumber, camend_id: data.camend_id }),
  });

  if (result.status == 500) {
    throw result.statusText;
  }
  return result.json();
};

export const lockSelectedRows = async (data: { barcode_id: number; locked: number }[]): Promise<void> => {
  const result = await request(`${endpoint}/ext/lockSelectedRows`, {
    method: "POST",
    body: JSON.stringify({ data }),
  });

  if (result.status == 500) {
    throw result.statusText;
  }
};
