import { client, request } from "./_base";

const endpoint = "salefunds";

export type SaleFundsType = {
  id?: number;
  clients_id: number;
  posteddate: Date;
  amount: number;
  bankcharges: number;
  status: number;
  currency_id: number;
  exchangerate: number;
  companydetailsbanking_id: number;
  makeanote: string;
  created_at?: Date;
  updated_at?: Date;
  allocated_year: number;
};

export const SaleFunds = client(endpoint, (item) => ({
  ...item,
}));

export type SaleFundsFullType = {
  id?: number;
  clients_id: number;
  clients_code: string;
  clients_name: string;
  posteddate: Date;
  amount: number;
  amountcredit?: number;
  bankcharges: number;
  exchangerate: number;
  makeanote: string;
  available: number;
  availableCredits?: number;
  status: number;
  currency_id: number;
  currency: string;
  companydetailsbanking_id: number;
  created_at?: Date;
  updated_at?: Date;
  adhoc_payment: boolean;
  salefundsadhoc_id?: number;
  allocate?: boolean;
  totalamount: number;
  allocated_year: number;
};

export type SaleFundsSummaryType = {
  clients_code: string;
  clients_name: string;
  currency_code: string;
  amountPaidTotal: number;
  bankchargesTotal: number;
  totalAmount: number;
  availableTotal: number;
  totalOutstanding: number;
  creditLimit: number;
  creditLimit_exp: Date;
  salesTotal: number;
  current_period: number;
  thirty_days: number;
  sixty_days: number;
  ninety_days: number;
  ninetyplus_days: number;
  amountOverpaid: number;
};

export const getSaleFundsFull = async (id: number = 0, clients_code: string = "", financial_year: number = 0) => {
  const result = await request(`${endpoint}/ex/full?id=${id}&clients_code=${clients_code}&financial_year=${financial_year}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

export const getSaleFundsSummary = async (financialyear: number): Promise<{ data: SaleFundsSummaryType[] }> => {
  const result = await request(`${endpoint}/ex/summary/${financialyear}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp;
};

export const getSaleFundById = async (salefund_id: number) => {
  const result = await request(`${endpoint}/ex/saleFundsById?salefund_id=${salefund_id}`, {
    method: "GET",
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};
