export const EstimationsCodes = {
  1: "1",
  2: "2",
  3: "3",
  55: "55",
  65: "65",
  70: "70",
  72: "72",
  80: "80",
  88: "88",
  104: "104",
  115: "115",
  120: "120",
  150: "150",
  160: "160",
  170: "170",
  180: "180",
};

export const getEstimationsCodesReadyForCombo = () => {
  return Object.keys(EstimationsCodes).map((st) => ({ value: st, display: EstimationsCodes[st] }));
};
