import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "react-datepicker/dist/react-datepicker.css";
import Radio from "@material-ui/core/Radio";

import { wrap } from "./string_methods";

export const MaterialSelectComponent = ({ input: { value, name, onChange, ...restInput }, meta, ...rest }) => {
  return (
    <Select {...rest} name={name} onChange={onChange} value={value} multiple={rest.multiple}>
      <MenuItem key="value" value="">
        <em>None</em>
      </MenuItem>

      {rest.childdata &&
        rest.childdata.map((data) => {
          return (
            <MenuItem key={`${name}-${data.value}`} value={data.value}>
              {data.display}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export const MaterialSelectNativeComponentNonField = (props) => {
  const { name, onChange, value, addNone, childdata, nonedisabled } = props;
  return (
    <Select name={name} onChange={onChange} native style={{ width: "100%" }} value={value}>
      {addNone && (
        <option key={`${name}none`} disabled={nonedisabled} value="">
          NONE
        </option>
      )}
      {childdata &&
        childdata.map((data) => {
          return (
            <option key={`${name}-${data.value}`} value={data.value}>
              {data.display}
            </option>
          );
        })}
    </Select>
  );
};
// very specific for instructions. Wrapping method is precise.
export const MaterialWrappedTextfieldComponent = ({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
    inputProps={restInput}
    onChange={(e) => {
      onChange(wrap((e.target.value || " ").replace(/(\w{54})(?=\w)/g, "$1-\n"), 70));
    }}
    value={value}
    style={{ width: "100%", height: "100%" }}
  />
);

export const MaterialCheckBoxComponent = ({ input: { checked, name, onChange, ...restInput }, meta, ...rest }) => (
  <FormControlLabel
    control={<Checkbox {...rest} name={name} inputProps={restInput} onChange={(ev) => onChange(ev.target.checked)} checked={checked} />}
    label={rest.label}
    labelPlacement="start"
  />
);

export const MaterialInputComponent = ({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => (
  <Input
    {...rest}
    name={name}
    error={meta.error && meta.touched}
    inputProps={restInput}
    onChange={(e) => {
      e.target.value = e.target.value.toString().toUpperCase();
      onChange(e);
    }}
    value={value}
    style={{ width: "100%" }}
  />
);

export const MaterialRadioComponent = ({ input: { value, name, onChange, ...restInput }, meta, ...rest }) => (
  <FormControlLabel
    control={
      <Radio
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={() => {
          onChange(!value);
        }}
        checked={value.toString() == "true" || value == "1"}
      />
    }
    label={rest.label}
    labelPlacement="start"
  />
);
