import { image as logo, image } from "./elements/logo";
import { PTSans } from "./elements/font";

import toDate from "date-fns/toDate";
import parseISO from "date-fns/parseISO";
import { format } from "date-fns";
import LZString from "lz-string";

import { arrivals } from "../lib/api/arrival";
import { arrivaldataByContainerProducerSalesRef } from "../lib/api/arrivaldata";

import jsPDF from "jspdf";

let pdf;
let fromTop = 1;
let page = 1;
let fromLeft = 9.0;
const lineHeight = 0.5;
const maxPageHeight = 28;

const grey = 210;
const red = { r: 255, g: 82, b: 69 };
const orange = { r: 235, g: 137, b: 71 };
const green = { r: 123, g: 228, b: 81 };

const columnDetails = {
  farmnumber: { width: 2, header: "Farm" },
  pickingref: { width: 2, header: "P Ref" },
  waybill: { width: 3, header: "Waybill" },
  intakepalletid: { width: 4.5, header: "Intake Pallet ID" },
  varietycode: { width: 1.5, header: "Variety" },
  gradecode: { width: 1.5, header: "Grade" },
  countcode: { width: 1.5, header: "Count" },
  markcode: { width: 1.5, header: "Brand" },
  qualityscore: { width: 1.5, header: "Quality" },
};

const legendWidth = columnDetails["farmnumber"].width + columnDetails["pickingref"].width + columnDetails["waybill"].width;
const scoreWidth = columnDetails["farmnumber"].width;
const commentWidth = columnDetails["pickingref"].width + columnDetails["waybill"].width;
let legendHeight = 0;

const InitialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");

  pdf.setFont("PTSans");
  page = 1;

  fromTop = 1;
  fromLeft = 1.2;

  pdf.setLineWidth(0.01);
};

const newPage = () => {
  pdf.addPage();
  page = page + 1;
  pdf.setPage(page);
  fromTop = 1;
  fromLeft = 1;
};

const printPageDetails = (arrival, arrivalData) => {
  //bottom right print point of page: fromTop: 28.09 fromLeft: 20
  pdf.setFontSize(8);
  pdf.setPage(1);

  for (let i = 1; i <= page; i++) {
    pdf.setPage(i);
    pdf.text(
      20,
      28.6,
      `${arrival && arrival.print_date ? format(parseISO(arrival.print_date), "yy-MM-dd") : ""}-${arrival ? arrival.producerid : ""}-${arrivalData.pod}-${arrivalData.salesref}`,
      null,
      null,
      "right",
    );
    pdf.text(20, 29, `PAGE ${i} of ${page}`, null, null, "right");
  }
};

// const printPageTDMargin = () => {
//   let revertfromTop = fromTop;
//   fromTop = 1;
//   for (let index = 0; index < 58; index += 0.5) {
//     pdf.text(0, fromTop, fromTop.toString(), null, null, "left"); // Print fromLeft value
//     fromTop = fromTop + 1;
//   }
//   fromTop = revertfromTop;
// };

// const printPageRLRightMargin = () => {
//   let revertfromLeft = fromLeft;
//   fromLeft = 1;
//   for (let index = 0; index < 22; index++) {
//     pdf.text(fromLeft, 0.5, fromLeft.toString(), null, null, "left"); // Print fromLeft value
//     fromLeft = fromLeft + 1;
//   }
//   fromLeft = revertfromLeft;
// };

export const handlePrint = async (arrival_id, container, producerid, salesref) => {
  const arrival = await arrivals.single(arrival_id);

  if (!arrival.print_date) {
    arrival.print_date = format(toDate(new Date()), "yyyy-MM-dd");
    const arrivalUpdate = { ...arrival };
    delete arrivalUpdate["id"];
    arrivals.update(arrival.id, arrivalUpdate);
  }
  const arrivalData = await arrivaldataByContainerProducerSalesRef(container, producerid, salesref);

  InitialisePDF();

  printHeader(arrivalData[0], arrival);

  printDetail(arrivalData);

  printLegend();

  printComments(arrival.comments);

  await printImages(arrival);

  printPageDetails(arrival, arrivalData[0]);

  pdf.save(
    `${arrival && arrival.print_date ? format(parseISO(arrival.print_date), "yyMMdd") : ""}_AQR_${arrivalData[0].salesref.toString()}_${arrivalData[0].producer} (${
      arrivalData[0].producerid
    })_${arrivalData[0].portofdischargedesc}.pdf`,
  );
  // pdf.save(`${arrivalData[0].containerno.toString()}_${arrivalData[0].salesref.toString()}_${arrivalData[0].farmnumberfull.toString()}.pdf`);
};

let legendTop;

const printHeader = (arrivalHeader, arrival) => {
  fromTop = 2;
  fromLeft = 11.5;
  pdf.addImage(logo, logo.split("/")[1].split(";")[0], 1, 0.5, 7, 2.5);

  pdf.setFontSize(15);
  pdf.setFillColor(grey);
  pdf.rect(8.5, 1.4, 11.5, 0.8, "DF");
  pdf.text(fromLeft, fromTop, "ARRIVAL QUALITY REPORT", "left");
  fromTop = fromTop + lineHeight;

  pdf.setFontSize(10);
  pdf.setFillColor(grey);
  let headerRowTop = 3.5;
  for (let headerRowCount = 0; headerRowCount < 9; headerRowCount++) {
    pdf.rect(1, headerRowTop, 7.5, lineHeight, "DF");
    pdf.rect(8.5, headerRowTop, 11.5, lineHeight, "S");
    headerRowTop = headerRowTop + lineHeight;
  }

  fromTop = 3.89;

  pdf.text(8.4, fromTop, "GROWER", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.producer.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "ARRIVAL DATE", null, null, "right");
  pdf.text(9, fromTop, !arrivalHeader.ata ? "N/A" : format(parseISO(arrivalHeader.ata), "E, dd MMM yyyy"), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "PACKHOUSE CODE", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.phc.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "FARM NUMBER", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.farmnumberfull.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "DATE OF REPORT", null, null, "right");
  pdf.text(9, fromTop, arrival && arrival.print_date ? format(parseISO(arrival.print_date), "E, dd MMM yyyy") : "", null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "DESTINATION", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.portofdischargedesc.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "VESSEL", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.vessel.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "CONTAINER NUMBER", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.containerno.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;

  pdf.text(8.4, fromTop, "CONSIGNMENT REF", null, null, "right");
  pdf.text(9, fromTop, arrivalHeader.salesref.toString(), null, null, "left");
  fromTop = fromTop + lineHeight;
};

const printDetail = (arrivalData) => {
  writeColumnHeaders();

  let moreRows: boolean = true;

  arrivalData.map((row, i) => {
    Object.keys(columnDetails).map((key) => {
      pdf.setLineWidth(0.01);
      if (key != "qualityscore" || (key == "qualityscore" && row[key] == 4) || row[key] == 0) {
        pdf.rect(fromLeft, fromTop, columnDetails[key].width, lineHeight, "S");
      } else if (row[key] == 1) {
        pdf.setFillColor(red.r, red.g, red.b);
        pdf.rect(fromLeft, fromTop, columnDetails[key].width, lineHeight, "DF");
      } else if (row[key] == 2) {
        pdf.setFillColor(orange.r, orange.g, orange.b);
        pdf.rect(fromLeft, fromTop, columnDetails[key].width, lineHeight, "DF");
      } else if (row[key] == 3) {
        pdf.setFillColor(green.r, green.g, green.b);
        pdf.rect(fromLeft, fromTop, columnDetails[key].width, lineHeight, "DF");
      }
      pdf.text(fromLeft + columnDetails[key].width / 2, fromTop + 0.37, row[key].toString(), null, null, "center");
      fromLeft = fromLeft + columnDetails[key].width;
    });
    moreRows = arrivalData.length > i + 1;
    if (fromTop >= maxPageHeight - 1) {
      newPage();

      if (moreRows) {
        writeColumnHeaders();
      }
    } else {
      fromTop = fromTop + lineHeight;
      fromLeft = 1;
    }
  });
};

const writeColumnHeaders = () => {
  fromLeft = 1;

  pdf.setLineWidth(0.01);
  pdf.setFillColor(grey);

  Object.keys(columnDetails).map((key) => {
    pdf.setFillColor(grey);
    pdf.rect(fromLeft, fromTop, columnDetails[key].width, lineHeight + 0.2, "DF");
    pdf.text(fromLeft + columnDetails[key].width / 2, fromTop + 0.45, columnDetails[key].header.toString().toUpperCase(), null, null, "center");
    fromLeft = fromLeft + columnDetails[key].width;
  });

  fromLeft = 1;
  fromTop = fromTop + (lineHeight + 0.2);
};

const printLegend = () => {
  // printPageSizeRightMargin();
  fromTop = fromTop + 0.5;

  if (fromTop >= maxPageHeight || fromTop >= maxPageHeight - 2.5) {
    // 2.5 === actual legend height
    newPage();
  }

  pdf.setLineWidth(0.01);
  legendTop = fromTop;
  fromLeft = 1;
  legendHeight = 0;

  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, legendWidth, lineHeight, "DF");
  pdf.text(fromLeft + legendWidth / 2, fromTop + 0.37, "QUALITY SCORE", null, null, "center");
  fromTop = fromTop + lineHeight;
  legendHeight += lineHeight;

  fromLeft = 1;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, scoreWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + scoreWidth / 2, fromTop + 0.32, "SCORE", null, null, "center");

  fromLeft = fromLeft + legendWidth - commentWidth;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, commentWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + commentWidth / 2, fromTop + 0.32, "COMMENT", null, null, "center");
  fromTop = fromTop + (lineHeight - 0.1);
  legendHeight += lineHeight - 0.1;

  pdf.setFontSize(6);
  //1
  fromLeft = 1;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, scoreWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + scoreWidth / 2, fromTop + 0.28, "1", null, null, "center");

  fromLeft = fromLeft + legendWidth - commentWidth;
  pdf.setFillColor(255, 82, 69);
  pdf.rect(fromLeft, fromTop, commentWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + commentWidth / 2, fromTop + 0.28, "TOTAL LOSS", null, null, "center");
  fromTop = fromTop + (lineHeight - 0.1);
  legendHeight += lineHeight - 0.1;

  //2
  fromLeft = 1;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, scoreWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + scoreWidth / 2, fromTop + 0.28, "2", null, null, "center");

  fromLeft = fromLeft + legendWidth - commentWidth;
  pdf.setFillColor(235, 137, 71);
  pdf.rect(fromLeft, fromTop, commentWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + commentWidth / 2, fromTop + 0.28, "DEFINITE CLAIM", null, null, "center");
  fromTop = fromTop + (lineHeight - 0.1);
  legendHeight += lineHeight - 0.1;

  //3
  fromLeft = 1;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, scoreWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + scoreWidth / 2, fromTop + 0.28, "3", null, null, "center");

  fromLeft = fromLeft + legendWidth - commentWidth;
  pdf.setFillColor(123, 228, 81);
  pdf.rect(fromLeft, fromTop, commentWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + commentWidth / 2, fromTop + 0.28, "ACCEPTED WITH DEVIATIONS - POSSIBLE CLAIM", null, null, "center");
  fromTop = fromTop + (lineHeight - 0.1);
  legendHeight += lineHeight - 0.1;

  //4
  fromLeft = 1;
  pdf.setFillColor(grey);
  pdf.rect(fromLeft, fromTop, scoreWidth, lineHeight - 0.1, "DF");
  pdf.text(fromLeft + scoreWidth / 2, fromTop + 0.28, "4", null, null, "center");

  fromLeft = fromLeft + legendWidth - commentWidth;
  pdf.rect(fromLeft, fromTop, commentWidth, lineHeight - 0.1, "S");
  pdf.text(fromLeft + commentWidth / 2, fromTop + 0.28, "GOOD ARRIVAL", null, null, "center");
  legendHeight += lineHeight - 0.1;

  pdf.setFontSize(10);
};

const maxCharactersAllowedInComments = 77;

const formatComments = (commentsArr: string[], newArr: string[] = []) => {
  if (commentsArr.length === 0) return newArr;

  const firstComment = commentsArr.splice(0, 1)[0];

  if (firstComment.length >= maxCharactersAllowedInComments) {
    const comment = firstComment.split("");
    const splitcomment = comment.splice(0, maxCharactersAllowedInComments);

    commentsArr.splice(0, 0, comment.join(""));
    newArr.push(splitcomment.join(""));
  } else {
    newArr.push(firstComment);
  }

  return formatComments(commentsArr, newArr);
};

const printComments = (comments) => {
  const leftPadding = 0.1;

  pdf.setFillColor(grey);
  pdf.setLineWidth(0.01);

  fromTop = legendTop;
  fromLeft = legendWidth + 1.5;

  // add header
  pdf.rect(fromLeft, fromTop, 20 - fromLeft, lineHeight, "DF");
  pdf.text(fromLeft + (20 - fromLeft) / 2, fromTop + 0.37, "ADDITIONAL COMMENTS", null, null, "center");

  fromTop = fromTop + 0.6;

  let startRect = fromTop;
  const formattedComments = formatComments(comments.split("\n"));

  for (const comment of formattedComments) {
    // if next iteration height is greater than height on the page
    if (fromTop + lineHeight > maxPageHeight) {
      // draw box
      pdf.rect(fromLeft, startRect - 0.1, 20 - (legendWidth + 1.5), fromTop - startRect, "S");

      // add new page
      newPage();
      startRect = fromTop;
      fromLeft = legendWidth + 1.5;
      pdf.setFillColor(grey);
      pdf.setLineWidth(0.01);

      // add header to new page for comments
      pdf.rect(fromLeft, fromTop - 0.1, 20 - fromLeft, lineHeight, "DF");
      pdf.text(fromLeft + (20 - fromLeft) / 2, fromTop + 0.27, "ADDITIONAL COMMENTS", null, null, "center");

      fromTop = fromTop + 0.6;
    }

    pdf.text(fromLeft + leftPadding, fromTop + 0.19, comment.toString(), null, null, "left");
    fromTop = fromTop + (lineHeight - 0.1);
  }

  if (formattedComments.length < 5) {
    fromTop += (5 - formattedComments.length) * (lineHeight - 0.1);
  }

  pdf.rect(fromLeft, startRect - 0.1, 20 - (legendWidth + 1.5), fromTop - startRect, "S");
};

const printImages = async (arrival) => {
  await new Promise((resolve, reject) => {
    if (!arrival || !arrival.fotos) {
      resolve("ok");
      return;
    }

    const images = arrival.fotos;
    const imagesJSON = JSON.parse(images);
    if (!images || !(JSON.parse(images).length > 0)) {
      resolve("ok");
      return;
    }

    newPage();

    pdf.setLineWidth(0.01);
    pdf.setFillColor(grey);
    fromLeft = 1;

    let heightRetainer = { x: 0, y: 0 };
    let arrivalImagesHeader = "ARRIVAL PHOTOS RECEIVED FROM CLIENT";
    pdf.rect(fromLeft, fromTop, 20 - fromLeft, lineHeight, "DF");
    pdf.text(fromLeft + (20 - fromLeft) / 2, fromTop + 0.37, arrivalImagesHeader, null, null, "center");

    fromTop = fromTop + 1;
    const promises = [];
    for (let index = 0; index < imagesJSON.length; index++) {
      const image = imagesJSON[index].image;
      const imageComment = imagesJSON[index].comment;
      promises.push(
        new Promise((resolve, reject) => {
          try {
            const imageDec = image.includes("base64") ? image : LZString.decompress(image);
            const img = new Image();
            img.onload = (imgloaded) => {
              const extension = imageDec.split("/")[1].split(";")[0];
              const dimensions = calculateAspectRatioFit(img.width, img.height, 345 / 37.8, 345 / 37.8);

              if (fromTop >= maxPageHeight || fromTop >= maxPageHeight - dimensions.height) {
                newPage();
                pdf.setLineWidth(0.01);
                pdf.setFillColor(grey);
                pdf.rect(fromLeft, fromTop, 20 - fromLeft, lineHeight, "DF");
                pdf.text(fromLeft + (20 - fromLeft) / 2, fromTop + 0.37, arrivalImagesHeader, null, null, "center");

                fromTop = fromTop + 1;
              }

              pdf.addImage(img, extension.toString(), fromLeft, fromTop, dimensions.width, dimensions.height);
              if (imageComment && imageComment.length > 0) {
                pdf.setLineWidth(0.0);
                pdf.setFillColor(grey);
                pdf.rect(fromLeft, fromTop, 5, lineHeight, "DF");
                pdf.text(fromLeft + 0.5, fromTop + 0.37, imageComment, null, null, "center");
              }

              if (fromLeft != 1) {
                fromLeft = 1;
                heightRetainer.y = dimensions.height;
                fromTop = fromTop + Math.max(heightRetainer.x, heightRetainer.y) + 0.5;
              } else {
                fromLeft = 20 - 345 / 37.8;
                heightRetainer.x = dimensions.height;
              }
              resolve("ok");
            };
            img.src = imageDec;
          } catch (err) {
            reject(err);
          }
        }),
      );
    }

    Promise.all(promises)
      .then((arrOfResults) => {
        resolve("ok");
      })
      .catch((err) => {
        resolve(err);
      });
  });
};
const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};
