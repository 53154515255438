import React from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Print from "@material-ui/icons/Print";
import GridOn from "@material-ui/icons/GridOn";
import ListAlt from "@material-ui/icons/ListAlt";

import { printInstructionPDF } from "../reports/printing";

const ITEM_HEIGHT = 58;

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1) / 2,
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  });

type PrintMenuProps = {
  orderId: any;
} & WithStyles<typeof styles>;

class PrintMenu extends React.Component<PrintMenuProps, any> {
  state = {
    anchorEl: null,
    orderId: undefined,
  };

  constructor(props: any) {
    super(props);
    this.state.orderId = props.orderId;
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (action: string) => {
    this.setState({ anchorEl: null });
    switch (action) {
      case "pdf":
        printInstructionPDF(this.state.orderId);
        break;
      default:
        break;
    }
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { classes } = this.props;
    return (
      <>
        <Button
          color="primary"
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          variant="contained"
          className={classes.button}
        >
          Documents
          <ListAlt className={classes.rightIcon} />
        </Button>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            this.handleClose("");
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 250,
            },
          }}
        >
          <MenuItem
            key={`menuitem-pdf`}
            onClick={() => {
              this.handleClose("pdf");
            }}
          >
            <ListItemIcon>
              <Print style={{ color: "orange" }} />
            </ListItemIcon>
            <ListItemText primary={"PRINT PDF"} />
          </MenuItem>
          <MenuItem key={`menuitem-excel`}>
            <ListItemIcon>
              <GridOn style={{ color: "orange" }} />
            </ListItemIcon>
            <a href={`/api/piinstruction/extend/exportInstructionsExcelSingle/${this.state.orderId}`} style={{ textDecoration: "none", color: "black" }}>
              EXPORT EXCEL
            </a>
          </MenuItem>
          <MenuItem key={`menuitem-excelAll`}>
            <ListItemIcon>
              <GridOn style={{ color: "orange" }} />
            </ListItemIcon>
            <a href={`/api/piinstruction/extend/exportInstructionsExcel/${this.state.orderId}`} style={{ textDecoration: "none", color: "black" }}>
              EXPORT FULL EXCEL
            </a>
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(PrintMenu);
