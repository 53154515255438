import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

export const palletspecs = client("palletspec", (item) => ({
  ...item,
}));

export const palletspecFull = async () => {
  return request(`palletspec/extend/all`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });
};

export const palletspecCartons = () =>
  request(`palletspec/extend/cartons`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

// extended methods

const endpoint = "palletspec";

export const palletSpecsByVarietyId = async (varietyId) => {
  const result = await request(`${endpoint}/PalletSpecsByVarietyId/${varietyId}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const palletspecsByVarietySortedMappedforCombo = async (varietyid) => {
  const result = await palletSpecsByVarietyId(varietyid).then((data) => {
    const returnArray = data.data;
    return returnArray
      .sort((a, b) => {
        if (Number(a.code) > Number(b.code)) return 1;
        if (Number(a.code) < Number(b.code)) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: `${item.code}-${item.carton}` };
      });
  });
  return result;
};

export const palletSpecsbyCommodityCode = async (commodityCode) => {
  const result = await request(`${endpoint}/PalletSpecsByCommodityCode/${commodityCode}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const palletspecsByCommodityCodeSortedMappedforCombo = async (commodityCode) => {
  const result = await palletSpecsbyCommodityCode(commodityCode).then((data) => {
    const returnArray = data.data;
    return returnArray
      .sort((a, b) => {
        if (Number(a.code) > Number(b.code)) return 1;
        if (Number(a.code) < Number(b.code)) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: item.id, display: `${item.code}-${item.carton}` };
      });
  });
  result.unshift({ id: undefined, value: undefined, display: undefined });
  return result;
};

export const palletspecsSortedMappedforCombo = async () => {
  const result = await palletspecFull().then((data) => {
    const returnArray = data.data;
    return returnArray
      .sort((a, b) => {
        if (Number(a.code) > Number(b.code)) return 1;
        if (Number(a.code) < Number(b.code)) return -1;
        return 1;
      })
      .map((item) => {
        return { id: item.id, value: item.id, display: `${item.code}-${item.carton}` };
      });
  });
  return result;
};
