import React from "react";
import { Redirect } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import numeral from "numeral";

import InstructionEdit from "./instructionedit";
import { TemplatesDialog } from "../maintenance/templates/templatesDialog";

//endpoints api
import { piorder, getOrdersByGroupNumber } from "../lib/api/piorder";
import { piinstructions } from "../lib/api/piinstruction";
import { getActiveProducers } from "../lib/api/producer";
import { varieties } from "../lib/api/variety";
import { getFinancialYearSelected, weeksOrdered } from "../lib/api/week";
import { targetsSorted } from "../lib/api/target";
import { classes } from "../lib/api/classes";
import { counts } from "../lib/api/counts";
import { getAllActiveColdrooms } from "../lib/api/coldroom";
import { palletSpecsByVarietyId } from "../lib/api/palletspec";
import { getActiveBrands } from "../lib/api/brand";
import { inventories } from "../lib/api/inventory";
import { mgps } from "../lib/api/mgp";
import { costestimations } from "../lib/api/costestimation";
import Confirmation from "../lib/components/confirmation";
import { estimatedcostsetupssummary } from "../lib/api/estimatedcostsetup";

import { getNextOrderNumber } from "../orders/_shared";
import { guid } from "../lib/helpers/GetRandomGuid";

class InstructionContainer extends React.Component<any, any> {
  state = {
    loading: true,
    order: undefined,
    instructions: undefined,
    instructionsUpdated: undefined,
    redirect: false,
    producers: undefined,
    varieties: undefined,
    variety: undefined,
    weeks: undefined,
    targets: undefined,
    classes: undefined,
    counts: undefined,
    coldrooms: undefined,
    palletspecs: undefined,
    brands: undefined,
    inventories: undefined,
    mgps: undefined,
    costestimations: undefined,
    showTemplateDialogue: false,
    showPIHead: true,
    confirmRemove: false,
    confirmRemoveId: undefined,
    headerdirty: false,
    paramsid: undefined,
    estimatedcost: undefined,
    redirectTabbed: undefined,
    refreshTabs: undefined,
    confirmCreateGroup: undefined,
    groupMaster: true,
    selectedRows: undefined,
  };

  constructor(props) {
    super(props);
    this.state.loading = true;

    if (props.match && props.match.params) {
      this.state.paramsid = props.match.params.id;
    } else {
      this.state.paramsid = props.id;
    }

    if (props.refreshTabs) {
      this.state.refreshTabs = props.refreshTabs;
    }

    if (props.groupMaster != undefined) {
      this.state.groupMaster = props.groupMaster;
    }
  }

  componentWillReceiveProps = (props) => {
    this.loadLookupData().then(() => {
      if (this.state.paramsid) {
        this.loadOrderFull(this.state.paramsid).then(() => {});
      }
    });
  };

  componentDidMount = () => {
    this.loadLookupData().then(() => {
      if (this.state.paramsid) {
        this.loadOrderFull(this.state.paramsid).then(() => {});
      }
    });
  };

  //FUNCTIONS
  checkIfLoading = () => {
    if (
      !this.state.order ||
      !this.state.instructions ||
      !this.state.producers ||
      !this.state.varieties ||
      !this.state.weeks ||
      !this.state.targets ||
      !this.state.classes ||
      !this.state.counts ||
      !this.state.coldrooms ||
      !this.state.palletspecs ||
      !this.state.brands ||
      !this.state.inventories ||
      !this.state.mgps ||
      !this.state.costestimations ||
      !this.state.estimatedcost
    ) {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false });
    }
  };

  loadOrderFull = async (id) => {
    await this.loadOrder(id).then(() => {
      this.loadInstructions(id).then(() => {
        this.checkIfLoading();
      });
    });
  };

  loadOrder = async (id) => {
    const result = await piorder.single(id).then((result) => {
      return { ...result, exworks: result.exworks ? "true" : "false", fixed: result.fixed ? "true" : "false", local: result.local ? "true" : "false" };
    });
    const variety = await this.state.varieties.find((vary) => vary.id == result.variety);

    const [resultProducers, resultPalletspecs, resultEstimatedCost] = await Promise.all([
      getActiveProducers(),
      palletSpecsByVarietyId(result.variety),
      estimatedcostsetupssummary(variety.code),
    ]);

    result.exchange = JSON.parse(result.exchange);
    this.setState({ order: result, producers: resultProducers, palletspecs: resultPalletspecs.data, variety: variety, estimatedcost: resultEstimatedCost.data });
  };

  loadInstructions = async (orderId) => {
    const result = [];
    await piinstructions.FindByOrderId(orderId).then((data) => {
      data.map((item, idx) => {
        if (!item.rowKey) {
          item.rowKey = idx + 1;
        }
        if (item.count[0] == "/") {
          item.count = item.count.substring(1);
        }
        result.push(item);
      });
    });

    this.setState(
      {
        instructions: result,
        instructionsUpdated: result,
      },
      () => {},
    );
    return result;
  };

  loadLookupData = async () => {
    const [resultVarieties, resultWeeks, resultTarget, resultClasses, resultCounts, resultColdrooms, resultBrands, resultInventories, resultMgps, resultCostestimations] =
      await Promise.all([
        varieties.all(),
        weeksOrdered(getFinancialYearSelected()),
        targetsSorted(),
        classes.all(),
        counts.all(),
        getAllActiveColdrooms(),
        getActiveBrands(),
        inventories.all(),
        mgps.all(),
        costestimations.all(),
      ]);
    this.setState(
      {
        weeks: resultWeeks.data,
        varieties: resultVarieties,
        targets: resultTarget,
        classes: resultClasses,
        counts: resultCounts,
        coldrooms: resultColdrooms,
        brands: resultBrands,
        inventories: resultInventories,
        mgps: resultMgps,
        costestimations: resultCostestimations,
        // estimatedcost: resultEstimatedCost.data,
      },
      () => {},
    );
  };

  // EVENTS
  onSubmit = (e, instructions, resequence = true) => {
    window.onbeforeunload = undefined;

    this.setState({ instructions: this.state.instructionsUpdated ? this.state.instructionsUpdated : this.state.instructions }, () => {
      const orderData = {
        data: {
          ordernum: e.ordernum,
          farm: e.farm,
          variety: e.variety,
          week: e.week,
          exchange: e.exchange,
          comment: e.comment || "",
          exworks: e.exworks,
          commission: e.commission,
          local: e.local,
          fixed: e.fixed,
          revision: e.revision,
          impala_comments: e.impala_comments || "",
        },
      };

      orderData.data.exchange.usd = numeral(orderData.data.exchange.usd).format("0.00").toString();
      orderData.data.exchange.cad = numeral(orderData.data.exchange.cad).format("0.00").toString();
      orderData.data.exchange.eur = numeral(orderData.data.exchange.eur).format("0.00").toString();
      orderData.data.exchange.gbp = numeral(orderData.data.exchange.gbp).format("0.00").toString();

      orderData.data.exchange = JSON.stringify(orderData.data.exchange);

      this.setState({ loading: true });

      getNextOrderNumber(this.state.weeks, this.state.varieties, orderData.data.farm, orderData.data.week, orderData.data.variety, e.id).then((result) => {
        orderData.data.ordernum = result;

        piorder
          .update(e.id, orderData)
          .then((data) => {
            this.state.instructions.map((ins, idx) => {
              delete ins.rowUpdated;

              ins.fob = numeral(ins.fob).format("0.00").toString();
              ins.rangemin = numeral(ins.rangemin).format("0.00").toString();
              ins.rangemax = numeral(ins.rangemax).format("0.00").toString();

              const id = ins.id;
              ins = CleanUpInstructionObject(ins);

              if (ins.count[0] == "/") {
                ins.count = ins.count.substring(1);
              }

              const rowKeyValue = resequence ? idx + 1 : ins.rowKey;
              const dataToUpdate = {
                data: { ...ins, rowKey: rowKeyValue },
              };

              if (id) {
                piinstructions.update(id, dataToUpdate).catch((error) => {
                  console.log("error->", error);
                });
              }
            });
          })
          .then(() => {
            if (e.groupnumber && this.state.groupMaster) {
              const grouporderData = {
                data: {
                  farm: orderData.data.farm,
                  week: orderData.data.week,
                  exchange: orderData.data.exchange,
                  comment: orderData.data.comment,
                  impala_comments: orderData.data.impala_comments,
                },
              };
              getOrdersByGroupNumber(e.groupnumber).then((resultByGroupNumber) => {
                if (resultByGroupNumber && resultByGroupNumber.data && resultByGroupNumber.data.length > 0) {
                  const promiseArr = [];
                  resultByGroupNumber.data.map((groupItem, index) => {
                    if (index != 0) {
                      promiseArr.push(
                        new Promise((res, rej) => {
                          piorder.update(groupItem.id, grouporderData).then(() => {
                            res("ok");
                          });
                        }),
                      );
                    }

                    Promise.all(promiseArr).then((result) => {
                      this.loadLookupData().then(() => {
                        this.loadOrderFull(e.id).then(() => {
                          if (this.state.refreshTabs) {
                            this.state.refreshTabs();
                          }
                        });
                      });
                    });
                  });
                }
              });
            } else {
              this.loadLookupData().then(() => {
                this.loadOrderFull(e.id).then(() => {
                  if (this.state.refreshTabs) {
                    this.state.refreshTabs();
                  }
                });
              });
            }
          });
      });
    });
  };

  // REMOVE DIALOG
  handleInstructionRemoveClose = () => {
    this.setState({ instructions: this.state.instructionsUpdated, confirmRemove: false, confirmRemoveId: undefined });
  };

  handleInstructionRemoveConfirm = async () => {
    const id = this.state.confirmRemoveId;
    const selectedRows = this.state.selectedRows;
    this.setState({ confirmRemove: false, confirmRemoveId: undefined, selectedRows: undefined });

    this.setState({ instructions: this.state.instructionsUpdated }, async () => {
      const promiseArr = [];
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.map(async (row) => {
          const selectedID = row;
          if (selectedID && selectedID != 0) {
            promiseArr.push(
              new Promise((res, rej) => {
                piinstructions
                  .remove(selectedID)
                  .then(() => {
                    res("ok");
                  })
                  .catch((err) => {
                    rej(err);
                  });
              }),
            );
          }
        });
      }
      Promise.all(promiseArr).then(() => {
        this.onSubmit({ ...this.state.order }, this.state.instructions);
      });
    });
  };

  handleInstructionRemove = (id, selectedRows) => {
    this.setState({ instructions: this.state.instructionsUpdated, confirmRemove: true, confirmRemoveId: id, selectedRows: selectedRows });
  };

  // COPY DIALOG
  handleInstructionCopy = async (selectedRows, neworder) => {
    let maxGroupNum = 0;
    let maxRowKey = 0;
    if (neworder) {
      this.state.instructionsUpdated.map((insitem) => {
        if (insitem.groupnum > maxGroupNum) {
          maxGroupNum = insitem.groupnum + 1;
        }
        if (insitem.rowKey > maxRowKey) {
          maxRowKey = insitem.rowKey;
        }
      });
    }

    this.setState({ instructions: this.state.instructionsUpdated }, () => {
      const promiseArr = [];
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.map(async (row, idx) => {
          const instructionLooped = this.state.instructions.find((ins) => ins.id == row);
          promiseArr.push(
            new Promise((res, rej) => {
              this.copyInstructionObj(instructionLooped, maxGroupNum, maxRowKey + idx)
                .then(() => {
                  res("ok");
                })
                .catch((err) => {
                  rej(err);
                });
            }),
          );
        });
      }

      Promise.all(promiseArr).then(() => {
        this.onSubmit({ ...this.state.order }, this.state.instructions, false);
      });
    });
  };

  copyInstructionObj = async (instruction, groupnum, rowkey) => {
    const dataToInsert = {
      data: { ...instruction, groupnum: groupnum == 0 ? instruction.groupnum : groupnum, rowKey: groupnum == 0 ? instruction.rowKey + 0.1 : rowkey },
    };
    dataToInsert.data = CleanUpInstructionObject(dataToInsert.data);
    if (groupnum == 0) {
      dataToInsert.data.groupline = 99;
    }

    await piinstructions.create(dataToInsert).then(() => {});
  };

  // ADD LINE BUTTON
  handleInstructionAdd = async (selectedindexes) => {
    const instArr = [...this.state.instructionsUpdated];

    let groupNum = 0;
    let rowKey = 0;

    if (selectedindexes.length > 0) {
      const findIns = instArr.find((insitem) => insitem.id == selectedindexes[0]);
      if (findIns) {
        groupNum = findIns.groupnum;
      }
      instArr.map((insitem, index) => {
        if (insitem.groupnum == groupNum) {
          rowKey = insitem.rowKey + 0.1;
        }
        if (insitem.groupnum > groupNum) {
          instArr[index].groupnum += 1;
        }
      });
    } else {
      instArr.map((insitem) => {
        if (insitem.groupnum >= groupNum) {
          groupNum = insitem.groupnum;
        }
        if (insitem.rowKey > rowKey) {
          rowKey = insitem.rowKey + 1;
        }
      });
    }
    groupNum += 1;

    const initialValueDummy = {
      data: {
        piorder_id: this.state.order.id,
        groupnum: groupNum,
        groupline: 99,
        volume: "ONBEPERK",
        class: "",
        count: "",
        countconstraint: "",
        coldroom: "",
        brand: "",
        inventory: 0,
        mgp: "",
        fob: 0,
        rowKey: Number(rowKey),
        tcgrouped: 1,
        conventional: 0,
      },
    };

    piinstructions.create(initialValueDummy).then((data) => {
      this.onSubmit({ ...this.state.order }, instArr, false);
    });
  };

  // LOAD TEMPLATE
  handleTemplateOpen = () => {
    this.setState({ showTemplateDialogue: true });
  };

  handleTogglePIHead = () => {
    this.setState({ showPIHead: !this.state.showPIHead });
  };

  handleTemplateClose = () => {
    this.setState({ showTemplateDialogue: false });
  };

  handleTemplateResponse = (data) => {
    this.setState({ showTemplateDialogue: false });
    if (data && data.length > 0) {
      this.setState({ loading: true });

      let difference = 0;
      data.map((item) => {
        const dataToAdd = { data: { ...item } };

        dataToAdd.data["piorder_id"] = this.state.order.id;
        dataToAdd.data["tcgrouped"] = 1;

        if (this.state.instructions && this.state.instructions.length > 0) {
          difference = Number(this.state.instructions[this.state.instructions.length - 1].groupnum);
        }
        dataToAdd.data["groupnum"] = Number(dataToAdd.data["groupnum"]) + difference;

        const cleanDataToAdd = CleanUpTemplateObject(dataToAdd);

        piinstructions.create(cleanDataToAdd).then((data) => {
          this.loadInstructions(this.state.order.id).then(() => {
            this.setState({
              loading: false,
              headerdirty: false,
            });
          });
        });
      });
    }
  };

  handleSetExchange = (exchangenew) => {
    this.setState({ order: { ...this.state.order, exchange: exchangenew } }, () => {});
  };

  onInstructionsDataChange = (instructions) => {
    this.setState({ instructionsUpdated: instructions });
  };

  handleCreateGroup = () => {
    this.setState({ confirmCreateGroup: true });
  };

  handleCreateGroupClose = () => {
    this.setState({ confirmCreateGroup: undefined });
  };

  handleCreateGroupConfirm = () => {
    const _guid = guid();
    const dataToUpdate = {
      data: {
        groupnumber: _guid,
        groupsequence: 1,
      },
    };

    piorder.update(this.state.order.id, dataToUpdate).then(() => {
      this.setState({ redirectTabbed: `/instructiontabbed/${_guid}/edit`, confirmCreateGroup: undefined });
    });
  };

  handleResetData = () => {
    this.setState({ loading: true });
    this.loadOrderFull(this.state.paramsid).then(() => {
      if (this.state.refreshTabs) {
        this.state.refreshTabs();
        this.setState({ loading: false });
      }
    });
  };

  handleSaveLines = () => {
    this.setState({ instructions: this.state.instructionsUpdated ? this.state.instructionsUpdated : this.state.instructions }, () => {
      this.onSubmit({ ...this.state.order }, this.state.instructions, false);
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <p>Loading</p>
          <CircularProgress />
        </div>
      );
    } else if (this.state.redirect) {
      return <Redirect to="/orders" />;
    } else if (this.state.redirectTabbed) {
      return <Redirect to={this.state.redirectTabbed} />;
    } else if (this.state.showTemplateDialogue) {
      return (
        <TemplatesDialog
          isOpen={true}
          title={"templates"}
          handleClose={this.handleTemplateClose}
          handleConfirm={this.handleTemplateResponse}
          varietyid={this.state.order.variety}
        />
      );
    } else {
      return (
        <div>
          {this.state.confirmRemove && (
            <Confirmation
              isOpen={this.state.confirmRemove}
              handleClose={this.handleInstructionRemoveClose}
              handleConfirm={this.handleInstructionRemoveConfirm}
              title="Remove selected instruction?"
              body="Are you sure you want to REMOVE the selected instruction?"
            ></Confirmation>
          )}
          {this.state.confirmCreateGroup && (
            <Confirmation
              isOpen={true}
              handleClose={this.handleCreateGroupClose}
              handleConfirm={this.handleCreateGroupConfirm}
              title="Create Grouped Instruction?"
              body="Are you sure you want to create a grouped instruction?"
            ></Confirmation>
          )}
          {!this.state.loading && (
            <InstructionEdit
              onSubmit={this.onSubmit}
              onInstructionsDataChange={this.onInstructionsDataChange}
              handleInstructionAdd={this.handleInstructionAdd}
              handleInstructionRemove={this.handleInstructionRemove}
              handleInstructionCopy={this.handleInstructionCopy}
              handleTemplateOpen={this.handleTemplateOpen}
              handleSetExchange={this.handleSetExchange}
              handleCreateGroup={this.handleCreateGroup}
              handleResetData={this.handleResetData}
              handleSaveLines={this.handleSaveLines}
              handleTogglePIHead={this.handleTogglePIHead}
              showPIHead={this.state.showPIHead}
              order={this.state.order}
              instructions={this.state.instructions}
              producers={this.state.producers}
              varieties={this.state.varieties}
              variety={this.state.variety}
              weeks={this.state.weeks}
              targets={this.state.targets}
              classesdata={this.state.classes}
              counts={this.state.counts}
              coldrooms={this.state.coldrooms}
              palletspecs={this.state.palletspecs}
              brands={this.state.brands}
              inventories={this.state.inventories}
              mgps={this.state.mgps}
              costestimations={this.state.costestimations}
              headerdirty={this.state.headerdirty}
              estimatedcost={this.state.estimatedcost}
              groupMaster={this.state.groupMaster}
            />
          )}
        </div>
      );
    }
  }
}

export default InstructionContainer;

const CleanUpInstructionObject = (ObjectToClean) => {
  delete ObjectToClean["id"];
  delete ObjectToClean["instruction_id"];
  delete ObjectToClean["isDirty"];
  delete ObjectToClean["region"];
  delete ObjectToClean["market"];
  delete ObjectToClean["country"];
  delete ObjectToClean["currency"];
  delete ObjectToClean["costestimation_id"];
  delete ObjectToClean["description"];
  delete ObjectToClean["code"];
  delete ObjectToClean["carton"];
  delete ObjectToClean["palletspec_name"];
  delete ObjectToClean["inventory_description"];
  delete ObjectToClean["inventory_code"];
  delete ObjectToClean["palletspec_carton"];
  delete ObjectToClean["palletspec_code"];
  delete ObjectToClean["port_id"];

  return ObjectToClean;
};

const CleanUpTemplateObject = (objectToClean) => {
  delete objectToClean.data["market"];
  delete objectToClean.data["region"];
  delete objectToClean.data["country"];
  delete objectToClean.data["code"];
  delete objectToClean.data["carton"];
  delete objectToClean.data["palletSpecCode"];
  delete objectToClean.data["palletSpecCarton"];
  delete objectToClean.data["palletSpec"];
  delete objectToClean.data["template_id"];
  delete objectToClean.data["palletspec_name"];
  delete objectToClean.data["currency"];
  delete objectToClean.data["id"];
  delete objectToClean.data["templatename"];
  delete objectToClean.data["inventory_description"];
  delete objectToClean.data["inventory_code"];
  delete objectToClean.data["palletspec_code"];
  delete objectToClean.data["port_id"];

  return objectToClean;
};
