import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import IconAdd from "@material-ui/icons/Add";

import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Grid from "../../lib/components/grid";
import { DialogInformation } from "../../lib/components/dialoginformation";
import Confirmation from "../../lib/components/confirmation";

import { getSaleFundsFull, SaleFundsFullType, SaleFunds, SaleFundsType, getSaleFundById } from "../../lib/api/salefunds";
import { clientsByCode } from "../../lib/api/clients";

import { GridColumns } from "./fundsgridsetup";
import FundsEditForm from "./fundseditform";
import Allocation from "./allocation/allocation";
import Statement from "./statement/statement";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

import numeral from "numeral";
import { getFinancialYearSelected } from "../../lib/api/week";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";
import { SaleFundsAdhoc, SaleFundsAdhocType } from "../../lib/api/salefundsadhoc";

import AllocationTable from "./allocationtable";
import { Row } from "react-data-grid";

import OverpaidFunds from "./overpaidfunds/index";
import { allocationsExistBySalefundsId } from "../../lib/api/salefundsallocation";
import { AllocationSummary } from "./allocationsummary";
import { getOutstandingSummary } from "../../lib/api/debtorsfull";
import { OutstandingSummaryGrid } from "./outstanding/grid";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} style={{ height: "100%" }}>
      {value === index && (
        <Box p={3} style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>{children}</div>
        </Box>
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 100px)",
      width: "100%",
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "60px",
      width: "100%",
    },
    mainSplitterDiv: {
      height: "100%",
      width: "100%",
      marginTop: "0px",
      display: "grid",
    },
    toolbarChild: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type DebtorsFullProps = {
  match: any;
  history: any;
} & WithStyles<typeof styles>;

export class DebtorsFullUnstyled extends React.Component<DebtorsFullProps, any> {
  state = {
    classes: undefined,
    data: [],
    loading: true,
    message: undefined,
    selectedRecord: undefined,
    selectedRecordAllocate: undefined,
    minGridHeight: 0,
    minGridHeightSecond: 0,
    clients_code: "",
    statement: false,
    clients_name: "",
    confirmRemoveAdhoc: undefined,
    confirmRemoveSaleFund: undefined,
    allocationSaleFundsID: undefined,
    tabValue: 0,
    allocationsExists: undefined,
    outstandingData: [],
    outstandingDataLoading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.clients_code = props.match.params.clients_code;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount = async () => {
    await Promise.all([this.loadOutstandingSummary(), this.loadData()]);
  };

  loadOutstandingSummary = async () => {
    try {
      this.setState({ outstandingDataLoading: true });
      const result = await getOutstandingSummary(this.props.match.params.clients_code);
      this.setState({ outstandingData: result });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error loading Outstanding data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ outstandingDataLoading: false });
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const result = await clientsByCode(this.state.clients_code);

      if (result.data.length > 0) {
        this.setState({ clients_name: result.data[0].name });
      }

      const [_, saleFundId] = (this.props.history.location.search || "").split("salefunds_id=");

      if (saleFundId) {
        const fullResult = await getSaleFundsFull(saleFundId);
        this.setState({ selectedRecordAllocate: { ...fullResult.data[0] } });
      } else {
        const clientid = result && result.data && result.data.length > 0 ? result.data[0].id : 0;
        const fullResult = await getSaleFundsFull(undefined, clientid, getFinancialYearSelected());

        if (fullResult && fullResult.data) {
          const dataSorted = fullResult.data.sort((a, b) => a.clients_name.localeCompare(b.clients_name));
          this.setState({ data: dataSorted });
        }
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error retrieving client data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setGridHeights();
    this.setState({ loading: false });
  };

  handleCreate = async () => {
    try {
      const result = await clientsByCode(this.state.clients_code);
      this.setState({
        selectedRecordAllocate: {
          id: 0,
          clients_id: result.data[0].id,
          clients_name: result.data[0].name,
          clients_code: this.state.clients_code,
          currency_id: result.data[0].currency_id,
          companydetailsbanking_id: result.data[0].companydetailsbanking_id,
        },
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleEdit = (selectedRecord: SaleFundsFullType) => {
    this.setState({ selectedRecord: { ...selectedRecord } });
  };

  handleProcessAdhoc = async (data: SaleFundsFullType) => {
    if (data) {
      this.setState({ loading: true });

      const dataToUpdate: { data: SaleFundsAdhocType } = {
        data: {
          clients_id: data.clients_id,
          amount: parseFloat(numeral(data.amount).format("0.000")),
          makeanote: data.makeanote,
          posteddate: data.posteddate ? data.posteddate : new Date(),
          currency_id: data.currency_id,
        },
      };

      if (data.salefundsadhoc_id && data.salefundsadhoc_id != 0) {
        await SaleFundsAdhoc.update(data.salefundsadhoc_id, dataToUpdate).catch((error) => {
          const err = GenerateErrorMessage(error, "failed to update");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
      } else {
        await SaleFundsAdhoc.create(dataToUpdate).catch((error) => {
          const err = GenerateErrorMessage(error, "failed to create");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
      }
    }
    await this.loadData();
    this.setState({ selectedRecord: undefined, selectedRecordAllocate: undefined, allocationSaleFundsID: undefined });
  };

  handleCloseForm = async () => {
    const list: string[] = (this.props.history.location.pathname || "").split("/");
    this.props.history.push(`/debtors/funds/${list.at(-1)}`);
  };

  handleProcessSaleFunds = async (data: SaleFundsFullType) => {
    this.setState({ loading: true });
    if (data) {
      let rowId = data.id;
      const dataToUpdate: { data: SaleFundsType } = {
        data: {
          clients_id: data.clients_id,
          amount: parseFloat(numeral(data.amount).format("0.000")),
          currency_id: data.currency_id,
          exchangerate: data.exchangerate,
          makeanote: data.makeanote,
          bankcharges: parseFloat(numeral(data.bankcharges).format("0.000")),
          companydetailsbanking_id: data.companydetailsbanking_id,
          posteddate: data.posteddate ? data.posteddate : new Date(),
          allocated_year: data.allocated_year,
          status: 0,
        },
      };

      if (data.id && data.id != 0) {
        await SaleFunds.update(data.id, dataToUpdate).catch((error) => {
          const err = GenerateErrorMessage(error, "failed to update");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
      } else {
        const result = await SaleFunds.create(dataToUpdate).catch((error) => {
          const err = GenerateErrorMessage(error, "failed to create");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });

        rowId = result[0];
      }

      try {
        // allocate or close
        if (data.allocate) {
          const [result] = await getSaleFundById(rowId);
          this.setState({ selectedRecord: undefined, selectedRecordAllocate: { ...result }, loading: false });
        } else {
          this.handleCloseForm();
        }
      } catch (error) {
        this.handleCloseForm();
      }
    } else {
      this.handleCloseForm();
    }
  };

  handleRemoveFundEntry = (row: SaleFundsFullType) => {
    if (row.salefundsadhoc_id) {
      this.setState({ confirmRemoveAdhoc: row.salefundsadhoc_id });
    } else {
      this.setState({ confirmRemoveSaleFund: row.id });
    }
  };

  handleInformationClose = () => {
    this.setState({ message: undefined });
  };

  handleAllocate = (data) => {
    this.setState({ selectedRecordAllocate: { ...data } });
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    if (primarysplit[0]) {
      this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 70, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 100 });
    }
  };

  handleEditAllocation = (selectedRecord: any) => {
    this.setState({ selectedRecord: selectedRecord, selectedRecordAllocate: undefined });
  };

  handleGoToSummary = () => {
    this.props.history.push("/debtors/fundssummary");
  };

  handleStatement = (accept: boolean = false) => {
    this.setState({ statement: accept });
  };

  handleGenerateEdiFile = () => {
    window.location.href = `/api/salefunds/ext/exportDebtorsFundsHistory?clients_code=${this.props.match.params.clients_code}&finyear=${getFinancialYearSelected()}`;
  };

  handleConfirmRemove = async () => {
    try {
      this.setState({ loading: true });

      const allocationsList = await allocationsExistBySalefundsId(this.state.confirmRemoveSaleFund);

      if (allocationsList.length > 0) {
        this.setState({ allocationsExists: true });
        return;
      }

      if (this.state.confirmRemoveAdhoc) {
        await SaleFundsAdhoc.remove(this.state.confirmRemoveAdhoc);
      } else {
        await SaleFunds.remove(this.state.confirmRemoveSaleFund);
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing payment. Please try again");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    await this.loadData();

    this.setState({
      selectedRecord: undefined,
      selectedRecordAllocate: undefined,
      allocationSaleFundsID: undefined,
      loading: false,
      confirmRemoveAdhoc: undefined,
      confirmRemoveSaleFund: undefined,
    });
  };

  handleConfirmRemoveClose = () => {
    this.setState({
      selectedRecord: undefined,
      selectedRecordAllocate: undefined,
      allocationSaleFundsID: undefined,
      loading: false,
      confirmRemoveAdhoc: undefined,
      confirmRemoveSaleFund: undefined,
    });
  };

  menuItems = [
    {
      title: "STATEMENT",
      icon: DocumentsType.DOCUMENT,
      action: () => this.handleStatement(true),
    },
    {
      title: "PURCHASE AND PAYMENT HISTORY",
      action: this.handleGenerateEdiFile,
      icon: DocumentsType.EXCEL,
    },
  ];

  handleViewAllocations = (salefunds_id: number | undefined) => {
    this.setState({ allocationSaleFundsID: salefunds_id });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const rowStyle = this.state.allocationSaleFundsID == row.id ? classes.boldRow : classes.normalRow;
    return <Row {...props} onDoubleClick={() => this.handleViewAllocations(row.id)} className={rowStyle} />;
  };

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  handleCloseAllocationExistDialog = () => {
    this.setState({
      allocationsExists: false,
      selectedRecord: undefined,
      selectedRecordAllocate: undefined,
      allocationSaleFundsID: undefined,
      loading: false,
      confirmRemoveAdhoc: undefined,
      confirmRemoveSaleFund: undefined,
    });
  };

  render() {
    const { classes } = this.state;

    if (this.state.loading) return <LinearProgress color="secondary" />;

    return (
      <div className={classes.root}>
        {(this.state.confirmRemoveAdhoc || this.state.confirmRemoveSaleFund) && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleConfirmRemoveClose}
            handleConfirm={this.handleConfirmRemove}
            title={`Confirmation`}
            body={`Are you sure you want to remove this payment?`}
          ></Confirmation>
        )}
        {this.state.statement && (
          <Confirmation
            isOpen={true}
            handleClose={() => this.handleStatement(false)}
            handleConfirm={() => this.handleStatement(false)}
            title={`STATEMENT SUMMARY - ${this.state.clients_name}`}
            body={undefined}
          >
            <Statement clientscode={this.state.clients_code} onClose={this.handleStatement} />
          </Confirmation>
        )}
        {this.state.allocationsExists && (
          <Confirmation
            isOpen={true}
            handleClose={this.handleCloseAllocationExistDialog}
            handleConfirm={this.handleCloseAllocationExistDialog}
            title={`Error - Allocation exists.`}
            body={`Funds have been allocated to a shipment(s). Please remove the Allocation(s) before deleting.`}
            buttonCaptionObject={{ no: "cancel", yes: "ok" }}
          ></Confirmation>
        )}
        {!this.state.selectedRecord && !this.state.selectedRecordAllocate && (
          <div style={{ height: "100%" }}>
            <AppBar position="static">
              <Tabs value={this.state.tabValue} onChange={this.handleChangeTab} aria-label="simple tabs example">
                <Tab label="Funds Received" {...a11yProps(0)} />
                <Tab label="Allocations" {...a11yProps(1)} />
                <Tab label="Outstanding" {...a11yProps(2)} />
                <Tab label="Overpaid" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabValue} index={0}>
              <div id="main_splitter_div" className={classes.mainSplitterDiv}>
                <Splitter
                  position="horizontal"
                  primaryPaneMaxHeight="calc(100% - 300px)"
                  primaryPaneWidth="100%"
                  dispatchResize={true}
                  postPoned={true}
                  onDragFinished={this.setGridHeights}
                >
                  <div id="primary_grid">
                    <Toolbar className={classes.toolbar}>
                      <div className={classes.toolbarChild}>
                        <Tooltip style={{ zIndex: 0 }} title="Go back to summary">
                          <Button variant="contained" color="secondary" onClick={this.handleGoToSummary}>
                            Back
                          </Button>
                        </Tooltip>
                        <Tooltip style={{ zIndex: 0 }} title="Create new Fund">
                          <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ gap: "5px" }}>
                            <IconAdd />
                            <span>Create Fund</span>
                          </Button>
                        </Tooltip>
                      </div>
                      <DocumentsButton menuItems={this.menuItems} disabled={this.state.loading} />
                    </Toolbar>
                    <Grid
                      forceHeight={this.state.minGridHeight}
                      loading={this.state.loading}
                      data={this.state.data}
                      GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                        GridColumns(data, filters, columnArrangement, columnsWidth, this.handleEdit, this.handleRemoveFundEntry, this.handleAllocate)
                      }
                      handleRefresh={() => this.loadData()}
                      clearFilters={"debtorsfunds"}
                      totalRowColumns={["amount", "bankcharges", "totalamount", "available"]}
                      rowRenderer={(props) => this.rowRenderer(props, classes)}
                    />
                  </div>
                  <div id="secondary_grid" style={{ height: this.state.minGridHeightSecond }}>
                    {this.state.allocationSaleFundsID && <AllocationTable salefunds_id={this.state.allocationSaleFundsID} gridHeight={this.state.minGridHeightSecond} />}
                  </div>
                </Splitter>
              </div>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              <AllocationSummary clients_code={this.state.clients_code} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              <OutstandingSummaryGrid data={this.state.outstandingData} loading={this.state.outstandingDataLoading} />
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={3}>
              <OverpaidFunds history={this.props.history} clients_code={this.props.match.params.clients_code} />
            </TabPanel>
          </div>
        )}
        {this.state.selectedRecord && <FundsEditForm selectedRecord={this.state.selectedRecord} handleProcess={this.handleProcessAdhoc} />}
        {this.state.selectedRecordAllocate && (
          <Allocation
            history={this.props.history}
            handleEditAllocation={this.handleEditAllocation}
            selectedRecord={this.state.selectedRecordAllocate}
            handleProcessSaleFunds={this.handleProcessSaleFunds}
          />
        )}
        {this.state.message && (
          <DialogInformation
            isOpen={true}
            handleClose={this.handleInformationClose}
            handleOK={this.handleInformationClose}
            title={"Feedback"}
            body={`${this.state.message}`}
            showinput={false}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DebtorsFullUnstyled);
