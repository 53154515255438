import React, { useMemo, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

import { Form, Field } from "react-final-form";
import { TextField, Select, Checkbox } from "final-form-material-ui";

import numeral from "numeral";

import { EstimatedCostsData, estimatedcostsFull } from "../../../lib/api/estimatedcost";
import { commoditiesAllSortedMappedforCombo } from "../../../lib/api/commodity";
import { MaterialSelectComponent } from "../../../lib/helpers/materialcomponents";
import Confirmation from "../../../lib/components/confirmation";
import { targetsDistinctMarkets } from "../../../lib/api/target";
import { getPortsLoadingForCombo, Port } from "../../../lib/api/port";
import { duplicateCheck } from "./estimatedcostssetup";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    checkboxRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      width: "200px",
      textAlign: "right",
    },
  });

type EstimatedCostsSetupHeadProps = {
  currentHead: EstimatedCostsData;
  allData: any[];
  isDuplicate: boolean;
  setIsDuplicate(value: boolean): void;
  submitHeadData(values: any): Promise<number>;
  palletSpecId: number;
} & WithStyles<typeof styles>;

const EstimatedCostsSetupHeadUnstyled: React.FunctionComponent<EstimatedCostsSetupHeadProps> = (props) => {
  const { classes, currentHead, allData, setIsDuplicate, isDuplicate, submitHeadData, palletSpecId } = props;
  const style = {
    tableCell: { borderWidth: "1px", paddingLeft: "2px", paddingRight: "2px", borderColor: "black", borderStyle: "solid", width: "200px" },
    tableCellValue: { borderWidth: "1px", paddingLeft: "2px", paddingRight: "2px", borderColor: "black", borderStyle: "solid", width: "100px" },
  };

  const [commodities, setCommodities] = useState([]);
  const [editing, setEditing] = useState(false);
  const [targets, setTargets] = useState([]);
  const [closeDialog, setCloseDialog] = useState(false);
  const [portsLoading, setPortsLoading] = useState<{ value: number; display: string; data: Port }[]>([]);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(currentHead);

  const { updateSnack } = React.useContext(SnackContext);

  const reloadData = async () => {
    setLoading(true);
    try {
      const { data } = await estimatedcostsFull(currentHead.id);
      setData({ ...data[0], original_id: data[0].id });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error reloading data. Please try again");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const loadData = async () => {
    const [resultCommodities, resultTargets, resultPortsLoading] = await Promise.all([commoditiesAllSortedMappedforCombo(), targetsDistinctMarkets(), getPortsLoadingForCombo()]);
    setTargets(resultTargets);
    setCommodities(resultCommodities);
    setPortsLoading(resultPortsLoading);
  };

  const handleSave = async (values: any) => {
    setCloseDialog(false);
    await submitHeadData(values);
    setReload(true);
    switchEditing();
  };

  const switchEditing = () => {
    setEditing(!editing);
    setReload(false);
  };

  const handleBackdropClick = () => {
    setCloseDialog(true);
  };

  useEffect(() => {
    if (reload) {
      reloadData();
    }
  }, [reload]);

  useEffect(() => {
    const doesExists = duplicateCheck(data, allData, palletSpecId);
    setIsDuplicate(!!doesExists);
  }, [data, palletSpecId]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {data && (
        <div>
          <table style={{ width: "98%" }}>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Season: </td>
                <td style={{ fontWeight: "bold", width: "59%" }}>{data.season}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Market: </td>
                <td style={{ ...style.tableCellValue }}>{data.market}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Commodity: </td>
                <td style={{ ...style.tableCellValue }}>{data.commodity}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Exchange USD: </td>
                <td style={{ ...style.tableCellValue }}>{numeral(data.exchange_usd).format("0.00")}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Exchange CAD: </td>
                <td style={{ ...style.tableCellValue }}>{numeral(data.exchange_cad).format("0.00")}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Exchange EUR: </td>
                <td style={{ ...style.tableCellValue }}>{numeral(data.exchange_eur).format("0.00")}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Exchange GBP: </td>
                <td style={{ ...style.tableCellValue }}>{numeral(data.exchange_gbp).format("0.00")}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Exchange ZAR: </td>
                <td style={{ ...style.tableCellValue }}>{numeral(data.exchange_zar).format("0.00")}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Port of Loading: </td>
                <td style={{ ...style.tableCellValue }}>{data.port}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Container: </td>
                <td style={{ ...style.tableCellValue }}>{data.container == 1 ? "TRUE" : "FALSE"}</td>
              </tr>
              <tr>
                <td style={{ ...style.tableCell }}>Conventional: </td>
                <td style={{ ...style.tableCellValue }}>{data.conventional == 1 ? "TRUE" : "FALSE"}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ paddingTop: "5px", marginRight: "9px", display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={switchEditing} disabled={loading}>
              edit
            </Button>
          </div>
          <p style={{ color: "red" }}>{isDuplicate ? "A record with this data has been copied. Please make the necessary changes." : ""}</p>
        </div>
      )}
      {editing && (
        <Confirmation
          isOpen={true}
          handleClose={switchEditing}
          handleConfirm={switchEditing}
          title={"Estimated Costs Head"}
          body={undefined}
          handleBackdropClick={handleBackdropClick}
        >
          <EstimatedCostsSetupHeadEditUnstyled
            classes={classes}
            targets={targets}
            currentHead={data}
            submitData={handleSave}
            commodities={commodities}
            market={"market"}
            allData={allData}
            closeDialog={closeDialog}
            setCloseDialog={setCloseDialog}
            handleSave={handleSave}
            palletSpecId={palletSpecId}
            portsLoading={portsLoading}
          />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupHeadUnstyled);

type EstimatedCostsSetupHeadEditProps = {
  currentHead: any;
  submitData: any;
  commodities: any;
  market: any;
  targets: any;
  allData: any;
  classes: any;
  closeDialog: boolean;
  palletSpecId: number;
  portsLoading: { value: number; display: string; data: Port }[];
  setCloseDialog(value: boolean): void;
  handleSave(value: any): void;
};

const EstimatedCostsSetupHeadEditUnstyled: React.FunctionComponent<EstimatedCostsSetupHeadEditProps> = (props) => {
  const { currentHead, submitData, commodities, allData, classes, closeDialog, setCloseDialog, handleSave, palletSpecId, portsLoading } = props;
  const [loading, setLoading] = useState(false);

  const ports = useMemo(() => (currentHead.port_id ? currentHead.port_id.split(",").map((item: string) => parseInt(item)) : []), [currentHead.port_id]);
  const markets = useMemo(() => (currentHead.market ? currentHead.market.split(",") : []), [currentHead.market]);

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={{
        ...currentHead,
        palletspec_id: palletSpecId,
        market: markets,
        port_id: ports,
      }}
      onSubmit={(values) => {
        setLoading(true);
        submitData(values);
        setLoading(false);
      }}
      render={({ values, handleSubmit, form, dirty }: any) => (
        <HeadForm
          classes={classes}
          values={values}
          targets={props.targets}
          commodities={commodities}
          handleSubmit={handleSubmit}
          allData={allData}
          loading={loading}
          form={form}
          dirty={dirty}
          closeDialog={closeDialog}
          setCloseDialog={setCloseDialog}
          handleSave={handleSave}
          portsLoading={portsLoading}
        />
      )}
    />
  );
};

type HeadFormProps = {
  targets: any;
  values: any;
  commodities: any;
  allData: any[];
  classes: any;
  loading: boolean;
  form: any;
  dirty: boolean;
  closeDialog: boolean;
  portsLoading: { value: number; display: string; data: Port }[];
  handleSubmit(data: any): void;
  setCloseDialog(value: boolean): void;
  handleSave(value: any): void;
};

const HeadForm: React.FC<HeadFormProps> = ({ targets, handleSubmit, values, commodities, allData, classes, loading, form, dirty, closeDialog, handleSave, portsLoading }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    const doesExists = duplicateCheck(values, allData, values.palletspec_id);
    setIsDuplicate(!!doesExists);
  }, [values, allData]);

  const handleChange = (value: string, name: string) => value;

  useEffect(() => {
    if (!values.container && !values.conventional) {
      setSubmittable(false);
    } else {
      setSubmittable(true);
    }
  }, [values]);

  const handleClose = () => {
    handleSave(undefined);
  };

  const handleConfirm = () => {
    handleSubmit(values);
  };

  const openDialog = useMemo(() => {
    if (closeDialog && dirty && !isDuplicate) {
      return true;
    } else if (closeDialog && !dirty) {
      handleClose();
      return false;
    }
    return false;
  }, [dirty, closeDialog]);

  return (
    <form style={{ maxWidth: "220px" }} onSubmit={handleSubmit}>
      <table>
        <tbody>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="season" component={TextField} type="text" label="Season" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "left" }}>
                <FormHelperText>Commodity</FormHelperText>
                <Field name="commodity" component={Select}>
                  {commodities.map((commItem) => {
                    return <MenuItem value={commItem.value}>{commItem.display}</MenuItem>;
                  })}
                </Field>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <FormHelperText>Market</FormHelperText>
                <Field
                  multiple
                  name="market"
                  component={MaterialSelectComponent}
                  label={"Market"}
                  style={{ width: "100%", textAlign: "left" }}
                  childdata={(targets.data || []).map((target) => ({ value: target.market, display: target.market }))}
                ></Field>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <FormHelperText>Port of Loading</FormHelperText>
                <Field
                  multiple
                  name="port_id"
                  component={MaterialSelectComponent}
                  label={"Port of Loading"}
                  style={{ width: "100%", textAlign: "left" }}
                  childdata={portsLoading}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="exchange_usd" component={TextField} type="text" label="Exchange USD" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="exchange_cad" component={TextField} type="text" label="Exchange CAD" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="exchange_eur" component={TextField} type="text" label="Exchange EUR" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="exchange_gbp" component={TextField} type="text" label="Exchange GBP" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ width: "200px", textAlign: "right" }}>
                <Field fullWidth name="exchange_zar" component={TextField} type="text" label="Exchange ZAR" margin="dense" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={classes.checkboxRow}>
                <FormHelperText>CONTAINER</FormHelperText>
                <Field fullWidth name="container" component={Checkbox} type="checkbox" label="CONTAINER" margin="dense" parse={handleChange} />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={classes.checkboxRow}>
                <FormHelperText>CONVENTIONAL</FormHelperText>
                <Field fullWidth name="conventional" component={Checkbox} type="checkbox" label="CONVENTIONAL" margin="dense" parse={handleChange} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p style={{ color: "red" }}>{!submittable && "Either CONVETIONAL or CONTAINER must be selected."}</p>
      <p style={{ color: "red" }}>{isDuplicate && "Please make the necessary changes"}</p>
      <div style={{ paddingTop: "5px", float: "left" }}>
        <Button type="submit" variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} disabled={isDuplicate || loading || !submittable}>
          save
        </Button>
      </div>
      <Confirmation isOpen={openDialog} handleClose={handleClose} handleConfirm={handleConfirm} body="Do you want to save your changes?" title="Unsaved changes" />
    </form>
  );
};
