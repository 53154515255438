import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import { stock, StockType, allocateStock } from "../lib/api/stock";
import { loadoutByReferenceAndFinYear } from "../lib/api/loadout";
import { countStockDetaildistinctReferencesSellingNull, stockDetailUpdatePricingByReferenceAnd, unallocateSelectedStock } from "../lib/api/stockdetail";

import StockAllocationTable from "./stockallocationtable";
import StockPricingUpdateGrid from "../stockpricing/stockpricingupdategrid";
import StockPricing from "../stockpricing/stockpricing";
import Loadout from "../loadout/loadout";

import Confirmation from "../lib/components/confirmation";
import { getStockGroupedByReference } from "../lib/api/stock";

import { SnackContext } from "../lib/context/SnackContext";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      height: "calc(100% - 65px)",
    },
  });

type StockAllocationProps = {
  stock: StockType[];
} & WithStyles<typeof styles>;

class StockAllocationUnstyled extends React.Component<StockAllocationProps> {
  state = {
    stock: [],
    loading: true,
    selectedData: undefined,
    selectedUpdating: false,
    clients: undefined,
    weeks: undefined,
    temperaturecodes: undefined,
    unAllocateConfirm: false,
    referenceBatchesCount: 0,
    pricingBatchReferences: [{}],
    pricingBatchOpen: false,
    pricingBatchData: [{}],
    pricingBatchesOpen: false,
    classes: undefined,
    loadoutForwardReference: undefined,
    allocating: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadStock().then(() => {
      this.handleTitle(false);
    });
  }

  loadStock = async () => {
    this.setState({ loading: true });
    const [stockData, loadReferenceBatches] = await Promise.all([stock.all(), countStockDetaildistinctReferencesSellingNull()]);
    const sorted = stockData.sort((a, b) => parseInt(b.age || "0") - parseInt(a.age || "0"));
    this.setState({ stock: sorted, loading: false, referenceBatchesCount: loadReferenceBatches.length });
  };

  handleUpdateStock = async () => {
    const [stockData, loadReferenceBatches] = await Promise.all([stock.all(), countStockDetaildistinctReferencesSellingNull()]);
    this.setState({ stock: stockData, referenceBatchesCount: loadReferenceBatches.length });
    return stockData;
  };

  onReload = async () => {
    this.setState({ allocating: false, loading: true });
    return await this.loadStock();
  };

  onAccept = (data) => {
    if (data.length > 0) {
      this.setState({ selectedData: data, selectedUpdating: true });
    }
  };

  onAcceptProcess = async (data, rows = undefined) => {
    this.setState({ allocating: true });

    try {
      const { actionpricing, actionloadout, reference } = data;
      const dataWIP = rows || this.state.selectedData;

      let loadout = dataWIP.find((item: StockType) => item.reference == data.reference);

      // No loadout found, either a new loadout or the loadout exists in the db but the user hasn't selected that stock
      if (!loadout) {
        // check if loadout exists for the reference
        // Why use financial year in the search? There could be multiple records with the same reference but different year
        const loadoutExists = await loadoutByReferenceAndFinYear(reference);

        if (loadoutExists && loadoutExists.data && loadoutExists.data.length > 0) {
          loadout = loadoutExists.data[0];
        }
      } else {
        loadout = { ...loadout, id: loadout.loadout_id };
      }

      const loadoutDetail = {
        id: loadout ? loadout.id : null,
        reference: data.reference,
        exportNotificationReference: data.loadout_exportNotificationReference || null,
        voyageNumber: data.loadout_voyageNumber || null,
        portLoading_id: data.loadout_portLoading_id || null,
        portDischarge_id: data.loadout_portDischarge_id || null,
        portFinal_id: data.loadout_portFinal_id || null,
        shipping_id: data.loadout_shippingline_id || null,
        vessel_id: data.vessel_id || null,
        dateLoading: data.loadout_dateLoading || null,
        specialinstruction: data.loadout_specialinstruction || null,
        coldroom_id: data.loadout_coldroom_id || null,
        agent_id: data.loadout_agent_id || null,
        etd: data.loadout_etd || null,
        eta: data.loadout_eta || null,
        phytclean: data.loadout_phytclean || null,
        consignee: data.client,
      };

      const batchData = dataWIP.map((item) => ({
        barcode_id: item.barcode_id,
        barcode: item.barcode,
        client: data.client,
        shipmentweek_id: data.shipmentweek_id,
        destination: data.destination,
        ponumber: data.ponumber,
        reference: data.reference,
        temperaturecode: data.temperaturecode,
        username: data.username,
        allocated: 0,
        preallocated: 1,
        commodityCode: item.commodityCode,
        varietyCode: item.varietyCode,
        gradeCode: item.gradeCode,
        targetMarket: item.targetMarket,
        targetCountry: item.targetCountry,
        countCode: item.countCode,
        packCode: item.packCode,
        markCode: item.markCode,
        dealtype: data.dealtype,
        vessel_id: data.vessel_id,
      }));

      const loadoutBatchData = batchData.reduce((arr, item) => {
        if (!arr.find((row) => row.barcode == item.barcode)) {
          arr.push({ barcode: item.barcode, loadout_id: loadout ? loadout.id : null });
        }
        return arr;
      }, []);

      await allocateStock(loadoutDetail, batchData, loadoutBatchData);

      this.setState({ selectedData: undefined, selectedUpdating: false });
      if (actionpricing) {
        this.onPricingBatch(data.reference);
      }
      if (actionloadout) {
        this.setState({ loadoutForwardReference: reference });
      }
      if (!actionpricing && !actionloadout) {
        this.setState({ pricingOpen: true, pricingBatch: [{ reference: reference }] }, () => {
          setTimeout(() => {
            this.onReload();
          }, 1000);
        });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to Allocate. Please try again");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ selectedData: undefined, selectedUpdating: false });
  };

  onAcceptCancel = (data) => {
    this.setState({ selectedData: undefined, selectedUpdating: false });
  };

  onUnAllocateSelect = (data) => {
    this.setState({ selectedData: data, unAllocateConfirm: true });
  };

  onUnAllocateSelectClose = () => {
    this.setState({ selectedData: undefined, unAllocateConfirm: false });
  };

  onUnAllocateSelectConfirm = async () => {
    this.setState({ selectedData: undefined, unAllocateConfirm: false, loading: true });

    try {
      await unallocateSelectedStock(this.state.selectedData.map((item) => ({ barcode: item.barcode, barcode_id: item.barcode_id })));
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to unallocate selected data.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ selectedData: undefined, unAllocateConfirm: false, loading: true }, () => {
      this.onReload();
    });
  };

  onPricingBatches = async () => {
    this.setState({ pricingBatchesOpen: true }, () => {});
    this.handleTitle(true);
  };

  onPricingBatch = async (reference) => {
    this.handleTitle(true);
    const result = await this.loadPricingData([{ reference }]);
    this.setState({ pricingBatchOpen: true }, () => {});
  };

  onPricingBatchClose = async () => {
    this.handleTitle(false);
    this.setState({ pricingBatchOpen: false, pricingBatchData: undefined, pricingBatchReferences: undefined, pricingBatchesOpen: false });
    if (!this.state.loadoutForwardReference) {
      this.onReload();
    }
  };

  loadPricingData = async (pricingBatch) => {
    const mapped = [];
    await pricingBatch.map((item) => {
      mapped.push(item.reference);
    });

    await getStockGroupedByReference(mapped).then((result) => {
      this.setState({ pricingBatchData: result, pricingBatchReferences: mapped, loading: false }, () => {});
    });
  };

  onAcceptPricing = async (updateData) => {
    for (let index = 0; index < updateData.length; index++) {
      const item = updateData[index];
      for (let indexr = 0; indexr < this.state.pricingBatchReferences.length; indexr++) {
        const ref = this.state.pricingBatchReferences[indexr];
        await stockDetailUpdatePricingByReferenceAnd({ ...item, reference: ref });
      }
    }
    this.onPricingBatchClose();
  };

  onCancelPricing = () => {
    this.onPricingBatchClose();
    // this.loadStock();
  };

  onLoadoutComplete = () => {
    this.setState({ loadoutForwardReference: undefined });
    this.onReload();
  };

  //Expected to rub on _each_and_every_ render... until a better methos is introduced
  handleTitle = (open: boolean) => {
    if (document.getElementsByClassName("theMainTitle")[0]) {
      (document.getElementsByClassName("theMainTitle")[0] as HTMLHtmlElement).innerText = `STOCK ${open ? "PRICING" : "ALLOCATION"}`;
    }
  };

  setContractSellingPrices = () => {
    const data = this.state.pricingBatchData;
    data["data"].map((row) => {
      row.sellingprice = row.contractSellPrice ? row.contractSellPrice : row.sellingprice;
    });
    this.setState({ updateData: data });
    return data;
  };

  render() {
    return (
      <div className={this.state.classes.root}>
        {this.state.loading && <CircularProgress />}
        {/* {this.handleTitle()} */}
        {this.state.pricingBatchOpen && (
          <StockPricingUpdateGrid
            updateData={this.state.pricingBatchData["data"] ? this.state.pricingBatchData["data"] : [{}]}
            onAcceptPricing={this.onAcceptPricing}
            onCancelPricing={this.onCancelPricing}
            onBack={this.onPricingBatchClose}
            setContractSellingPrices={this.setContractSellingPrices}
            minGridHeightSecond={undefined}
          />
        )}
        {this.state.loadoutForwardReference && !this.state.pricingBatchOpen && <Loadout reference={this.state.loadoutForwardReference} onForward={this.onLoadoutComplete} />}
        {this.state.pricingBatchesOpen && <StockPricing onBack={this.onPricingBatchClose} />}

        {this.state.unAllocateConfirm && (
          <Confirmation
            isOpen={this.state.unAllocateConfirm}
            handleClose={this.onUnAllocateSelectClose}
            handleConfirm={this.onUnAllocateSelectConfirm}
            title={`Reset Data`}
            body={`Are you sure you want to reset the selected data?`}
          ></Confirmation>
        )}
        {!this.state.loading && !this.state.pricingBatchOpen && !this.state.pricingBatchesOpen && !this.state.loadoutForwardReference && (
          <StockAllocationTable
            allocating={this.state.allocating}
            data={this.state.stock}
            onReload={this.onReload}
            onAllocate={this.onAcceptProcess}
            onAccept={this.onAccept}
            onUnAllocateSelect={this.onUnAllocateSelect}
            onPricingBatches={this.onPricingBatches}
            referenceBatchesCount={this.state.referenceBatchesCount}
            handleUpdateStock={this.handleUpdateStock}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(StockAllocationUnstyled);
