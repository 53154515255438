import React, { FC, useEffect, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MTextField from "@material-ui/core/TextField";

import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { FormApi } from "final-form";

import { VarietiesByCommodityMappedForCombo } from "../../lib/api/variety";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { weeksOrdered, getFinancialYearSelected } from "../../lib/api/week";

import { clientsContracts, clientscontractsFull } from "../../lib/api/clientscontracts";
import { clientscontractsVarietiesFull } from "../../lib/api/clientscontractsvarieties";
import { clientscontractsGradesFull } from "../../lib/api/clientscontractsgrades";
import { clientscontractsCountsFull } from "../../lib/api/clientscontractscounts";

import { getAllClients } from "../../lib/api/clients";
import { classesAllSortedMappedforCombo } from "../../lib/api/classes";
import { distinctCodeByCommodity } from "../../lib/api/counts";
import { currency } from "../../lib/api/currency";
import { getPacksByCommodityCodeReadyForCombo } from "../../lib/api/pack";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      width: "600px",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
      width: "150px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
      width: "450px",
    },
    tableRowFieldInput: {
      width: "310px",
    },
  });

enum SelectedTab {
  Client = 0,
}

type ClientsContractsFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
  isCopied?: boolean;
} & WithStyles<typeof styles>;

class ClientsContractsFormUnstyled extends React.Component<ClientsContractsFormProps, any> {
  state = {
    classes: undefined,
    value: SelectedTab.Client,
    item: {
      id: "0",
      clients_id: 0,
      shipmentweekfrom: 0,
      shipmentweekto: 0,
      currency_id: undefined,
      sellingprice: undefined,
      commodity_id: 0,
      variety: [undefined],
      grade: [undefined],
      count: [undefined],
    },
    onSubmit: undefined,
    onClose: undefined,
    fromWeeks: [],
    toWeeks: [],
    commodityAllData: [],
    varieties: [],
    clientsList: [],
    grades: [],
    counts: [],
    currencies: [],
    id: 0,
    loading: true,
    packCodes: [],
    clientsContracts: [],
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentWillMount() {
    this.loadData().then(() => {
      if (this.state.id != 0) {
        this.getItem(this.state.id);
      }
    });
  }

  loadData = async () => {
    this.setState({ loading: true });
    const [resultCommodity, resultWeeks, resultClients, resultClasses, resultCurrency, clientContracts] = await Promise.all([
      commoditiesAllSortedMappedforCombo(),
      weeksOrdered(getFinancialYearSelected()),
      getAllClients(),
      classesAllSortedMappedforCombo(),
      currency.all(),
      clientscontractsFull(),
    ]);
    this.setState({
      commodityAllData: resultCommodity,
      fromWeeks: resultWeeks.data,
      toWeeks: resultWeeks.data,
      clientsList: resultClients.sort((a, b) => a.name.localeCompare(b.name)),
      grades: resultClasses,
      currencies: resultCurrency,
      clientsContracts: clientContracts,
      loading: false,
    });
  };

  getItem = async (id) => {
    clientsContracts.single(id).then((data) => {
      clientscontractsVarietiesFull(data.id).then((clientscontractvars) => {
        if (clientscontractvars && clientscontractvars.length > 0) {
          data.variety = clientscontractvars[0].varietyidlist.split(",").map((value) => parseInt(value));
        } else {
          data.variety = [undefined];
        }

        clientscontractsGradesFull(data.id).then((clientscontractgrades) => {
          if (clientscontractgrades && clientscontractgrades.length > 0) {
            data.grade = clientscontractgrades[0].gradesidlist.split(",").map((value) => parseInt(value));
          } else {
            data.grade = [undefined];
          }

          clientscontractsCountsFull(data.id).then((clientscontractcounts) => {
            if (clientscontractcounts && clientscontractcounts.length > 0) {
              data.count = clientscontractcounts.map((count) => count.count);
            } else {
              data.count = [undefined];
            }
            const commodityCode = this.state.commodityAllData.find((comm) => comm.id == data.commodity_id);
            this.setState({ item: { ...data, commodity_id: commodityCode ? commodityCode.value : 0 } }, () => {
              this.handleChangeCommodity(commodityCode ? commodityCode.value : "ALL");
            });
          });
        });
      });
    });
  };

  handleChangeCommodity = async (value) => {
    const [resultVars, resultPacks, resultDistinctCount] = await Promise.all([
      VarietiesByCommodityMappedForCombo(value),
      getPacksByCommodityCodeReadyForCombo(value),
      distinctCodeByCommodity(value),
    ]);
    const resultVarsSorted = (resultVars || []).sort((a, b) => {
      if (a.display > b.display) return 1;
      if (a.display < b.display) return -1;
      return 1;
    });
    this.setState({ varieties: resultVarsSorted, packCodes: resultPacks, counts: resultDistinctCount.data });
  };

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  updateToWeekSelections = (fromWeekId, item) => {
    if (item) {
      const fromWeek = JSON.parse(JSON.stringify(this.state.fromWeeks)).filter((weeks) => weeks.id == fromWeekId)[0];
      const toWeeks = this.state.fromWeeks.filter((week) => week.period_start >= fromWeek.period_start);

      this.setState({ toWeeks });
    }
  };

  render() {
    const { classes } = this.state;

    return (
      <ClientEdit
        onSubmit={this.state.onSubmit}
        classes={classes}
        onClose={this.state.onClose}
        item={this.state.item}
        fromWeek={this.state.fromWeeks}
        toWeek={this.state.toWeeks}
        commodityAllData={this.state.commodityAllData}
        varieties={this.state.varieties}
        clientsList={this.state.clientsList}
        handleOnChangeCode={this.handleChangeCommodity}
        updateToWeekSelections={this.updateToWeekSelections}
        grades={this.state.grades}
        counts={this.state.counts}
        currencies={this.state.currencies}
        packCodes={this.state.packCodes}
        clientsContracts={this.state.clientsContracts}
        isCopied={this.props.isCopied}
      />
    );
  }
}

export default withStyles(styles)(ClientsContractsFormUnstyled);

const ClientEdit = ({
  classes,
  onClose,
  item,
  fromWeek,
  toWeek,
  commodityAllData,
  varieties,
  clientsList,
  handleOnChangeCode,
  onSubmit,
  updateToWeekSelections,
  grades,
  counts,
  currencies,
  packCodes,
  clientsContracts,
  isCopied,
}) => {
  const formatToDropdown = (data: any[], displayKey: string, valueKey: string = "id", includeAll?: string): SelectType[] =>
    data.reduce((arr, item, index) => {
      if (index == 0 && includeAll) {
        arr.push({ value: 0, display: includeAll });
      }
      arr.push({ value: item[valueKey], display: item[displayKey] });
      return arr;
    }, []);

  const handleParseCommodity = (value: any, form: FormApi) => {
    handleOnChangeCode(value);
    form.change("count", [undefined]);
    form.change("variety", [undefined]);
  };

  const handleParseGeneric = (value: any) => {
    if (!value[value.length - 1]) {
      return [undefined];
    } else {
      return value.filter((item) => item);
    }
  };

  return (
    <Form
      initialValues={{ ...item }}
      onSubmit={(values: any) => {
        delete values["is_duplicate"];
        const commodityfind = commodityAllData.find((comm) => comm.value == values.commodity_id);
        const submitData = { ...values, commodity_id: commodityfind ? commodityfind.id : undefined };
        onSubmit(submitData);
      }}
      validate={(values) => {
        let errors = {};
        if (!values["clients_id"] || values["clients_id"] === 0) {
          errors = { ...errors, clients_id: "Please select a Client." };
        }
        if (!values["shipmentweekfrom"] || values["shipmentweekfrom"] === 0) {
          errors = { ...errors, shipmentweekfrom: "Please select a From Week." };
        }
        if (!values["shipmentweekto"] || values["shipmentweekto"] === 0) {
          errors = { ...errors, shipmentweekto: "Please select a To Week." };
        }
        return errors;
      }}
      render={({ handleSubmit, form, values }: any) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <table style={{ margin: "15px" }}>
            <tbody style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <CustomSelect form={form} values={values} title="Client" classes={classes} field="clients_id" data={formatToDropdown(clientsList, "name")} />
              <CustomSelect
                form={form}
                values={values}
                classes={classes}
                field="shipmentweekfrom"
                title="From Shipment Week"
                data={formatToDropdown(fromWeek, "week")}
                onParse={(value) => updateToWeekSelections(value, form.getState().values)}
              />
              <CustomSelect form={form} values={values} title="To Shipment Week" classes={classes} field="shipmentweekto" data={formatToDropdown(toWeek, "week")} />
              <CustomSelect
                form={form}
                values={values}
                classes={classes}
                title="Commodity"
                field="commodity_id"
                onParse={(value) => handleParseCommodity(value, form)}
                data={formatToDropdown(commodityAllData, "display", "value", "ALL COMMODITIES")}
              />
              <CustomSelect
                multiple
                form={form}
                values={values}
                title="Variety"
                field="variety"
                classes={classes}
                onParse={handleParseGeneric}
                disabled={!varieties || varieties.length == 0}
                data={formatToDropdown(varieties, "display", "id", "ALL VARIETIES")}
              />
              <CustomSelect
                multiple
                form={form}
                title="Grade"
                field="grade"
                values={values}
                classes={classes}
                onParse={handleParseGeneric}
                disabled={!grades || grades.length == 0}
                data={formatToDropdown(grades, "value", "id", "ALL GRADES")}
              />
              <CustomSelect
                form={form}
                title="Pack Code"
                field="pack_id"
                values={values}
                classes={classes}
                onParse={handleParseGeneric}
                disabled={!packCodes || packCodes.length == 0}
                data={packCodes}
              />
              <CustomSelect
                multiple
                form={form}
                field="count"
                title="Counts"
                values={values}
                classes={classes}
                onParse={handleParseGeneric}
                disabled={!counts || counts.length == 0}
                data={formatToDropdown(counts, "code", "code", "ALL COUNTS")}
              />
              <CustomSelect form={form} values={values} title="Currency" classes={classes} field="currency_id" data={formatToDropdown(currencies, "code", "id")} />
              <tr>
                <td className={classes.tableRowFieldTitle}>
                  <span>Selling Price:</span>
                </td>
                <td colSpan={4} className={classes.tableRowFieldData}>
                  <Field fullWidth required name="sellingprice" component={TextField} type="text" />
                </td>
              </tr>
            </tbody>
            <Field name="is_duplicate" hidden />
            <DuplicateCheck form={form} clientsContracts={clientsContracts} isCopied={isCopied} />
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 29px 5px" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" disabled={!!form.getState().values["is_duplicate"]}>
              <Check />
            </Button>
          </div>
        </form>
      )}
    />
  );
};

// recusive function to sort and convert array/string to sorted string;
const sortListString = (arr: any) => (typeof arr === "string" ? (arr == "" ? "" : sortListString(arr.split(","))) : arr.length == 0 ? "" : arr.sort().join(","));

const DuplicateCheck: FC<{ form: FormApi; clientsContracts: any[]; isCopied: boolean }> = ({ form, clientsContracts, isCopied }) => {
  const handleDuplicateCheck = async () => {
    const { id, clients_id, shipmentweekfrom, shipmentweekto, commodity_id, variety, grade, pack_id, count, currency_id, sellingprice } = form.getState().values;
    const exists = clientsContracts.find(
      (item) =>
        (item.id != id || isCopied) &&
        item.clients_id == clients_id &&
        item.weekfrom_id == shipmentweekfrom &&
        item.weekto_id == shipmentweekto &&
        item.commodity_code == commodity_id &&
        item.pack_id == pack_id &&
        item.currency_id == currency_id &&
        item.sellingprice == sellingprice &&
        sortListString(item.varieties || "") == sortListString(variety) &&
        sortListString(item.grades_ids || "") == sortListString(grade) &&
        sortListString(item.counts || "") == sortListString(count),
    );
    form.change("is_duplicate", !!exists);
  };

  useEffect(() => {
    handleDuplicateCheck();
  }, [form.getState().values]);

  if (!!form.getState().values["is_duplicate"]) {
    return <p style={{ color: "red" }}>A record with this data already exists. Please make the necessary changes.</p>;
  }

  return <></>;
};

type SelectType = { display: string; value: any };
type CustomSelectProps = {
  form: FormApi;
  classes: any;
  field: string;
  title: string;
  data: SelectType[];
  values: any;
  multiple?: boolean;
  disabled?: boolean;
  onParse?(value: any): void;
};

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { classes, field, data, title, form, onParse, values, disabled = false, multiple = false } = props;

  const selected = useMemo(() => {
    if (multiple) {
      return ((form.getState().values[field] || []).map((value: any[]) => data.find((item) => item.value == value)) || []).filter((item: SelectType) => item);
    }
    return data.find((item) => item.value === form.getState().values[field]);
  }, [data, form, values, field]);

  const handleItemSelect = (_: unknown, data: any) => {
    const value = multiple ? data.map((item: SelectType) => item.value) : data.value;
    form.change(field, value);
    if (onParse) {
      onParse(value);
    }
  };

  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{title}:</td>
      <td className={classes.tableRowFieldData}>
        <Field
          name={field}
          label={title}
          render={(props) => (
            <Autocomplete
              {...props}
              id={field}
              fullWidth
              options={data}
              multiple={multiple}
              disabled={disabled}
              onChange={handleItemSelect}
              value={selected || { display: undefined, value: undefined }}
              getOptionLabel={(option: any) => option.display}
              renderInput={(params) => <MTextField {...params} variant="standard" />}
            />
          )}
        />
      </td>
    </tr>
  );
};
