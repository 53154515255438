import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { palletspecs } from "../../lib/api/palletspec";
import { costestimations } from "../../lib/api/costestimation";
import { getEstimationsCodesReadyForCombo } from "../../lib/api/estimationcodes";

import { Form, Field } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";

import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      marginTop: theme.spacing(1),
      textAlign: "right",
      maxWidth: "inherit",
    },
    tableRowCombo: {
      marginBottom: "5px",
      marginLeft: "8px",
      textAlign: "left",
      width: "90%",
    },
    codeInput: {
      marginLeft: "8px",
      width: "90%",
      textAlign: "left",
    },
    formContainer: {
      width: "250px",
    },
  });

type PalletSpecsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PalletSpecsEditFormUnstyled extends React.Component<PalletSpecsEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", code: "", carton: "" },
    onSubmit: undefined,
    onClose: undefined,
    estimationCodes: [],
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }

    this.setState({ estimationCodes: getEstimationsCodesReadyForCombo() });
  }

  getItem = (id) => {
    palletspecs.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  onSelectionChange = (value) => {
    this.setState({ item: { ...this.state.item, code: value } });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.state.onSubmit}
          render={({ handleSubmit, form }) => (
            <form className={classes.formContainer} id={this.state.item.id} onSubmit={handleSubmit}>
              <table style={{ marginLeft: "15px" }}>
                <tbody>
                  <TableFieldCombo classes={classes} field="code" title="Code" combodata={this.state.estimationCodes} />
                  <TableFieldText classes={classes} field="carton" title="Carton" disabled={false} multiline={false} required={false} />
                  <TableFieldText classes={classes} field="weight" title="Weight" disabled={false} multiline={false} required={false} />
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginBottom: "15px", float: "right" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PalletSpecsEditFormUnstyled);

export const TableFieldCombo: React.FunctionComponent<{ classes: any; field: string; title: string; combodata: any } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, combodata } = props;

  return (
    <tr>
      <td className={classes.tableRow}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field name={field} component={Select} formControlProps={{ className: classes.tableRowCombo }}>
          {combodata.map((item, idx) => {
            return (
              <MenuItem key={`${idx}-${item.value}`} value={item.value}>
                {item.display}
              </MenuItem>
            );
          })}
        </Field>
      </td>
    </tr>
  );
};

const TableFieldText: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRow}>
        <span>{`${title}: `}</span>
      </td>
      <td>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.codeInput}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};
