import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../../lib/components/grid";
import {
  ConsolidationFinalDetailType,
  consolidationGroupedByProducer,
  ConsolidationGroupedProducerType,
  getConsolidationFinalByInvoiceNumber
} from "../../../lib/api/consolidation";
import { GroupedGridColumns } from "./finalgridsetup";
import { DetailGridColumns } from "./finaldetailgridsetup";

import classnames from "classnames";
import { Row } from "react-data-grid";
import format from "date-fns/format";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import "react-splitter-layout/lib/index";
import "../../../lib/custom/splitter.css";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import Form from "./form";
import { SnackContext } from "../../../lib/context/SnackContext";
import PaymentOverviewTable from "./paymentoverviewtable";
import { GreenButton, OrangeButton } from "../../../lib/components/ColorButtons";
import { getFinancialYearSelected, getWeekIdByDate } from "../../../lib/api/week";
import { producerfinalpaidhead, ProducerFinalPaidHeadType } from "../../../lib/api/producerfinalpaidhead";
import { getCurrencyIdByCode } from "../../../lib/api/currency";
import { producerfinalpaid, ProducerFinalPaidType } from "../../../lib/api/producerfinalpaid";
import { consolidationamenddetails } from "../../../lib/api/consolidationamenddetails";
import { printProducerFinalRemittance } from "../../../reports/printing";

import Menu, { MenuProps } from "@material-ui/core/Menu";

import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { DocumentsButton, DocumentsType } from "../../../lib/components/DocumentsButton";
import ProducerFinalAdHoc from "../adhoc/producerfinaladhoc";
import { producerfinalpaidadhoc, ProducerFinalPaidAdHocType } from "../../../lib/api/producerfinalpaidadhoc";
import { producerfinalpaidadhocdetail, ProducerFinalPaidAdHocDetailType } from "../../../lib/api/producerfinalpaidadhocdetail";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
    gridWrapper: {
      position: "relative",
    },
    toolbarWrapper: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
    },
    greenRow: {
      color: "green",
    },
    blackRow: {
      color: "black",
    },
  });

type ProducersFinalProps = {
  classes: any;
  producer: string;
};

type ProducerFinalState = {
  classes: any,
  producer: string,
  loading: boolean,
  detailLoading: boolean,
  data: any[],
  detailData: any[],
  selectedRows: ConsolidationGroupedProducerType[],
  selectedRowsDetail: ConsolidationFinalDetailType[],
  minGridHeight: number,
  minGridHeightSecond: number,
  makePayment: boolean,
  showPaymentOverview: boolean,
  anchorEl: any,
  createAdhocView: boolean
}

class ProducersFinalUnstyled extends React.Component<ProducersFinalProps, {}> {
  constructor(props) {
    super(props);
    this.state.classes = this.props.classes;

    if (props.producer) {
      this.state.producer = this.props.producer;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  state: ProducerFinalState = {
    classes: undefined,
    producer: undefined,
    loading: true,
    detailLoading: false,
    data: [],
    detailData: [],
    selectedRows: [],
    selectedRowsDetail: [],
    minGridHeight: 0,
    minGridHeightSecond: 0,
    makePayment: false,
    showPaymentOverview: false,
    anchorEl: undefined,
    createAdhocView: false,
  };

  setGridHeights = () => {
    const primarysplit = document.getElementsByClassName("splitter horizontal");
    this.setState({ minGridHeight: primarysplit[0].children[0].clientHeight - 90, minGridHeightSecond: primarysplit[0].children[2].clientHeight - 70 });
  };

  loadData = async () => {
    this.setState({ loading: true });
    const resp: any[] = await consolidationGroupedByProducer(this.props.producer, getFinancialYearSelected());
    this.setState({ loading: false, data: resp });
    this.filteredRows = resp;
  };

  componentDidMount() {
    this.setGridHeights();

    this.loadData().then(() => {
      this.setGridHeights();
    });
  }

  handleLoadSelectedRow = async (row) => {
    this.setState({ detailLoading: true });

    await getConsolidationFinalByInvoiceNumber(row.invoicenumber, this.props.producer)
      .then((result) => {
        this.setState({ detailData: [...this.state.detailData, ...result] });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });

    this.setState({ detailLoading: false })
  };

  selectRow = (row) => {
    const exists = this.state.selectedRows.find((item) => item.invoicenumber == row.invoicenumber);
    if (exists) {
      const filtered = this.state.selectedRows.filter((item) => item.invoicenumber != row.invoicenumber);
      const filteredDetail = this.state.detailData.filter((item) => item.invoicenumber != row.invoicenumber);

      this.setState({ selectedRows: filtered, detailData: filteredDetail });
    } else {
      this.setState({ selectedRows: [...this.state.selectedRows, row] });
      this.handleLoadSelectedRow(row);
    }
  };

  selectRowDetail = (row) => {
    const exists = this.state.selectedRowsDetail.find((item) => item.barcode_id == row.barcode_id);
    if (exists) {
      const filtered = this.state.selectedRowsDetail.filter((item) => item.barcode_id != row.barcode_id);
      this.setState({ selectedRowsDetail: filtered });
    } else {
      this.setState({ selectedRowsDetail: [...this.state.selectedRowsDetail, row] });
    }
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectedRows.find((item) => item.invoicenumber == row.invoicenumber) ? classes.boldRow : classes.normalRow;
    const color = row.idents ? classes.greenRow : classes.normalRow;

    return <Row {...props} className={classnames(bold, color)} onDoubleClickCapture={() => this.selectRow(row)} />;
  };

  detailRowRenderer = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectedRowsDetail.find((item) => item.barcode_id == row.barcode_id) ? classes.boldRow : classes.normalRow;
    const green = row.producerfinalpaidhead_id ? classes.greenRow : classes.blackRow;

    return <Row {...props} className={classnames(green, bold)} />;
  };

  handleSelectAll = () => {
    this.setState({ selectedRowsDetail: [], detailData: [] });
    for (let i = 0; i < this.filteredRows.length; i++) {
      const row = this.filteredRows[i];
      this.handleLoadSelectedRow(row);
    }
    this.setState({ selectedRows: this.filteredRows });
  };

  handleDeselectAll = () => {
    this.setState({ selectedRows: [], selectedRowsDetail: [], detailData: [] });
  };

  handleSelectAllDetail = () => {
    this.setState({ selectedRowsDetail: this.filteredRowsDetail.filter((item) => !item.producerfinalpaidhead_id) });
  };

  handleDeselectAllDetail = () => {
    this.setState({ selectedRowsDetail: [] });
  };

  handleRefresh = () => {
    this.loadData();
  };

  handleRefreshDetail = async () => {
    this.setState({ detailLoading: true, detailData: [] });

    for (let i = 0; i < this.state.selectedRows.length; i++) {
      const row = this.state.selectedRows[i];

      await getConsolidationFinalByInvoiceNumber(row.invoicenumber, this.props.producer)
        .then((result) => {
          this.setState({ detailData: [...this.state.detailData, ...result] });
        })
        .catch((error) => {
          const err = GenerateErrorMessage(error, "failed to retrieve data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    }

    this.setState({ detailLoading: false, selectedRowsDetail: [] });
    this.setGridHeights();
  };

  filteredRows;
  handleFilteredRows = (filteredRows: any[]) => {
    this.filteredRows = filteredRows;
  };

  filteredRowsDetail;
  handleFilteredDetailRows = (filteredRows: any[]) => {
    this.filteredRowsDetail = filteredRows;
  };

  toggleMakePayment = () => {
    this.setState({ makePayment: !this.state.makePayment });
  };

  handleMakePayment = async (
    values: {
      amount: number;
      currency_code: string;
      ident: 1;
      payment_date: Date;
      makeanote: string;
      adhoc: string;
    },
    selectedAdHocs: any[]
  ) => {
    let producerHeadId = 0;

    const formattedDate = format(values.payment_date, "yyyy-MM-dd");
    const week_id = await getWeekIdByDate(formattedDate);
    const currency_id = await getCurrencyIdByCode(values.currency_code);

    const dataToPush: { data: ProducerFinalPaidHeadType } = {
      data: {
        amount: values.amount,
        ident: parseInt(values.ident.toString().replace("PPF", "")),
        week_id: week_id,
        makeanote: values.makeanote,
        payment_date: values.payment_date,
        currency_id: currency_id,
      },
    };

    for (let i = 0; i < this.state.selectedRowsDetail.length; i++) {
      const item = this.state.selectedRowsDetail[i];
      const id = item.camenddetails_id;

      await consolidationamenddetails.update(id, { data: { status: 4 } }).catch((error) => {
        const err = GenerateErrorMessage(error, "Error updating data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });
    }

    await producerfinalpaidhead
      .create(dataToPush)
      .then((res) => {
        producerHeadId = res[0];
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });

    if (producerHeadId && producerHeadId != 0) {
      let adhocValue: number = parseFloat((values.adhoc || "").replaceAll(",", ""));

      for (let i = 0; i < selectedAdHocs.length; i++) {
        let adhoc = selectedAdHocs[i];

        if (adhocValue === 0) continue;

        if (adhocValue > adhoc.data.remaining) {
          adhocValue = adhocValue - adhoc.data.remaining;
        } // use the remainding adhoc value and set the adhocvalue to 0
        else {
          adhoc.data.remaining = adhocValue;
          adhocValue = 0;
        }

        const dataToAdhoc: { data: ProducerFinalPaidAdHocDetailType } = {
          data: {
            producerfinalpaidhead_id: producerHeadId,
            producerfinalpaidadhoc_id: adhoc.value,
            amount: adhoc.data.remaining,
          },
        };

        await producerfinalpaidadhocdetail.create(dataToAdhoc);
      }

      for (let i = 0; i < this.state.selectedRowsDetail.length; i++) {
        const row = this.state.selectedRowsDetail[i];

        const detailToPush: { data: ProducerFinalPaidType } = {
          data: {
            producerfinalpaidhead_id: producerHeadId,
            barcode_id: row.barcode_id,
            invoicenumber: parseInt(row.invoicenumber),
          },
        };

        await producerfinalpaid.create(detailToPush).catch((error) => {
          const err = GenerateErrorMessage(error, "failed to retrieve data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
      }
    }
    this.setState({ selectedRows: [] });
    this.loadData();
  };

  togglePaymentOverview = () => {
    this.setState({ showPaymentOverview: !this.state.showPaymentOverview });
  };

  showDetailOverview = () => {
    this.setState({ showPaymentOverview: false, detailData: [], selectedRowsDetail: [] });
    this.handleReload();
  };

  handleReload = async () => {
    this.loadData();
    this.handleRefreshDetail();
  };

  handlePrintFinalPdf = () => {
    if (this.state.detailData[0].producerfinalpaidhead_id) {
      printProducerFinalRemittance(this.state.selectedRows, this.state.detailData);
    } else {
      this.context.updateSnack({ show: true, color: "red", message: "Cannot export pdf when missing payment details" });
    }
  };

  handlePrintFinalExcel = async () => {
    window.location.href = `/api/consolidation/ext/printFinalRemittanceExcel?producer=${this.state.producer
      }&financial_year=${getFinancialYearSelected()}&invoicenumbers=${this.state.selectedRows.map((item) => item.invoicenumber).join(",")}`;
  };

  menuItems = [
    {
      icon: DocumentsType.PDF,
      title: "FINAL",
      options: [
        {
          title: "FINAL PDF",
          icon: DocumentsType.PDF,
          action: () => this.handlePrintFinalPdf(),
        },
        {
          title: "FINAL EXCEL",
          icon: DocumentsType.EXCEL,
          action: () => this.handlePrintFinalExcel(),
        },
      ],
    },
  ];

  handleExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleExportMenuClose = () => {
    this.setState({ anchorEl: undefined });
  };

  handleToggleAdhocTransaction = () => {
    this.setState({ createAdhocView: !this.state.createAdhocView });
  }

  handleCreateAdhoc = async (data: ProducerFinalPaidAdHocType) => {
    if (data.id) {
      await producerfinalpaidadhoc.update(data.id, {
        data: {
          producer_id: data.producer_id,
          week_id: data.week_id,
          currency_id: data.currency_id,
          paidamount: parseFloat(data.paidamount.toString()),
          note: data.note,
        }
      });
    } else {
      await producerfinalpaidadhoc.create({
        data: {
          producer_id: data.producer_id,
          week_id: data.week_id,
          currency_id: data.currency_id,
          paidamount: parseFloat(data.paidamount.toString()),
          note: data.note,
        }
      });
    }
    this.context.updateSnack({ show: true, color: "green", message: "Successfully created adhoc" });
    this.setState({ createAdhocView: !this.state.createAdhocView });
    this.loadData();
  }

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root} id="main_splitter_div">
        {this.state.createAdhocView ?
          <ProducerFinalAdHoc
            initialData={{}}
            producerID={this.props.producer}
            handleProcessAdHoc={this.handleCreateAdhoc}
            handleClose={this.handleToggleAdhocTransaction}
          />
          : (
            this.state.showPaymentOverview ? (
              <PaymentOverviewTable producer={this.state.producer} showDetailOverview={this.showDetailOverview} refreshDetailOverview={this.loadData} />
            ) : this.state.makePayment ? (
              <Form
                toggleClose={this.toggleMakePayment}
                selectedRowsDetail={this.state.selectedRowsDetail}
                handleMakePayment={this.handleMakePayment}
                handleReload={this.handleReload}
                producerId={this.props.producer}
              />
            ) : (
              <div style={{ height: "100%", width: "100%", display: "grid" }}>
                <Splitter
                  position="horizontal"
                  primaryPaneMaxHeight="calc(100% - 150px)"
                  primaryPaneHeight="calc(100% - 400px)"
                  primaryPaneWidth="100%"
                  dispatchResize={true}
                  postPoned={true}
                  onDragFinished={(e) => {
                    this.setGridHeights();
                  }}
                >
                  <div id="primary_grid" className={classes.gridWrapper}>
                    <Toolbar className={classes.toolbarWrapper}>
                      <Button onClick={this.togglePaymentOverview} variant="contained" color="primary" style={{ marginRight: "10px" }}>
                        payment overview
                      </Button>
                      <OrangeButton onClick={this.handleSelectAll} disabled={this.state.data.length == 0} variant="contained">
                        Select All
                      </OrangeButton>
                      <OrangeButton onClick={this.handleDeselectAll} disabled={this.state.selectedRows.length == 0} variant="contained">
                        Deselect All
                      </OrangeButton>
                      <GreenButton onClick={this.handleToggleAdhocTransaction} variant="contained">
                        Create Add Hoc Transactions
                      </GreenButton>
                    </Toolbar>
                    <Grid
                      data={this.state.data}
                      loading={this.state.loading}
                      clearFilters={"producerfinalgrid"}
                      handleRefresh={this.handleRefresh}
                      rowRenderer={(props) => this.rowRenderer(props, classes)}
                      GridColumns={(data, filters, arrangement, columnsWidth) => GroupedGridColumns(data, filters, arrangement, columnsWidth, this.state.selectedRows, this.selectRow)}
                      handleFilteredRows={(filteredrows) => this.handleFilteredRows(filteredrows)}
                      forceHeight={this.state.minGridHeight}
                      totalRowColumns={[
                        "sellingprice",
                        "sellingpricefinal",
                        "foreigncost",
                        "nettsellprice",
                        "roe_eta",
                        "roe_etd",
                        "exchangerate",
                        "adjustment",
                        "sellpricezar",
                        "fobcost",
                        "fobcost_est",
                        "fobcost_final",
                        "coldcost",
                        "coldcost_est",
                        "coldcost_final",
                        "margin",
                        "totalcost",
                        "rtgc",
                        "rtgp",
                        "advance",
                        "final",
                      ]}
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <Toolbar className={classes.toolbarWrapper}>
                      <GreenButton disabled={this.state.selectedRowsDetail.length == 0} variant="contained" onClick={this.toggleMakePayment}>
                        Make Payment
                      </GreenButton>
                      <OrangeButton onClick={this.handleSelectAllDetail} disabled={this.state.detailData.length == 0} variant="contained">
                        Select All
                      </OrangeButton>
                      <OrangeButton onClick={this.handleDeselectAllDetail} disabled={this.state.selectedRowsDetail.length == 0} variant="contained">
                        Deselect All
                      </OrangeButton>
                      <DocumentsButton menuItems={this.menuItems} disabled={this.state.selectedRows.length == 0 || this.state.detailLoading} />
                    </Toolbar>
                    <Grid
                      data={this.state.detailData}
                      loading={this.state.detailLoading}
                      clearFilters={"producerfinalgriddetail"}
                      handleRefresh={this.handleRefresh}
                      rowRenderer={(props) => this.detailRowRenderer(props, classes)}
                      GridColumns={(data, filters, arrangement, columnsWidth) =>
                        DetailGridColumns(data, filters, arrangement, columnsWidth, this.state.selectedRowsDetail, this.selectRowDetail)
                      }
                      forceHeight={this.state.minGridHeightSecond}
                      handleFilteredRows={(filteredrows) => this.handleFilteredDetailRows(filteredrows)}
                      totalRowColumns={[
                        "palletSize",
                        "NoCartons",
                        "sellingprice",
                        "sale_credit",
                        "sellingprice_final",
                        "foreigncost",
                        "adjustment",
                        "nettsellprice",
                        "ld_fob",
                        "roe_eta",
                        "roe_etd",
                        "exchangerate",
                        "sellpricezar",
                        "fobcost",
                        "fobcost_est",
                        "fobcost_final",
                        "coldcost",
                        "coldcost_est",
                        "coldcost_final",
                        "margin",
                        "totalcost",
                        "rtgc",
                        "ld_dip",
                        "rtgp",
                        "advance",
                        "final",
                      ]}
                    />
                  </div>
                </Splitter>
              </div>
            )
          )}
      </div>
    );
  }
}

export default withStyles(styles)(ProducersFinalUnstyled);
