import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Row } from "react-data-grid";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { OrangeButton, RedButton } from "../../../lib/components/ColorButtons";
import { PaymentOverviewGridColumns } from "./paymentoverviewgridsetup";
import Grid from "../../../lib/components/grid";
import { getPaymentOverviewByProducer, producerfinalpaidhead } from "../../../lib/api/producerfinalpaidhead";
import { SnackContext } from "../../../lib/context/SnackContext";
import Confirmation from "../../../lib/components/confirmation";

import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { getFinancialYearSelected } from "../../../lib/api/week";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
    },
    toolbarWrapper: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
    },
    boldRow: {
      fontWeight: "bold",
    },
    normalRow: {
      fontWeight: "normal",
    },
  });

type PaymentOverviewProps = {
  classes: any;
  producer: string;
  showDetailOverview(): void;
  refreshDetailOverview(): void;
};

class PaymentOverviewUnstyled extends React.Component<PaymentOverviewProps, {}> {
  constructor(props) {
    super(props);

    this.state.togglePaymentOverview = props.showDetailOverview;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  state = {
    loading: true,
    togglePaymentOverview: undefined,
    data: [],
    selectedRows: [],
    minGridHeight: 800,
    reversePaymentConfirmation: false,
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const data = await getPaymentOverviewByProducer(this.props.producer, getFinancialYearSelected());
      this.setState({ data });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.loadData();
  }

  handleSelectAll = () => {
    this.setState({ selectedRows: this.filteredRows });
  };

  handleDeselectAll = () => {
    this.setState({ selectedRows: [] });
  };

  handleRefresh = () => {
    this.loadData();
  };

  selectRow = (row) => {
    const exists = this.state.selectedRows.find((item) => item.ident == row.ident);
    if (exists) {
      const filtered = this.state.selectedRows.filter((item) => item.ident != row.ident);
      this.setState({ selectedRows: filtered });
    } else {
      this.setState({ selectedRows: [...this.state.selectedRows, row] });
    }
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const bold = this.state.selectedRows.find((item) => item.ident == row.ident) ? classes.boldRow : classes.normalRow;

    return <Row {...props} className={bold} />;
  };

  filteredRows;
  handleFilteredRows = (filteredRows: any[]) => {
    this.filteredRows = filteredRows;
  };

  toggleReversePaymentConfirmation = () => {
    this.setState({ reversePaymentConfirmation: !this.state.reversePaymentConfirmation });
  };

  handleReversePayments = async () => {
    this.setState({ loading: true, reversePaymentConfirmation: false });

    for (let i = 0; i < this.state.selectedRows.length; i++) {
      const headid = this.state.selectedRows[i].paidheadid;
      await producerfinalpaidhead.remove(headid);
    }

    this.setState({ selectedRows: [] });
    this.loadData();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Confirmation
          isOpen={this.state.reversePaymentConfirmation}
          handleClose={this.toggleReversePaymentConfirmation}
          handleConfirm={this.handleReversePayments}
          title={`Reverse Selected Payment(s)`}
          body={`Are you sure you would like to reverse the selected payment(s)?`}
        ></Confirmation>
        <div className={classes.root}>
          <Toolbar className={classes.toolbarWrapper}>
            <Button onClick={this.state.togglePaymentOverview} variant="contained" color="primary" style={{ marginRight: "10px" }}>
              detail overview
            </Button>
            <OrangeButton onClick={this.handleSelectAll} disabled={this.state.data.length == 0} variant="contained">
              Select All
            </OrangeButton>
            <OrangeButton onClick={this.handleDeselectAll} disabled={this.state.selectedRows.length == 0} variant="contained">
              Deselect All
            </OrangeButton>
            <RedButton onClick={this.toggleReversePaymentConfirmation} disabled={this.state.selectedRows.length == 0} variant="contained">
              Reverse Payment
            </RedButton>
          </Toolbar>
          <Grid
            data={this.state.data}
            loading={this.state.loading}
            clearFilters={"finalpaymentoverviewgrid"}
            handleRefresh={this.handleRefresh}
            rowRenderer={(props) => this.rowRenderer(props, classes)}
            GridColumns={(data, filters, arrangement, columnsWidth) =>
              PaymentOverviewGridColumns(data, filters, arrangement, columnsWidth, this.state.selectedRows, this.selectRow)
            }
            handleFilteredRows={(filteredrows) => this.handleFilteredRows(filteredrows)}
            forceHeight={this.state.minGridHeight}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentOverviewUnstyled);
