import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "../lib/components/grid";

import { GridColumnsEdiDetail } from "./dispatchtableformcompareedidetail";

import { dispatchFixBarcodeEdi } from "../lib/api/dispatch";
import { FindByDispatchIDBarcodeCompareType, findByDispatchIDBarcodeMissingFromLoadout } from "../lib/api/edistockpalettesdispatched";
import { loadout } from "../lib/api/loadout";
import { deleteByBarcode, updateLoadoutByBarcode, loadoutDetailFull } from "../lib/api/loadoutdetail";
import { stockDetailDeleteByBarcode, stockDetailInsertByBarcodeID } from "../lib/api/stockdetail";
import { stock, updateStockByBarcodeID } from "../lib/api/stock";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { Row } from "react-data-grid";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 400px",
      gridColumnGap: "20px",
    },
    errorRowStyle: {
      backgroundColor: "yellow",
      "& div": {
        backgroundColor: "yellow",
      },
      "& .react-grid-Row:hover .react-grid-Cell, .react-grid-Row.row-context-menu .react-grid-Cell": {
        backgroundColor: "yellow",
      },
    },
    errorsContainer: {
      border: "1px solid #dddddd",
      outline: "1px solid #e7eaec",
      backgroundColor: "#fff",
    },
    errorsHeader: {
      display: "flex",
      fontSize: "20px",
      height: "60px",
      alignItems: "center",
      marginLeft: "10px",
      marginRight: "10px",
    },
    errorCard: {
      border: "1px solid #dddddd",
      outline: "1px solid #e7eaec",
      backgroundColor: "#ddd",
      marginRight: "10px",
      marginLeft: "10px",
      marginBottom: "10px",
    },
    errorCardButton: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
    errorCardText: {
      marginLeft: "10px",
      marginRight: "10px",
    },
    gridHeaderWrapper: {
      position: "absolute",
      left: 0,
      height: "60px",
    },
    gridHeaderText: {
      marginLeft: "10px",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    yellowRow: {
      backgroundColor: "yellow",
      "&:hover": {
        backgroundColor: "yellow",
      },
    },
    boldRow: {
      fontWeight: "bold",
    },
  });

type VSDataType = {
  status: number;
  Type: string;
  Barcode: string;
  NoCartons: string;
  PalletSize: string;
  CommodityCode: string;
  VarietyCode: string;
  PackCode: string;
  MarkCode: string;
  GradeCode: string;
  CountCode: string;
  InventoryCode: string;
  PickingRef: string;
  barCommodityCode: string;
  barVarietyCode: string;
  barPackCode: string;
  barMarkCode: string;
  barGradeCode: string;
  barCountCode: string;
  barInventoryCode: string;
  barNoCartons: string;
  barPalletSize: string;
  barPickingRef: string;
  detail: string;
  dispatchid: string;
  barcode_id: number;
  Waybill: string;
  barWaybill: string;
};

const StatusType = {
  LoadoutLinkMissing: 1,
  OnlyOnLoadout: 2,
  BarcodeNotExist: 3,
  BarcodeNotEquals: 4,
};

const StatusValue = {
  1: "Loadout Link Missing",
  2: "Only on Loadout. Not part of Dispatch",
  3: "Barcode Does not exist",
  4: "Barcode data not equals",
};

const DispatchFixStockType = {
  AcceptEDI: 1,
  AcceptBarcode: 2,
};

type DispatchAcceptDialogEdiDetailsProps = {
  selectedDispatch: any;
  dispatchPallets: FindByDispatchIDBarcodeCompareType[];
  dispatchPalletsLoadouts: { loadout_id: number; loadout_reference: string }[];
  handleForceReload: any;
  handleIssuesCount: any;
} & WithStyles<typeof styles>;

class DispatchAcceptDialogEdiDetailsUnstyled extends React.Component<DispatchAcceptDialogEdiDetailsProps, {}> {
  state = {
    vsData: [],
    vsDataMissingFromLoadout: [],
    loading: false,
    selectedRow: undefined,
    dispatchPallets: [],
    dispatchPalletsLoadouts: [],
    handleForceReload: undefined,
    handleIssuesCount: undefined,
    errorsDetail: [],
    selectedFixRow: undefined,
    selectedLoadout: undefined,
    stock: [],
  };

  constructor(props) {
    super(props);
    this.state.dispatchPallets = props.dispatchPallets;
    this.state.dispatchPalletsLoadouts = props.dispatchPalletsLoadouts;
    this.state.handleForceReload = props.handleForceReload;
    this.state.handleIssuesCount = props.handleIssuesCount;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.load().then(() => {
      this.setState({ loading: false });
    });
  }

  load = async () => {
    let IssuesCount = 0;
    const result = [];
    const loadout_id = this.props.selectedDispatch["loadout_id"];
    const lddata = await loadout.single(loadout_id);

    if (lddata) {
      const stockData = await loadoutDetailFull(lddata.id);
      const sortedStock = stockData.data
        .map((item) => ({
          status: 0,
          Type: "Stock",
          Barcode: item.barcode,
          NoCartons: item.noCartons,
          PalletSize: item.palletSize,
          CommodityCode: item.commodityCode,
          VarietyCode: item.varietyCode,
          PackCode: item.packCode,
          MarkCode: item.markCode,
          GradeCode: item.gradeCode,
          CountCode: item.countCode,
          InventoryCode: item.inventoryCode,
          PickingRef: item.PickingRef,
          loadoutId: item.loadout_id,
          Waybill: item.Waybill,
        }))
        .sort((a, b) => {
          if (a.Barcode > b.Barcode) return 1;
          if (a.Barcode < b.Barcode) return -1;
          return 1;
        });
      this.setState({ stock: sortedStock });
    } else {
      this.setState({ stock: [] });
    }

    this.props.dispatchPallets.map((dp) => {
      const data: VSDataType = {
        status: 0,
        Type: "EDI",
        Barcode: dp.edi_Barcode,
        NoCartons: dp.edi_NoCartons,
        PalletSize: dp.edi_PalletSize,
        CommodityCode: dp.edi_CommodityCode,
        VarietyCode: dp.edi_VarietyCode,
        PackCode: dp.edi_PackCode,
        MarkCode: dp.edi_MarkCode,
        GradeCode: dp.edi_GradeCode,
        CountCode: dp.edi_CountCode,
        InventoryCode: dp.edi_InventoryCode,
        barCommodityCode: dp.bar_CommodityCode,
        barVarietyCode: dp.bar_VarietyCode,
        barPackCode: dp.bar_PackCode,
        barMarkCode: dp.bar_MarkCode,
        barGradeCode: dp.bar_GradeCode,
        barCountCode: dp.bar_CountCode,
        barInventoryCode: dp.bar_InventoryCode,
        barNoCartons: dp.bar_NoCartons,
        barPalletSize: dp.bar_PalletSize,
        detail: "",
        dispatchid: dp.edi_dispatchid,
        barcode_id: dp.bar_barcode_id,
        PickingRef: dp.edi_PickingRef,
        barPickingRef: dp.bar_PickingRef,
        barWaybill: dp.bar_Waybill,
        Waybill: dp.edi_Waybill,
      };

      if (isNullOrUndef(dp.loadout_id)) {
        data.status = StatusType.LoadoutLinkMissing;
      } else if (
        !(
          dp.edi_Barcode.trim() == dp.bar_Barcode.trim() &&
          dp.edi_CommodityCode.trim() == dp.bar_CommodityCode.trim() &&
          dp.edi_VarietyCode.trim() == dp.bar_VarietyCode.trim() &&
          dp.edi_PackCode.trim() == dp.bar_PackCode.trim() &&
          dp.edi_MarkCode.trim() == dp.bar_MarkCode.trim() &&
          dp.edi_GradeCode.trim() == dp.bar_GradeCode.trim() &&
          dp.edi_CountCode.trim() == dp.bar_CountCode.trim() &&
          dp.edi_InventoryCode.trim() == dp.bar_InventoryCode.trim() &&
          dp.edi_sequenceNumber == dp.bar_sequenceNumber &&
          dp.edi_PalletSize == dp.bar_PalletSize &&
          dp.edi_NoCartons == dp.bar_NoCartons
        )
      ) {
        data.status = StatusType.BarcodeNotEquals;
      }

      if (data.status != 0) {
        IssuesCount += 1;
      }

      result.push(data);
      return data;
    });

    const resultSorted = result.sort((a, b) => {
      if (a.Barcode > b.Barcode) return 1;
      if (a.Barcode < b.Barcode) return -1;
      return 1;
    });

    this.state.handleIssuesCount(IssuesCount);
    this.setState({ vsData: resultSorted, selectedLoadout: lddata });
  };

  handleRefresh = () => {
    this.load();
  };

  checkRowError = (row) => {
    if ((row.status & StatusType.OnlyOnLoadout) != 0) {
      return true;
    }
    if ((row.status & StatusType.LoadoutLinkMissing) != 0) {
      return true;
    }
    if ((row.status & StatusType.BarcodeNotExist) != 0) {
      return true;
    }
    if ((row.status & StatusType.BarcodeNotEquals) != 0) {
      return true;
    }
    return false;
  };

  handleRowSelect = async (row) => {
    const filteredVSData = this.state.vsData.filter((item) => item.Barcode === row.Barcode);
    const filteredStock = this.state.stock.filter((item) => item.Barcode === row.Barcode);

    // filter out duplicates
    const errors = [...filteredVSData, ...filteredStock].reduce((arr, row) => {
      if (!arr.find((item) => item.Barcode === row.Barcode && item.status === row.status)) {
        arr.push(row);
      }
      return arr;
    }, []);

    this.setState({ selectedRow: row, errorsDetail: errors });
  };

  handleFixDispatchStock = async (acceptType: number) => {
    if (this.state.selectedRow) {
      this.setState({
        loading: true,
        vsData: [],
        vsDataMissingFromLoadout: [],
        errorsDetail: [],
      });

      const { Barcode, dispatchid } = this.state.selectedRow;

      await dispatchFixBarcodeEdi(dispatchid, Barcode, acceptType);
      await this.handleDataReload();

      this.setState({ loading: false });
    }
  };

  handleDataReload = async () => {
    // reload data
    await this.state.handleForceReload();
    await this.load();
    this.handleRowSelect(this.state.selectedRow as any);
  };

  handleRemoveFromLoadout = async (row) => {
    this.setState({
      loading: true,
      vsData: [],
      vsDataMissingFromLoadout: [],
      errorsDetail: [],
    });

    await deleteByBarcode(row.Barcode);

    const updateInfo = {
      "Consignee ID": "",
      "Ref No": "",
      userName: "",
      Allocated: 0,
      "Pre Allocated": 0,
    };

    await stockDetailDeleteByBarcode(row.Barcode);
    await updateStockByBarcodeID(row.barcode_id, updateInfo);
    await this.handleDataReload();

    this.setState({ loading: false });
  };

  handleAddToLoadout = async (row) => {
    this.setState({
      loading: true,
      vsData: [],
      vsDataMissingFromLoadout: [],
      errorsDetail: [],
    });

    const updateInfo = {
      data: {
        loadout_id: this.state.selectedLoadout[0].id,
        barcode: row.Barcode,
      },
    };

    const updateInfoStock = {
      barcode_id: row.barcode_id,
      barcode: row.Barcode,
      // client: dataToUse.consigneeId,
      // destination: dataToUse.pod,
      // ponumber: dataToUse.po,
      reference: this.state.selectedLoadout[0].reference,
      // temperaturecode: dataToUse.temp,
      username: "loadout",
      // allocated: dataToUse.allocated,
      // preallocated: dataToUse.preAllocated,
      commodityCode: row.CommodityCode,
      varietyCode: row.VarietyCode,
      gradeCode: row.GradeCode,
      // targetMarket: item.targetMarket,
      // targetCountry: item.targetCountry,
      countCode: row.CountCode,
      packCode: row.PackCode,
      markCode: row.MarkCode,
      // dealtype: item.dealtype,
    };

    await updateLoadoutByBarcode(updateInfo);
    await stockDetailInsertByBarcodeID(updateInfoStock);
    await updateStockByBarcodeID(row.barcode_id, {
      "Ref No": this.state.selectedLoadout[0].reference,
      userName: "loadout",
    });

    // reload data
    await this.handleDataReload();

    this.setState({ loading: false });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;

    const fontWeight = this.state.selectedRow && this.state.selectedRow["Barcode"] == row["Barcode"] && classes.boldRow;
    const error = this.checkRowError(row) && classes.yellowRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleRowSelect(row)} className={classNames(fontWeight)} rowClass={() => error} />;
  };

  selectedRowRenderer = (props, classes) => {
    const { row } = props;
    const selected = this.state.selectedRow && this.state.selectedRow["Barcode"] == row["Barcode"] && classes.yellowRow;

    return <Row {...props} onDoubleClickCapture={() => this.handleRowSelect(row)} rowClass={() => selected} />;
  };

  render() {
    const { classes } = this.props;
    const totalsCols = ["NoCartons", "PalletSize"];
    return (
      <div className={classes.root}>
        {this.state.loading ? (
          <CircularProgress />
        ) : (
          <div className={classes.gridContainer}>
            <div style={{ position: "relative" }}>
              <div className={classes.gridHeaderWrapper}>
                <h3 className={classes.gridHeaderText}>EDI</h3>
              </div>
              <Grid
                showFilterChips={false}
                loading={this.state.loading}
                data={this.state.vsData}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  GridColumnsEdiDetail(data, filters, columnArrangement, columnsWidth, "dispatchsetupcompareedidetail")
                }
                handleRefresh={this.handleRefresh}
                selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) => {}}
                clearFilters={"dispatchsetupcompareedidetail"}
                rowRenderer={(props) => this.rowRenderer(props, classes)}
                forceHeight={850}
                totalRowColumns={totalsCols}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className={classes.gridHeaderWrapper}>
                <h3 className={classes.gridHeaderText}>Loadout</h3>
              </div>
              <Grid
                showFilterChips={false}
                loading={this.state.loading}
                data={this.state.stock}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  GridColumnsEdiDetail(data, filters, columnArrangement, columnsWidth, "dispatchsetupcompareedidetailselected")
                }
                selectedRowChange={(selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) => {}}
                clearFilters={"dispatchsetupcompareedidetailselected"}
                rowRenderer={(props) => this.selectedRowRenderer(props, classes)}
                forceHeight={850}
                totalRowColumns={totalsCols}
              />
            </div>
            <div className={classes.errorsContainer}>
              <div className={classes.errorsHeader}>Fix Errors</div>
              {this.state.errorsDetail.map(
                (dp, idx) =>
                  dp.status != 0 && (
                    <div key={idx} className={classes.errorCard}>
                      <div className={classes.errorCardText}>
                        <p style={{ lineHeight: "20px" }}>{StatusValue[dp.status]}</p>
                      </div>
                      {dp.status === StatusType.BarcodeNotEquals && (
                        <div className={classes.errorCardButton}>
                          <Button
                            name="submit"
                            variant="contained"
                            color="primary"
                            style={{ margin: "2px" }}
                            onClick={() => this.handleFixDispatchStock(DispatchFixStockType.AcceptEDI)}
                          >
                            Accept EDI
                          </Button>
                          <Button
                            name="submit"
                            variant="contained"
                            color="secondary"
                            style={{ margin: "2px" }}
                            onClick={() => this.handleFixDispatchStock(DispatchFixStockType.AcceptBarcode)}
                          >
                            Accept Stock
                          </Button>
                        </div>
                      )}
                      {dp.status === StatusType.OnlyOnLoadout && (
                        <div style={{ textAlign: "center" }}>
                          <Button name="submit" variant="contained" color="primary" style={{ margin: "2px" }} onClick={() => this.handleRemoveFromLoadout(dp)}>
                            Remove from Loadout
                          </Button>
                        </div>
                      )}
                      {dp.status === StatusType.LoadoutLinkMissing && (
                        <div style={{ textAlign: "center" }}>
                          <Button name="submit" variant="contained" color="primary" style={{ margin: "2px" }} onClick={() => this.handleAddToLoadout(dp)}>
                            Add to Loadout
                          </Button>
                        </div>
                      )}
                    </div>
                  ),
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DispatchAcceptDialogEdiDetailsUnstyled);
