import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getFinancialYearSelected } from "../../lib/api/week";
import { arrivalDumpDataExcel } from "../../lib/api/arrival";
import { arrivaldataGroupedContainer } from "../../lib/api/arrivaldata";

import Grid from "../../lib/components/grid";
import { GridColumns } from "./arrivalgridsetup";

import ArrivalGridActionDialog from "./arrivalgridactiondialog";
import Confirmation from "../../lib/components/confirmation";
import { DialogInformation } from "../../lib/components/dialoginformation";

import { SnackContext } from "../../lib/context/SnackContext";
import { Row } from "react-data-grid";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    greenRow: {
      color: "green",
    },
    normalRow: {
      color: "black",
    },
  });

type ArrivalsGridProps = {
  handleEdit: any;
  handleRemove: any;
} & WithStyles<typeof styles>;

class ArrivalsGridUnstyled extends React.Component<ArrivalsGridProps, {}> {
  state = {
    classes: undefined,
    data: [],
    handleEdit: undefined,
    handleRemove: undefined,
    hideCompleted: undefined,
    dataLoading: true,
    captureNote: undefined,
    bePatient: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleEdit = props.handleEdit;
    this.state.handleRemove = props.handleRemove;
    // this.loadData();
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ dataLoading: true });
    try {
      const data = await arrivaldataGroupedContainer();
      const dataSorted = data.filter((item) => {
        if (!this.state.hideCompleted) return item;
        if (item.arrival_id.toString() == "" || item.arrival_id.toString() == "0") return item;
      });
      this.setState({ data: dataSorted });
    } catch (error) {
      console.log("🚀 ~ ArrivalsGridUnstyled ~ loadData= ~ error:", error);
      const err = GenerateErrorMessage(error, "Error retrieving data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ dataLoading: false });
  };

  GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    return GridColumns(data, filters, arrangement, columnsWidth, this.state.handleEdit, this.state.handleRemove);
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const colorStyle = row.arrival_id != 0 ? classes.greenRow : classes.normalRow;

    return <Row {...props} className={colorStyle} />;
  };

  handleHideCompleted = () => {
    this.setState({ hideCompleted: !this.state.hideCompleted }, () => {
      this.loadData();
    });
  };

  handleGridRowDoubleClick = (arrivalid) => {
    if (arrivalid != 0) {
      this.setState({ captureNote: arrivalid });
    }
  };

  handleGridActionClose = () => {
    this.setState({ captureNote: undefined }, () => {
      this.loadData();
    });
  };

  handleBePatientClose = () => {
    this.setState({ bePatient: false });
  };

  menuItemsArr = {
    1: {
      title: "Arrival Summary",
      action: (data, filters) => {
        this.setState({ bePatient: true, dataLoading: true, loading: true });
        arrivalDumpDataExcel(data, 0, getFinancialYearSelected())
          .then(() => {
            this.setState({ dataLoading: false, loading: true });
          })
          .catch((error) => {
            const err = GenerateErrorMessage(error, "failed to retrieve data");
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
        // arrivalDetailedExportData(0).then((result) => {});
      },
    },
    2: {
      title: "Producer Arrival Summary",
      action: (data, filters) => {
        this.setState({ bePatient: true, dataLoading: true, loading: true });
        const dataFixed = data.map((item) => {
          const itemNew = { ...item };
          delete itemNew["consigneeid"];
          delete itemNew["consignee"];
          return itemNew;
        });
        arrivalDumpDataExcel(dataFixed, 1, getFinancialYearSelected())
          .then(() => {
            this.setState({ dataLoading: false, loading: true });
          })
          .catch((error) => {
            const err = GenerateErrorMessage(error, "failed to retrieve data");
            this.context.updateSnack({ show: true, color: "red", message: err });
          });
      },
    },
    // 3: {
    //   title: "Full Detail Summary",
    //   action: (data, filters) => {
    //     this.setState({ bePatient: true, dataLoading: true, loading: true });
    //     arrivalDetailedExportData(0).then((result) => {
    //       this.setState({ dataLoading: false, loading: true });
    //     });
    //   },
    // },
    // 3: {
    //   title: "Full Producer Detail Summary",
    //   action: (data, filters) => {
    //     this.setState({ bePatient: true, dataLoading: true, loading: true });
    //     arrivalProducerDetailedExportData().then((result) => {
    //       this.setState({ dataLoading: false, loading: false });
    //     });
    //   },
    // },
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.bePatient && (
          <DialogInformation
            isOpen={this.state.bePatient}
            handleClose={this.handleBePatientClose}
            handleOK={this.handleBePatientClose}
            showinput={false}
            title={"BE PATIENT"}
            body={"This could take a moment. Please be patient."}
          />
        )}
        <div style={{ marginBottom: "-65px", width: "300px", marginLeft: "17px" }}>
          <FormControlLabel
            style={{ marginBottom: "10px" }}
            control={<Switch checked={this.state.hideCompleted} onChange={this.handleHideCompleted} value="HIDE COMPLETED" color="primary" />}
            label="HIDE COMPLETED"
          />
          {this.state.dataLoading && <CircularProgress size={24} />}
        </div>
        {this.state.captureNote && (
          <Confirmation isOpen={true} handleClose={this.handleGridActionClose} handleConfirm={this.handleGridActionClose} title={`Grid Record Action`} body={undefined}>
            <ArrivalGridActionDialog arrivalid={this.state.captureNote} handleClose={this.handleGridActionClose} />
          </Confirmation>
        )}
        <Grid
          loading={false}
          data={this.state.data}
          GridColumns={this.GridColumnsOverride}
          rowRenderer={(props) => this.rowRenderer(props, classes)}
          menuItems={this.menuItemsArr}
          rowHeight={30}
          clearFilters={"arrivalsgridsetup"}
          handleRefresh={() => {
            this.loadData();
          }}
        />
      </div>
    );
  }
}

export const ArrivalsGrid = withStyles(styles, { withTheme: true })(ArrivalsGridUnstyled);
